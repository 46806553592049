/* globals zc _ Backbone app */

(function () {
  'use strict'

  zc.views.LobbyView = Backbone.View.extend({
    initialize: function (options) {
      this.project = options.project
      this.recorder = this.project.recorder
      this.call = this.project.call

      this.listenTo(this.model.users, 'add', this.userAdded)
      this.listenTo(this.model.users, 'reset', this.renderUsers)

      this.listenTo(this.call, 'peerStreamAdded', this.peerStreamAdded)
      this.listenTo(this.call, 'peerStreamRemoved', this.peerStreamRemoved)
      // this.listenTo(this.call, 'localStreamAdded', this.localStreamAdded)
      // this.listenTo(this.call, 'localStreamRemoved', this.localStreamRemoved)
      this.listenTo(this.recorder, 'localRecorderAudioAdded', this.localStreamAdded)
      this.listenTo(this.recorder, 'localRecorderAudioRemoved', this.localStreamRemoved)
      this.listenTo(this.call, 'streamVolumeChange', this.streamVolumeChange)
      this.listenTo(this.call, 'streamMuted', this.streamMuted)
      this.listenTo(this.call, 'streamUnmuted', this.streamUnmuted)
    },

    template: _.template($('.lobby-template').html()),

    userAdded: function (user) {
      this.renderUser(user)
    },

    renderUsers: function () {
      var view = this
      this.model.users.forEach(function (user) {
        view.renderUser(user)
      })
    },

    renderUser: function (user) {
      var buffer = this.project.buffers.get(user.id)
      var userView = new zc.views.LobbyUserView({
        model: user,
        recorder: this.recorder,
        buffer: buffer,
        id: user.id,
        className: user.isLocal() ? 'lobby-user is-local' : 'lobby-user'
      })
      this.$el.append(userView.render().el)
    },

    peerStreamAdded: function (peerAudioGain, peerAudio, peer) {
      var user = this.model.users.get(peer.id)
      console.log('peer stream added', user.get('username'))
      user.trigger('streamAdded', peerAudioGain, user)
    },

    peerStreamRemoved: function (peerAudioGain, peerAudio, peer) {
      var user = this.model.users.get(peer.id)
      // this gets called when a user removes a video or audio stream
      // or just when a user leaves the room.  Don't try to trigger
      // event if user has left the room and isn't here anymore
      if (user) {
        user.trigger('streamRemoved', peerAudioGain, user)
      }
    },

    localStreamAdded: function (localInput, localAudio) {
      var user = app.user
      // var streamSource = this.recorder.actx.createMediaStreamSource(stream);
      // streamSource.connect(app.micGain);
      user.trigger('streamAdded', localInput)

      // set audio devices on user
      // var audioInputId = this.call.get('audioInputId')
      // var audioInput = this.recorder.mediaDevices.getInputDeviceById(audioInputId)
      // var audioInputAttrs = audioInput && audioInput.toJSON()
      // var audioOutputId = this.call.get('audioOutputId')
      // var audioOutput = this.recorder.mediaDevices.getOutputDeviceById(audioOutputId)
      // var audioOutputAttrs = audioOutput && audioOutput.toJSON()

      // app.user.set({
      //   audioInputId: audioInputId,
      //   audioInput: audioInputAttrs,
      //   audioOutputId: audioOutputId,
      //   audioOutput: audioOutputAttrs
      // })
    },

    localStreamRemoved: function () {
      var user = app.user
      user.trigger('streamRemoved')
    },

    streamMuted: function (peer) {
      var user = this.model.users.get(peer.nick)
      user.set('muted', true)
    },

    streamUnmuted: function (peer) {
      var user = this.model.users.get(peer.nick)
      user.set('muted', false)
    },

    streamVolumeChange: function (userId, volume) {
      var user = this.model.users.get(userId)
      if (user) {
        user.set({'streamVolume': volume})
      }
    },

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))

      this.renderUsers()

      return this
    }
  })
})()
