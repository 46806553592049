/* globals zc Backbone */

(function () {
  'use strict'

  zc.collections.Users = Backbone.Collection.extend({
    model: zc.models.User,

    getByPeerId: function (peerId) {
      return this.findWhere({peerId: peerId})
    }
  })
})()
