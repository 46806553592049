/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.FrequencyAnalyserView = Backbone.View.extend({
    initialize: function () {
      _.bindAll(this, 'drawAnalyser')
      // this.listenTo(this.model, 'change:micArmed', this.micArmedChange);

      this.freqByteData = new Uint8Array(this.model.analyserNode.frequencyBinCount)
      $(window).resize(this.resizeCanvas.bind(this))
    },

    template: _.template($('.frequency-analyser-template').html()),

    className: 'frequency-analyser',

    micArmedChange: function (model, micArmed) {
      if (micArmed) {
        this.drawAnalyser()
      } else {
        window.cancelAnimationFrame(this.analyserRAFID)
      }
    },

    drawAnalyser: function () {
      var analyserNode = this.model.analyserNode
      var canvas = this.canvas
      var context = this.context
      var canvasWidth = canvas.width
      var canvasHeight = canvas.height

      // analyzer draw code here
      var SPACING = 3
      var BAR_WIDTH = 1
      var numBars = Math.round(canvasWidth / SPACING)
      var freqByteData = this.freqByteData

      this.model.analyserNode.getByteFrequencyData(freqByteData)

      context.clearRect(0, 0, canvasWidth, canvasHeight)
      context.fillStyle = '#F6D565'
      context.lineCap = 'round'
      var multiplier = analyserNode.frequencyBinCount / numBars

      // Draw rectangle for each frequency bin.
      for (var i = 0; i < numBars; ++i) {
        var magnitude = 0
        var offset = Math.floor(i * multiplier)
        // gotta sum/average the block, or we miss narrow-bandwidth spikes
        for (var j = 0; j < multiplier; j++) { magnitude += freqByteData[offset + j] }
        magnitude = magnitude / multiplier
        context.fillStyle = 'hsl( ' + Math.round((i * 360) / numBars) + ', 100%, 50%)'
        context.fillRect(i * SPACING, canvasHeight, BAR_WIDTH, -magnitude / 4)
      }

      this.analyserRAFID = window.requestAnimationFrame(this.drawAnalyser)
    },

    resizeCanvas: function () {
      this.canvas.width = this.$el.width()
      this.canvas.height = this.$el.height()
    },

    render: function () {
      var view = this
      this.$el.html(this.template(this.model.toJSON()))
      this.$canvas = this.$('.frequency-analyser-canvas')
      this.canvas = this.$canvas[0]
      this.context = this.canvas.getContext('2d')

      _.defer(function () {
        view.resizeCanvas()
      })

      return this
    }
  })
})()
