/* globals zc _ Backbone app utils */

(function () {
  'use strict'

  zc.views.ChatView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model.messages, 'add', this.renderMessage)
      this.listenTo(this.model.messages, 'reset', this.renderMessages)
      this.listenTo(this.model, 'remoteTypingStarted', this.showRemoteTyper)
      this.listenTo(this.model, 'remoteTypingStopped', this.hideRemoteTyper)
    },

    className: 'chat',

    template: _.template($('.chat-template').html()),

    events: {
      'keypress .chat-input': 'chatInputKeypress'
    },

    chatInputKeypress: function (e) {
      e.stopPropagation()
      this.typingHandler()
      if (e.keyCode === 13) {
        e.preventDefault()
        var text = utils.sanitizeText(e.target.value)
        text = utils.linkify(text, true)
        this.createMessage(text)
        this.stoppedTyping()
        e.target.value = ''
      }
    },

    typingHandler: function () {
      if (this.typingTimeout) {
        clearInterval(this.typingTimeout)
      } else {
        this.model.trigger('localTypingStarted')
      }

      this.typingTimeout = setTimeout(this.stoppedTyping.bind(this), 3000)
    },

    stoppedTyping: function () {
      this.model.trigger('localTypingStopped')
      this.typingTimeout = undefined
    },

    showRemoteTyper: function (model, user) {
      var message = (user.displayName || user.username) + ' is typing...'
      this.$typingDisplay.text(message)
    },

    hideRemoteTyper: function (model, user) {
      this.$typingDisplay.empty()
    },

    createMessage: function (text) {
      var attrs = {
        userId: app.user.id,
        username: app.user.get('displayName') || app.user.get('username'),
        text: text
      }
      var message = new zc.models.Message(attrs)
      this.model.messages.add(message)
      app.socket.emit('chat:message', message.toJSON())
    },

    scrollToBottom: function () {
      this.$messages.scrollTop(this.$messages.prop('scrollHeight'))
    },

    renderMessages: function () {
      var view = this
      this.model.messages.each(function (message) {
        view.renderMessage(message)
      })
    },

    renderMessage: function (message) {
      var messageView = new zc.views.MessageView({model: message})
      this.$messages.prepend(messageView.render().el)
      this.scrollToBottom()
    },

    render: function () {
      var view = this
      this.$el.html(this.template(this.model.toJSON()))
      this.$messages = this.$('.messages')
      this.$typingDisplay = this.$('.typing-display')

      this.renderMessages()

      _.defer(function () {
        view.scrollToBottom()
      })

      return this
    }
  })
})()
