/* globals zc _ */

(function () {
  'use strict'

  zc.views.KickedView = zc.views.PageView.extend({

    template: _.template($('.kicked-template').html()),

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      return this
    }
  })
})()
