/* globals moment zc _ Backbone utils app PersistentStore PersistentStoreWorkerProxy MemoryStoreWorkerProxy CloudStore CloudStoreWorkerProxy CloudStoreWorkerProxy debug uploader */

(function () {
  'use strict'

  var dbg = debug('zc:track')

  zc.models.Track = Backbone.Model.extend({
    name: 'track',

    initialize: function (attrs, opts) {
      opts = opts || {}

      this.persistentStore = new PersistentStore({
        name: 'persistentStore',
        parentId: attrs._id,
        format: attrs.format
      })

      this.cloudStore = new CloudStore({
        name: 'cloudStore',
        format: attrs.format,
        trackId: attrs._id,
        uploadUrl: attrs.uploadUrl,
        chunkSize: attrs.uploaderChunkSize,
        socket: app.socket,
        persistentStore: this.persistentStore
      })

      this.on('change:recordingSessionEnded', this.onRecordingSessionEndedChange)
      this.on('change:processing', this.processingChange)
      this.on('change:uploading', this.uploadingChange)
      this.on('change:bytesRecorded', this.bytesRecordedChange)
      this.on('change:bytesSaved', this.bytesSavedChange)
      this.on('change:bytesUploaded', this.bytesUploadedChange)
      this.on('change:percentSaved', this.percentSavedChange)
      this.on('change:percentUploaded', this.percentUploadedChange)
      this.on('change:finalized', this.finalizedChange)

      this.initPersistentStoreListeners(this.persistentStore)
      this.initCloudStoreListeners(this.cloudStore)

      // uploads and postproduction keep their path in different places
      if (!attrs.path) {
        this.set({ path: attrs.cloudPath })
      }

      // Fix for old postproductions that don't have the format label
      // if the attrs object doesn't have a format, but has the outputFiles
      // then it's a postproduction
      if (!attrs.format && _.isArray(attrs.outputFiles)) {
        // if it has only one outputFiles, then it's a mp3
        if (attrs.outputFiles.length === 1 && attrs.outputFiles[0].format) {
          this.set('format', 'mp3')
        } else
          // else, the sepparate wav files options was used, show the wav label
          if (attrs.outputFiles.length === 2) {
            this.set('format', 'wav')
          }
      }

      this.throttledLogFunction = _.throttle(this.logTrackProgress.bind(this), 5000)
    },

    defaults: {
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
      localStorageDeletedAt: null,

      format: 'wav', // 'wav' or 'mp3'
      type: 'microphone',
      audio: null, // audio data
      duration: 0,
      downloading: false,
      path: null, // relative path/filename for track
      url: null, // permanent public url for legacy dropbox tracks
      uploadUrl: null, // temporary signed url for upload
      downloadUrl: null, // temporary signed url for downloads
      error: null,
      size: 0,
      token: null,
      forced: false,
      finalized: false,

      // keeping track of what is recorded / saved / uplaoded
      bytesRecorded: 0, // bytes recorded to memoryStore in the current session
      bytesSaved: 0, // bytes saved to persistentStore
      bytesUploaded: 0, // bytes uploaded using cloudStore
      percentSaved: 0, // percent successfully saved to persistentStore
      percentUploaded: 0, // percent successfully uploaded using cloudStore

      progress: 0, // what gets rendered into progress bars in the UI
      selectable: false,
      deferUpload: false, // if true, audio will be saved but not uploaded in chunks as it is received. generally for wav uploads
      uploading: false, // true for the entirety of the uploading process regardless of pauses between chunks
      recording: false, // true from when the recording is started until the final chunk is passed to the track for upload
      recordingSessionEnded: false, // true after the recording session for this track has been finally ended
      initializedForRecording: false,
      localStoreChunkSize: 65536, // 64kb - should be varied/tested to find optimum size for indexed db upload frequency and size
      uploaderChunkSize: 1024 * 256, // 256kb - the minimum allowable sized chunk for google cloud upload
      chunksRecorded: 0,
      chunksUploaded: 0,
      profiles: null,
      driftStats: null
    },

    attrs: function () {
      var attrs = this.toJSON()

      if (attrs.forced && attrs.duration === 0) {
        attrs.hmsDuration = 'Unknown Duration'
      } else {
        // if the format is wav then divide that value by 4
        attrs.hmsDuration = utils.msToHms(attrs.duration)
      }

      attrs.createdAtReadable = attrs.createdAt ? moment(attrs.createdAt).format('dddd MMM Do YYYY - h:mmA') : null
      attrs.updatedAtReadable = attrs.updatedAt ? moment(attrs.updatedAt).format('dddd MMM Do YYYY - h:mmA') : null
      attrs.localStorageDeletedAtReadable = attrs.localStorageDeletedAt ? moment(attrs.localStorageDeletedAt).format('dddd MMM Do YYYY - h:mmA') : null

      attrs.formattedSize = utils.formattedDiskSpace(attrs.size || attrs.offset)
      return attrs
    },

    initPersistentStoreListeners: function (persistentStore) {
      var self = this
      persistentStore.on('change:bytesSaved', function (bytesSaved, chunksIn, chunksOut) {
        self.set('bytesSaved', bytesSaved)
        dbg('Saved', bytesSaved, 'to persistentStore')
        // monitor if the idb saves are backing up
        // and log an error every 50 chunks
        var chunksDiff = chunksIn - chunksOut
        if (chunksDiff > 10 && chunksIn % 50 === 0) {
          console.error(self.get('format'), ' persistent store saves are backing up. chunks in/out: ', chunksIn, '/', chunksOut)
        }
      })
    },

    initMemoryStoreListeners: function (memoryStore) {
      var self = this
      memoryStore.on('change:bytesRecorded', function (bytesRecorded) { self.set('bytesRecorded', bytesRecorded) })
    },

    initCloudStoreListeners: function (cloudStore) {
      var self = this
      // we have to re-initialize the cloudStore when creating new tracks for recording
      // this makes it easier to stay DRY when re-attaching listeners
      // NOTE: cloudStore uses events, not Backbone.Events
      cloudStore.on('change:bytesUploaded', function (bytesUploaded) { self.set('bytesUploaded', bytesUploaded) })
      cloudStore.on('chunkUploaded', this.chunkUploaded.bind(this))
    },

    // -----------------
    // Recording Methods
    // -----------------
    //  Audio Recording Pipeline:
    //  pcm: instance of Int16Array
    //  mp3: instance of Int8Array

    /**
     * This is called to prepare a track to handle input chunks to be recorded
     * This only needs to be called the first time a track is created and used
     * @return {Promise}
     */
    initForRecording: function (audioPipeline) {
      var self = this

      // we only want to do this part once for a given track
      if (this.initForRecordingPromise) return this.initForRecordingPromise

      console.log('Initalizing', this.get('type'), this.get('format'), 'track for recording.')

      // set relevant attrs
      this.set({
        deferUpload: (this.get('format') === 'wav') // only wav tracks defer uploading
      })

      self.audioPipeline = audioPipeline

      // init wav or mp3 pipeline depending on track format
      // then generate uploadUrl
      this.initForRecordingPromise = utils.promiseSerial([
        self.initAudioPipeline.bind(self),
        self.createUploadUrl.bind(self)
      ]).then(function () {
        if (!self.get('deferUpload')) {
          return self.cloudStore.initStreamingUpload()
            // if this failed then most likely the uplad url is invalid
            .catch(function () {
              // create a new one and restart
              self.unset('uploadUrl')
              return self.createUploadUrl().then(function () {
                return self.cloudStore.initStreamingUpload()
              })
            })
        }
      }).then(function () {
        return self
      }).catch(utils.logRethrowCustom(new Error('Track initialization error')))

      return this.initForRecordingPromise
    },

    initAudioPipeline: function () {
      var self = this

      var format = this.get('format')
      // name will be made from format and type, eg: mp3microphone
      var name = format + this.get('type')
      var constructor = format === 'mp3' ? Uint8Array : Int16Array
      var uploaderChunkSize = this.get('uploaderChunkSize')
      var localStoreChunkSize = this.get('localStoreChunkSize')

      return new Promise(function (resolve, reject) {
        // create worker proxy objects
        // these are representations of the stream nodes which run in the worker
        // they proxy messages to and from the stream nodes and allow us to interact
        // with the nodes in the worker as if they were in the main thread
        self.memoryStore = new MemoryStoreWorkerProxy({ name: name + 'MemoryStoreWorkerProxy', ChunkConstructor: constructor.name, format: format, chunkSize: localStoreChunkSize })
        self.initMemoryStoreListeners(self.memoryStore) // listen for upload events
        self.persistentStore = new PersistentStoreWorkerProxy({ name: name + 'PersistentStoreWorkerProxy', parentId: self.id, ChunkConstructor: constructor.name, format: format })
        self.initPersistentStoreListeners(self.persistentStore) // listen for upload events
        self.cloudStore = new CloudStoreWorkerProxy({ name: name + 'CloudStoreWorkerProxy', trackId: self.id, format: format })
        self.initCloudStoreListeners(self.cloudStore) // listen for upload events
        // get worker proxy message ports
        var memoryStorePort = self.memoryStore.getMessagePort()
        var persistentStorePort = self.persistentStore.getMessagePort()
        var cloudStorePort = self.cloudStore.getMessagePort()

        var audioPipeline = self.audioPipeline

        var debugPipeline = false

        var streamNodes = [{
          constructorName: 'MemoryStoreStreamNode', // we store upload-sized chunks here so we can easily resume retry uploads from memory
          constructorConf: { name: name + 'MemoryStore', chunkSize: localStoreChunkSize, ChunkConstructor: constructor.name, format: format, messagePort: memoryStorePort, debug: debugPipeline }
        }, {
          constructorName: 'PersistentStoreStreamNode',
          constructorConf: { name: name + 'PersistentStore', parentId: self.id, format: format, messagePort: persistentStorePort, debug: debugPipeline }
        }, {
          constructorName: 'CloudStoreStreamNode', // this node makes the chunks the appropriate size for uploading (256k)
          constructorConf: { name: name + 'CloudStore', trackId: self.id, format: format, uploadUrl: self.get('uploadUrl'), deferUpload: self.get('deferUpload'), chunkSize: uploaderChunkSize, messagePort: cloudStorePort, debug: debugPipeline }
        }]

        self.pipelineMap = streamNodes.map(function (n) { return n.constructorConf.name })

        // add these nodes to their own pipeline
        audioPipeline.postMessage({
          command: 'addPipeline',
          config: {
            UpstreamChunkConstructor: constructor.name,
            name: name,
            format: format,
            debug: debugPipeline,
            nodes: streamNodes
          }
        }, [memoryStorePort, persistentStorePort, cloudStorePort])

        resolve(self)
      }).catch(function (err) {
        utils.logRethrow(err)
      })
    },

    bytesChange: function () {
      var bytesRecorded = this.get('bytesRecorded')
      var bytesUploaded = this.get('bytesUploaded')
      var bytesSaved = this.get('bytesSaved')

      dbg('Progress for track', this.get('type'), this.get('format'), { bytesRecorded: bytesRecorded, bytesUploaded: bytesUploaded, bytesSaved: bytesSaved })

      this.set({ percentSaved: Math.min(Math.round(bytesSaved / bytesRecorded * 100), 100) })
      this.set({ percentUploaded: Math.min(Math.round(bytesUploaded / bytesRecorded * 100), 100) })
    },

    getAudioBlob: function () {
      return this.persistentStore.exportAudioBlob()
    },

    /**
     * used to upload a new audio blob for the track. usually that comes from the audiostore
     * @param  {Blob} audioBlob
     */
    uploadRetryLimit: 3,
    uploadRetryAttempts: 0,
    uploadFull: function () {
      var self = this
      console.log('Starting full upload of track', this.get('type'), this.get('format'))

      this.set({ processing: true })

      var url = this.get('uploadUrl')
      return self.cloudStore.uploadFromStore(url).then(function () {
        dbg('Full Upload Complete. track:', self.id)
      }).catch(function (err) {
        console.error('Error uploading track. attempt:', self.uploadRetryAttempts)
        if (self.uploadRetryAttempts <= self.uploadRetryLimit) {
          console.error(err)
          utils.notify('alert', 'There was a problem uploading your audio. Retrying', { ttl: 3000 })
          self.uploadRetryAttempts++
          return self.uploadFull()
        }
        // keep incrementing attempts after the retry limit is hit
        // because the force finalize options may try to upload again
        // and we need to know if we're getting stuck in an endless loop
        self.uploadRetryAttempts++
        utils.notify('error', 'Unable to complete upload after ' + self.uploadRetryAttempts + ' attempts')
        self.set({ uploading: false, error: 'Error uploading track' })

        if (self.uploadRetryAttempts < self.uploadRetryLimit * 2) {
          console.warn('Reached attempt limit. Trying to force finalize track', self.id)
          self.forceFinalizeUpload()
          throw new Error('There was a problem uploading to the cloud storage. ', err)
        } else {
          utils.notify('alert', 'Unable to finalize upload to the server.  Initiating download of local backup.', 10000)
          return new Promise(function (resolve, reject) {
            setTimeout(function () {
              self.downloadFromLocal(self.get('path')).then(resolve).catch(reject)
            }, 2000) // to allow for some time to read the notice before download prompts
          })
        }
      })
    },

    hasUploadInProgress: function () {
      return this.cloudStore.uploadInProgress
    },

    confirmFileUpload: function () {
      // var self = this
      var bytesRecorded = this.get('bytesRecorded')
      var bytesUploaded = this.get('bytesUploaded')
      var bytesSaved = this.get('bytesSaved')

      console.log('Confirming Track Upload', this.get('type'), this.get('format'), { bytesRecorded: bytesRecorded, bytesUploaded: bytesUploaded, bytesSaved: bytesSaved })

      if (bytesUploaded < bytesRecorded) {
        console.error('uploaded file size is underweight by: ', bytesRecorded - bytesUploaded)
      }

      this.trigger('uploadCompleted', this)
    },

    chunkUploaded: function (chunkMeta) {
      this.set({ chunksUploaded: chunkMeta.chunkIndex + 1 })
      dbg('Chunk uploaded. Number:', chunkMeta.chunkIndex + 1, 'Bytes:', chunkMeta.uploadedBytes, 'Track id:', this.id)

      if (chunkMeta.isLastChunk) {
        dbg('Last chunk received')
        this.confirmFileUpload(chunkMeta)
        this.set({ finalized: true, uploading: false })
      }

      var attrs = {
        _id: this.id,
        offset: chunkMeta.uploadedBytes,
        duration: this.get('duration'),
        size: this.get('bytesUploaded'),
        finalized: chunkMeta.isLastChunk
      }

      this.updateUpload(attrs)
    },

    isChunkUploadHealthy: function () {
      return this.cloudStore.getUploadErrors().then(function (uploadErrors) {
        if (uploadErrors.length) return false
        return true
      })
    },

    onRecordingSessionEndedChange: function (track, sessionEnded) {
      // if the session has ended and the user owns this track
      if (sessionEnded && track.get('userId') === app.user.id) this.onRecordingSessionEnded()
    },

    onRecordingSessionEnded: function () {
      var self = this
      // We have problems with stalled or unhealthy chunk uploads
      // that never complete at the end of the recording session.
      // Here we check to see if we need to kickstart the uploads.
      // Might as well upload the full track at once since its complete now.
      var hasChunkUpload = this.get('format') === 'mp3'
      if (hasChunkUpload) {
        this.isChunkUploadHealthy().then(function (isHealthy) {
          if (!isHealthy) self.abortChunkUploadAndRetryFull()
        })
      }
    },

    abortChunkUploadAndRetryFull: function () {
      var self = this
      // Our google upload session may be broken so
      // create a new upload session/url and save it to db
      // before uploading data in full
      this.createUploadUrl().then(function (url) {
        self.uploadFull()
      })
    },

    saveProfiles: function (profiles) {
      profiles.unshift(this.get('resamplerProfile'))
      this.updateUpload({ _id: this.id, profiles: profiles })
    },

    /**
     * used to update some attribute for the upload db model
     * @param  {Object}   attrs The attribute to change, must contain _id
     * @param  {Function} cb    Callback
     */
    updateUpload: function (attrs) {
      return new Promise(function (resolve, reject) {
        app.socket.emit('upload:update', attrs, function (err, uploadId) {
          if (err) {
            console.error("Error while saving the track's upload offset in the DB: " + err)
            return reject(err)
          }

          resolve(uploadId)
        })
      })
    },

    deleteUpload: function () {
      app.socket.emit('upload:delete', { _id: this.id }, function (err, uploadId) {
        if (err) {
          console.error('Error while deleting the track: ', err)
        }
      })
    },

    saveDriftStats: function (driftStats) {
      return this.updateUpload({ _id: this.id, driftStats: driftStats })
    },

    handleUploadError: function (err) {
      this.uploadError = err
      console.error(err.responseText)
    },

    processingChange: function (track, processing) {
      var ownsTrack = app.user.ownsTrack(track)
      if (ownsTrack && !app.user.isHost()) {
        app.socket.emit('track:change:processing', { _id: track.id, format: track.get('format'), processing: processing })
      }
    },

    uploadingChange: function (track, uploading) {
      var ownsTrack = app.user.ownsTrack(track)
      if (ownsTrack && !app.user.isHost()) {
        app.socket.emit('track:change:uploading', { _id: track.id, format: track.get('format'), uploading: uploading })
      }
    },

    bytesRecordedChange: function (track, bytesRecorded) {
      // bytesChange sets the percentage properties based on byte data
      this.bytesChange()

      // if app.user owns the track and is NOT the host
      // if (ownsTrack && !isHost) { // don't send a users upload percent back at them if you are the host or it bounces around
      //   app.socket.emit('track:change:bytesRecorded', {_id: track.id, bytesRecorded: bytesRecorded})
      // }
    },

    bytesSavedChange: function (track, bytesSaved) {
      // bytesChange sets the percentage properties based on byte data
      this.bytesChange()
      this.throttledLogFunction()
    },

    bytesUploadedChange: function (track, bytesUploaded) {
      // bytesChange sets the percentage properties based on byte data
      this.bytesChange()
      this.throttledLogFunction()
    },

    logTrackProgress: function () {
      var bytesRecorded = this.get('bytesRecorded')
      var bytesUploaded = this.get('bytesUploaded')
      var bytesSaved = this.get('bytesSaved')

      console.log('Progress for track', this.get('type'), this.get('format'), { bytesRecorded: bytesRecorded, bytesUploaded: bytesUploaded, bytesSaved: bytesSaved })
    },

    percentSavedChange: function (track, percentSaved) {
      var ownsTrack = app.user.ownsTrack(track)
      var isHost = app.user.isHost()

      // if app.user owns the track and is NOT the host
      if (ownsTrack && !isHost) { // don't send a users upload percent back at them if you are the host or it bounces around
        app.socket.emit('track:change:percentSaved', {
          _id: track.id,
          userId: app.user.id,
          bytesRecorded: this.get('bytesRecorded'),
          bytesSaved: this.get('bytesSaved'),
          bytesUploaded: this.get('bytesUploaded'),
          percentSaved: percentSaved
        })
      }
    },

    percentUploadedChange: function (track, percentUploaded) {
      if (this.get('processing')) this.set({ processing: false, uploading: true })
      this.set({ progress: percentUploaded })
      dbg(this.get('format') + ':track:percentUploaded:' + track.id, percentUploaded)
      var ownsTrack = app.user.ownsTrack(track)
      if (ownsTrack && !app.user.isHost()) { // don't send a users upload percent back at them if you are the host or it bounces around
        app.socket.emit('track:change:percentUploaded', {
          _id: track.id,
          userId: app.user.id,
          bytesRecorded: this.get('bytesRecorded'),
          bytesSaved: this.get('bytesSaved'),
          bytesUploaded: this.get('bytesUploaded'),
          percentUploaded: percentUploaded
        })
      }
    },

    finalizedChange: function (track, finalized) {
      if (finalized) app.location.trigger('userAudioUploaded', track)

      var ownsTrack = app.user.ownsTrack(track)
      if (ownsTrack && !app.user.isHost()) {
        app.socket.emit('track:change:finalized', track.toJSON()) // send the whole thing over at the end
      }
    },

    /**
     * Checks to see what location has more audio data (remote or local)
     * and returns the finalize function to execute a finalize a that location
     *
     * @return {Array} Array of finalize functions in order of preference
     */
    getFinalizePreference: function () {
      var self = this

      var localPreference = function () {
        console.log('Preferring local storage finalization')
        return [self.finalizeUploadFromLocal.bind(self), self.finalizeUploadFromServer.bind(self)]
      }

      var remotePreference = function () {
        console.log('Preferring remote server finalization')
        return [self.finalizeUploadFromServer.bind(self), self.finalizeUploadFromLocal.bind(self)]
      }

      if (this.get('format') === 'wav') {
        console.log('Wav tracks always have a preference of finalization from local')
        return Promise.resolve(localPreference())
      }

      return self.cloudStore.getResumableUploadProgress().then(function (progress) {
        var remoteSize = progress.byteOffset ? progress.byteOffset + 1 : 0 // because byteOffset is zero indexed
        return self.persistentStore.calcStorageUsed().then(function (localSize) {
          console.log('Getting finalization preference based on:', 'Remote size: ', remoteSize, 'Local size: ', localSize)
          if (remoteSize >= localSize) {
            return remotePreference()
          } else {
            return localPreference()
          }
        }).catch(function (err) {
          console.error('Failed to calculate local storage size: ', err)
          return remotePreference()
        })
      }).catch(function (err) {
        console.error('Failed to calculate remote server upload size: ', err)
        return localPreference()
      })
    },

    /**
     * method called when one of the tracks fires the 'forceFinalize' event
     * it tries to read the track from idb and if nothing is found it signals the server to upload
     */
    forceFinalizeUpload: function () {
      var self = this
      var format = self.get('format')
      if (format === 'wav' && self.get('userId') !== app.user.id) {
        var url = window.location.origin + app.location.recorder.recording.getIdPath()
        utils.notify('error', 'Unfortunately you cannot finalize another users WAV file.  Please have them return to this recording on their computer using this url: ' + url + '.  Once there, they can click on their own track to retry the upload from their local backup.')
        throw new Error('You cannot finalize a wav file from the server')
      }

      return self.getFinalizePreference().then(function (finalizeFunctions) {
        console.log('Finalizing using first preference: ', finalizeFunctions[0].name)
        return finalizeFunctions[0]().catch(function (err) {
          console.error('Finalizing with first preference failed: ', err)
          console.log('Falling back to finalizing using second preference: ', finalizeFunctions[0].name)
          return finalizeFunctions[1]()
        })
      }).catch(function (err) {
        console.error('Error finalizing upload: ', err)
        self.set({ processing: false, error: 'Finalization Failed :/' })
        utils.notify('alert', 'Unable to finalize upload to the server.  Initiating download of local backup.', 10000)
        setTimeout(function () {
          self.downloadFromLocal(self.get('path')).catch(function (err) {
            utils.notify('error', 'Unable to export backup audio for local download: ' + err, 10000)
            throw new Error(err)
          })
        }, 2000)
      })
    },

    /**
     * called by forceFinalizeUpload to try and read the audio file from idb
     * most likely a wav file
     */
    finalizeUploadFromLocal: function () {
      var self = this
      return this.persistentStore.getDbMeta().then(function (dbMeta) {
        console.log('dbMeta: ', dbMeta)
        self.set({ duration: dbMeta.duration })
        return self.createUploadUrl().then(function () {
          return self.uploadFull()
        }).then(function () {
          console.log('after uploadFull local finalization')
          // return self.saveDuration(self.getDuration())
        }).catch(utils.logRethrow)
      })
    },

    /**
     * called by forceFinalizeUpload to signal the server that it should upload the file
     */
    finalizeUploadFromServer: function () {
      var self = this
      return new Promise(function (resolve, reject) {
        var data = self.toJSON()
        data.uploadUrl = data.uploadUrl
        data.path = data.path || self.getUploadPath()
        data._id = data._id
        data.forced = true

        app.socket.emit('forceFinalizeUpload', data, function (err, upload) {
          if (err) {
            utils.notify('error', err)
            self.set({ error: 'Finalization Failed :/' })
            reject(err)
          } else {
            self.set(upload)
            resolve(self)
          }
        })
      })
    },

    calcLocalStorageUsed: function () {
      return this.persistentStore.calcStorageUsed()
    },

    deleteLocalStorage: function () {
      dbg('Deleting local storage. Track id:', this.id)
      return this.persistentStore.dbDelete()
    },

    downloadFile: function (path) {
      var self = this

      self.set({ progress: 0, downloading: true })
      dbg('Downloading track. Id:', this.id)

      // try local store first, then fallback to cloud download
      return self.downloadFromLocal(path).catch(function () {
        console.warn('Unable to export audio from local store.  Falling back to download from cloud.')
        return self.downloadFromCloud(path).catch(function (err) {
          utils.notify('error', 'There was a problem downloading this track: ' + err, { ttl: 5000 })
          self.set({ downloading: false })
        })
      })
    },

    downloadFromLocal: function (path) {
      var self = this

      // make sure we don't get a leading / which ends up as a - after slugify and screws up commandline arguments
      path = path.match(/[^/]*\/[^/]*\/[^/]*$/)[0] // <projectSlug>/<recordingSlug>/<filename>

      console.log('Downloading from local')
      return this.getAudioBlob().then(function (audioBlob) {
        console.log('Total download size: ', audioBlob.size)

        utils.forceDownload(audioBlob, path)
        self.set('progress', 100)
        setTimeout(function () {
          self.set('downloading', false)
        }, 2000)
      })
    },

    downloadFromCloud: function (path) {
      var self = this
      // if it's postproduction or transcription
      var isPostproduction = this.has('deliveredAt')
      var isLegacyTrack = !this.has('uploadUrl') && !isPostproduction
      var projectId = this.get('projectId') || app.location.id

      // download from dropbox if legacy track
      if (isLegacyTrack) return this.downloadFromDropbox(path)

      console.log('Downloading from the cloud')
      return this.cloudStore.createDownloadUrl(projectId, path).then(function (downloadUrl) {
        return self.downloadUsingPublicUrl(downloadUrl).then(function () {
          // if download is successfull, set downloading as complete and return early
          self.set('progress', 100)
          setTimeout(function () {
            self.set('downloading', false)
          }, 2000)
        })
      })
    },

    downloadFromDropbox: function (path) {
      var self = this

      // if the track has a public url, try and download from there first
      if (this.has('url')) {
        console.log('Downloading from dropbox')
        return this.downloadUsingPublicUrl(this.get('url')).then(function () {
          // if download is successfull, set downloading as complete and return early
          self.set('progress', 100)
          setTimeout(function () {
            self.set('downloading', false)
          }, 2000)
        })
      } else {
        return Promise.reject(new Error('Missing Dropbox url. Please contact support@zencastr.com'))
      }
    },

    downloadUsingPublicUrl: function (url) {
      // sometimes this request to Dropbox might throw a CORS error
      // catch that and continue
      var self = this
      return new Promise(function (resolve, reject) {
        if (url.includes('backblaze')) {
          var element = document.createElement('a')
          element.setAttribute('href', url) // when cross-domain the value of the anchor's download attribute is ignored, the browser only respects the content-disposition header in the response
          element.style.display = 'none'
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)
          resolve()
        } else {
          window.fetch(url, { method: 'HEAD' }).then(function (response) {
            if (response.ok) {
              var element = document.createElement('a')
              element.setAttribute('href', url)
              // element.setAttribute('target', '_blank')

              var name = 'file.' + self.get('format')
              // hacky way to set a name for the file
              if (self.has('path')) {
                name = self.get('path').match(/[^/]*\/[^/]*\/[^/]*$/)[0] // <projectSlug>/<recordingSlug>/<filename>
              } else if (self.has('filename')) {
                name = self.get('filename') + '.' + self.get('format')
              }
              element.setAttribute('download', name)

              element.style.display = 'none'
              document.body.appendChild(element)

              element.click()

              document.body.removeChild(element)
              resolve()
            } else {
              reject(new Error('Failed to download track.  error code: ' + response.status))
            }
          })
        }
      })
    },

    /**
     * used to get the duration for the current track
     * @return {Number} The duration in ms
     */
    saveDuration: function (duration) {
      // also update the duration on the model
      this.set('duration', duration)

      var attrs = {
        _id: this.id,
        duration: duration,
        hmsDuration: utils.msToHms(duration)
      }

      return this.updateUpload(attrs)
    },

    /**
     * used to get the duration for the current track
     * @return {Number} The duration in ms
     */
    getDuration: function () {
      if (this.get('duration')) {
        return this.get('duration')
      } else if (this.get('size')) {
        var duration = 0
        if (this.get('format') === 'mp3') {
          duration = Math.round(this.get('size') / 16) // approximated duration based on 128kbps
        } else if (this.get('format') === 'wav') {
          duration = Math.round(this.get('size') / 90)
        }

        return duration
      }

      return 0
    },

    createUploadUrl: function () {
      var self = this
      var path = this.get('path')
      var projectId = this.get('projectId')
      if (!path) throw new Error('Cannot create an upload url with out a path')
      if (!projectId) throw new Error('Cannot create an upload url with out a projectId')

      if (this.get('uploadUrl')) return Promise.resolve(this.get('uploadUrl'))

      dbg('Creating upload url for track', this.id)
      return self.cloudStore.createUploadUrl(projectId, path)
        .then(function (url) {
          self.set({ uploadUrl: url })
          return url
        }).then(function (url) {
          return self.updateUpload({ _id: self.id, uploadUrl: url })
            .then(function () { return url })
        }).catch(utils.logRethrow)
    },

    getFilename: function (format, username, withDuration) {
      format = format || this.get('format')
      username = username || this.get('username')
      // if this track is a soundboard, use that in the file name
      username = this.get('type') === 'soundboard' ? 'soundboard' : username
      username = username.toLowerCase().replace(/[ \\]/g, '-')
      var datetime = this.getFileDatetime()

      var filename = datetime + '--' + username

      if (withDuration) {
        var duration = utils.msToHms(this.getDuration(), true)
        filename += '--' + duration
      }

      return utils.slugify(filename) + '.' + format
    },

    getFileDatetime: function () {
      return moment().format('YYYY-MM-DD--thh-mm-ssa')
    },

    getUploadPath: function (format, username) {
      format = format || this.get('format')
      username = username || this.get('username')
      var filename = this.getFilename(format, username)
      return this.getUploadFolder() + '/' + filename
    },

    getUploadFolder: function () {
      var project = app.location
      return project.recorder.recording.getCloudFolder()
    },

    getFinalMixFilename: function (format) {
      format = format || this.get('format')
      var datetime = this.getFileDatetime()
      return utils.slugify(datetime + '--final-mix') + '.' + format
    },

    getFinalMixFolder: function () {
      return this.getUploadFolder() + '/postproductions'
    },

    getFinalMixPath: function (format) {
      format = format || this.get('format')
      return this.getFinalMixFolder() + '/' + this.getFinalMixFilename(format)
    },

    doTestUpload: function (uploadSize) {
      var self = this
      self.set({ finalized: false, processing: true, bytesUploaded: 0, bytesRecorded: uploadSize })
      self.cloudStore.createUploadUrl(app.location.id, self.get('path')).then(function (url) {
        self.set('uploadUrl', url)
        self.upload(new Blob([new Float32Array(uploadSize / 4)]))
      }, console.warn)
    },

    doTestCloudStorageUpload: function (uploadSize) {
      var self = this
      self.cloudStore.createUploadUrl(app.location.id, self.get('path')).then(function (url) {
        self.set({ finalized: false, uploading: true, bytesUploaded: 0, bytesRecorded: uploadSize })
        self.cloudStore.upload(url, new Blob([new Float32Array(uploadSize / 4)]))
      }, console.warn)
    },

    doTestSafePutUpload: function (uploadSize) {
      var self = this
      var arrayBuffer = new ArrayBuffer(uploadSize)
      var blob = new Blob([arrayBuffer])

      // var chunk = arrayBuffer
      var chunk = blob
      console.time('start safePutUpload')
      self.cloudStore.createUploadUrl(app.location.id, self.get('path')).then(function (url) {
        self.set({ finalized: false, uploading: true, bytesUploaded: 0, bytesRecorded: uploadSize })

        var headers = { 'Content-Type': 'audio/mpeg', 'Content-Range': 'bytes 0-' + (uploadSize - 1) + '/' + uploadSize }
        var i = 0
        uploader.Upload.safePut(url, chunk, {
          headers: headers,
          onUploadProgress: function (e) {
            if (!i) {
              console.timeEnd('request.send')
              i++
            }
            self.set({ 'bytesUploaded': e.loaded })
          }
        }).then(function (fileMeta) {
          console.timeEnd('start safePutUpload')
          self.set({ finalized: true, uploading: false })
        })
      })
    }
  })

  // streamify.mixin(zc.models.Track.prototype)
})()
