/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.AudioSettingsView = Backbone.View.extend({
    initialize: function (options) {
      this.settings = options.settings
      this.callback = options.callback
    },

    template: _.template($('.audio-settings-template').html()),

    className: 'audio-settings panel',

    events: {
      'click .submit': 'handleSubmitClick'
    },

    handleSubmitClick: function () {
      this.callback()
    },

    renderMediaDeviceSelect: function () {
      var self = this
      this.model.mediaDevices.fetch().then(function () {
        var mediaDeviceSelect = new zc.views.MediaDeviceSelectView({
          collection: self.model.mediaDevices,
          model: self.model.call,
          recorder: self.model,
          el: self.$mediaDeviceSelect
        })

        mediaDeviceSelect.render()
      })
    },

    renderVoipSetting: function () {
      var voipSetting = new zc.views.VoipSettingView({
        model: this.settings,
        el: this.$voipSetting
      })

      voipSetting.render()
    },

    renderMonitorSetting: function () {
      var monitorSetting = new zc.views.MonitorSettingView({
        model: this.model,
        el: this.$monitorSetting
      })

      monitorSetting.render()
    },

    renderEchoCancellationSetting: function () {
      var echoCancellationSetting = new zc.views.EchoCancellationSettingView({
        model: this.settings,
        el: this.$echoCancellationSetting
      })

      echoCancellationSetting.render()
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.$mediaDeviceSelect = this.$('.media-device-select')
      this.$echoCancellationSetting = this.$('.echo-cancellation-setting')
      this.$voipSetting = this.$('.voip-setting')
      this.$monitorSetting = this.$('.monitor-setting')
      this.renderMediaDeviceSelect()
      this.renderEchoCancellationSetting()
      this.renderVoipSetting()
      this.renderMonitorSetting()
      return this
    }
  })
})()
