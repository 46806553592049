 /* globals zc window app Backbone _ utils analytics */

(function () {
  'use strict'

  zc.views.SignupFormView = zc.views.FormView.extend({
    initialize: function (options) {
      this.signupCallback = options.callback

      this.model = new Backbone.Model()
      this.model.set('selectedPlan', options.selectedPlan)
      this.model.set('signUpOnlyMode', options.signUpOnlyMode)
      this.model.set('couponCode', options.couponCode)
      this.model.set('plans', window.servars.activePlans)
    },

    template: _.template($('.signup-form-template').html()),

    className: 'signup-form form panel',

    url: function () {
      return '/api/signup'
    },

    validateFirstName: function (name) {
      var validRegex = new RegExp(/^.{1,20}$/)

      if (!name) {
        return 'Please enter your first name'
      }

      if (!validRegex.test(name)) {
        return 'Your first name must be between 1 and 20 characters in length'
      }
    },

    validateLastName: function (name) {
      var validRegex = new RegExp(/^.{1,20}$/)

      if (!name) return false // last names aren't required

      if (!validRegex.test(name)) {
        return 'Your last name must be between 1 and 20 characters in length'
      }
    },

    validateLogin: function (login) {
      var loginRegex = new RegExp(/^[a-z0-9_]{3,20}$/)

      if (!loginRegex.test(login)) {
        return 'Usernames must be between 3 and 20 characters in length and only contain characters a-z, 0-9, and _'
      }
    },

    validateEmail: function (email) {
      if (!utils.validate.isEmail(email)) {
        return 'Please enter a valid email address'
      }
    },

    validatePassword: function (password) {
      if (!password) {
        return 'Please enter a password'
      }
    },

    validateForm: function (attrs) {
      var validFirstNameError = this.validateFirstName(attrs.firstName)
      if (validFirstNameError) {
        this.$firstName.addClass('form-error')
        return validFirstNameError
      }
      this.$firstName.removeClass('form-error')

      var validLastNameError = this.validateLastName(attrs.lastName)
      if (validLastNameError) {
        this.$lastName.addClass('form-error')
        return validLastNameError
      }
      this.$lastName.removeClass('form-error')

      var validLoginError = this.validateLogin(attrs.login)
      if (validLoginError) {
        this.$username.addClass('form-error')
        return validLoginError
      }
      this.$username.removeClass('form-error')

      var validEmailError = this.validateEmail(attrs.email)
      if (validEmailError) {
        this.$email.addClass('form-error')
        return validEmailError
      }
      this.$email.removeClass('form-error')

      var validPasswordError = this.validatePassword(attrs.password)
      if (validPasswordError) {
        this.$password.addClass('form-error')
        return validPasswordError
      }
      this.$password.removeClass('form-error')

      return false
    },

    values: function () {
      return {
        firstName: utils.sanitizeText(this.$firstName.val().trim()),
        lastName: utils.sanitizeText(this.$lastName.val().trim()),
        login: this.$username.val().toLowerCase(),
        email: this.$email.val().toLowerCase(),
        password: this.$password.val(),
        tos: window.servars.tos
      }
    },

    callback: function (err, user) {
      if (err) return this.signupCallback(err)

      try {
        analytics.alias(user._id)
        analytics.identify(user._id, {
          name: user.firstName + ' ' + user.lastName,
          username: user.username,
          email: user.email,
          plan: user.plan,
          createdAt: user.createdAt
        })
        analytics.track('Registered', {
          username: user.username,
          email: user.email
        })

        app.set('user', new zc.models.User(user))
        app.user.setLoggedIn()
        this.signupCallback(null, app.user)
      } catch (err) {
        this.signupCallback(err)
      }
    },

    renderCouponForm: function () {
      var self = this
      var couponModel = new zc.models.Billing({couponCode: this.model.get('couponCode')})
      var couponForm = new zc.views.CouponFormView({
        el: this.$couponForm,
        model: couponModel,
        callback: function (err, coupon) {
          if (err || !coupon) {
            console.error('Error applying coupon', err)
            return couponForm.showError('Error applying coupon code')
          }

          self.model.set({coupon: coupon})
          couponModel.set({coupon: coupon})
          couponForm.render()
        }
      })

      // render the couponForm first before we potentially auto-submit it
      couponForm.render()

      var couponCode = this.model.get('couponCode')
      var coupon = this.model.get('coupon')

      // if have a couponCode but not a coupon, then submit to fetch the coupon
      if (couponCode && !coupon) {
        couponForm.submit()
      }
    },

    render: function () {
      var view = this
      var attrs = this.model ? this.model.toJSON() : {}
      this.$el.html(this.template(attrs))
      this.$error = this.$('.error')
      this.$firstName = this.$('.first-name')
      this.$lastName = this.$('.last-name')
      this.$username = this.$('.username')
      this.$email = this.$('.email')
      this.$password = this.$('.password')

      if (this.model.get('signUpOnlyMode') && this.model.get('couponCode')) {
        this.$couponForm = this.$('.coupon-form')
        this.renderCouponForm()
      }
      _.defer(function () {
        view.$firstName.focus()
      })

      return this
    }
  })
})()
