/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.MediaDeviceSelectView = Backbone.View.extend({
    initialize: function (options) {
      this.mediaDevices = this.collection
      this.call = this.model
      this.recorder = options.recorder

      this.listenTo(this.mediaDevices, 'add', this.render)
      this.listenTo(this.mediaDevices, 'reset', this.render)
      this.listenTo(this.recorder, 'change:micArmed', this.micArmedChange)
    },

    template: _.template($('.media-device-select-template').html()),

    className: 'media-device-select',

    events: {
      'change .audio-input-select': 'inputSelectChange',
      'change .audio-output-select': 'outputSelectChange'
    },

    inputSelectChange: function (e) {
      var self = this
      this.mediaDevices.fetch().then(function () {
        var audioInput = self.mediaDevices.getInputDeviceById(e.target.value)
        self.call.audioInput.set(audioInput.toJSON())
      })
      console.log('Changed audio input', e.target.innerText)
    },

    outputSelectChange: function (e) {
      var self = this
      this.mediaDevices.fetch().then(function () {
        var audioOutput = self.mediaDevices.getOutputDeviceById(e.target.value)
        self.call.audioOutput.set(audioOutput.toJSON())
      })
      console.log('Changed audio output', e.target.innerText)
    },

    micArmedChange: function () {
      var view = this
      this.mediaDevices.fetch().then(function (mediaDevices) {
        view.render()
      })
    },

    render: function () {
      var attrs = {
        audioInputDevices: this.mediaDevices.getInputDevices().toJSON(),
        audioOutputDevices: this.mediaDevices.getOutputDevices().toJSON(),
        recorder: this.recorder
      }

      this.$el.html(this.template(attrs))

      var selectedInputId = this.call.audioInput.get('deviceId')
      if (selectedInputId) {
        this.$('.audio-input option[value="' + selectedInputId + '"]').attr('selected', true)
      }

      var selectedOutputId = this.call.audioOutput.get('deviceId')
      if (selectedOutputId) {
        this.$('.audio-output option[value="' + selectedOutputId + '"]').attr('selected', true)
      }

      return this
    }
  })
})()
