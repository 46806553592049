/* globals $ zc _ app utils */

(function () {
  'use strict'

  zc.views.ResetPasswordPageView = zc.views.PageView.extend({
    initialize: function (options) {
      zc.views.PageView.prototype.initialize.call(this, options)
    },

    template: _.template($('.reset-password-page-template').html()),

    className: 'reset-password-page page',

    events: {
      'click .reset-password': 'handleSubmit',
      'keypress input': 'inputKeypress'
    },

    inputKeypress: function (e) {
      if (e.keyCode === 13) {
        this.handleSubmit()
      }
    },

    showError: function () {
      this.$error.text('Could not reset password. Please try again or contact support.')
    },

    validateEmail: function (email) {
      if (!utils.validate.isEmail(email)) {
        return 'Please enter a valid email address'
      }
    },

    validatePassword: function (password) {
      if (!password) {
        return 'Please enter a password'
      }
    },

    validateForm: function (attrs) {
      var validEmailError = this.validateEmail(attrs.login)
      if (validEmailError) {
        return validEmailError
      }

      var validPasswordError = this.validatePassword(attrs.password)
      if (validPasswordError) {
        return validPasswordError
      }

      return false
    },

    handleSubmit: function () {
      if (this.model.get('hash')) {
        this.resetPassword()
      } else {
        this.sendEmail()
      }
    },

    resetPassword: function () {
      var view = this
      var attrs = {
        hash: this.model.get('hash'),
        password: this.$('.password').val()
      }

      var validationError = this.validatePassword(attrs.password)

      if (validationError) {
        return this.showError(validationError)
      }

      this.submit(attrs)
      .success(function () {
        app.router.navigate('/login', {trigger: true})
        utils.notify('success', 'Your password has been reset')
      }).error(function (err) {
        view.showError(err.responseText)
      })
    },

    sendEmail: function () {
      var attrs = {
        email: this.$('.email').val().toLowerCase()
      }

      var validationError = this.validateEmail(attrs.email)

      if (validationError) {
        return this.showError(validationError)
      }

      this.submit(attrs)
      .success(function () {
        utils.notify('success', 'You will receive an email at: ' + attrs.email + ' with password reset instructions')
      })
    },

    submit: function (attrs) {
      return $.ajax({
        url: '/api/reset-password',
        type: 'POST',
        data: attrs
      })
    },

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      this.$error = this.$('.error')

      return this
    }
  })
})()
