/* globals zc _ Backbone utils app moment */

(function () {
  'use strict'

  zc.views.PlanUsageView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model.stats, 'change:usedCredits', this.render)
      this.listenTo(this.model.subscription, 'change', this.render)
      this.listenTo(this.model, 'change:onetimePostproductionCredits', this.render)
    },

    template: _.template($('.plan-usage-template').html()),

    className: 'plan-usage',

    events: {
      'click .change-plan': 'renderPlansModal',
      'click .buy-more-time': 'renderBuyPostproductionCreditsModal'
    },

    planSelectCallback: function (err, subscription) {
      if (err) {
        utils.notify('error', err)
      } else {
        utils.notify('success', 'You are now subscribed to the ' + subscription.plan.name + ' plan')
      };
      this.plansModalView.exit()
    },

    renderPlansModal: function () {
      this.plansModalView = new zc.views.ModalView({
        addClass: 'plans-modal',
        model: app.user.billing,
        ChildView: zc.views.PlansView,
        callback: this.planSelectCallback.bind(this),
        showCouponForm: true
      })
      this.plansModalView.render()
    },

    buyPostproductionCreditsCallback: function (err, res) {
      if (err) {
        // do nothing.  error displayed upstream
        utils.notify('error', err)
      } else {
        this.model.incPostproductionCredits(res.purchasedCredits)
        utils.notify('success', 'Your purchase was successful', {ttl: 5000})
      }

      this.buyPostproductionCreditsModalView.exit()
    },

    renderBuyPostproductionCreditsModal: function () {
      this.buyPostproductionCreditsModalView = new zc.views.ModalView({
        addClass: 'buy-postproduction-credits-modal',
        model: app.user.billing,
        ChildView: zc.views.BuyPostproductionCreditsView,
        callback: this.buyPostproductionCreditsCallback.bind(this)
      })
      this.buyPostproductionCreditsModalView.render()
    },

    render: function () {
      var attrs = this.model.subscription.toJSON()
      attrs.plan = this.model.subscription.plan.toJSON()
      attrs.activeUntilReadable = moment(attrs.activeUntil).format('M/D/YYYY')
      attrs.activeTimeRemaining = moment(attrs.activeUntil).fromNow()
      attrs.recurringPostproductionCredits = this.model.subscription.plan.get('features').recurringPostproductionCredits
      attrs.usedPostproductionCredits = this.model.getUsedPostproductionCredits()
      attrs.availablePostproductionCredits = this.model.getAvailablePostproductionCredits()
      attrs.postproductionRenewsOn = moment(Date.now()).add(1, 'month').date('1').format('M/D/YYYY')
      attrs.onetimePostproductionCredits = this.model.get('onetimePostproductionCredits')
      this.$el.html(this.template(attrs))

      this.model.stats.fetch()

      return this
    }
  })
})()
