/* globals zc _ Backbone app moment utils */

(function () {
  'use strict'

  zc.models.ProjectLink = Backbone.Model.extend({
    initialize: function (attrs) {
      this.recordings = new zc.collections.Recordings(attrs.recordings, {project: this})

      this.localStorageManager = app.localStorageManager
    },

    defaults: {
      localStorageUsed: 0
    },

    attrs: function () {
      var attrs = this.toJSON()

      attrs.formattedLocalStorageUsed = this.getFormattedDiskSpace(attrs.localStorageUsed)

      attrs.createdAtReadable = attrs.createdAt ? moment(attrs.createdAt).format('dddd MMM Do YYYY') : null
      attrs.durationReadable = utils.msToHms(attrs.duration || this.totalDuration())
      attrs.recordingCount = this.recordings.length

      attrs.archived = !!attrs.deletedAt
      attrs.deletedAtReadable = attrs.archived ? moment(attrs.deletedAt).format('dddd MMM Do YYYY') : null

      return attrs
    },

    getFormattedDiskSpace: function (spaceUsed) {
      return utils.formattedDiskSpace(spaceUsed)
    },

    totalDuration: function () {
      var total = 0
      _.each(this.get('recordings'), function (recording) {
        total += recording.duration
      })
      return total
    },

    calcLocalStorageUsed: function () {
      var self = this
      return new Promise(function (resolve, reject) {
        var promises = self.recordings.map(function (recording) {
          return recording.calcLocalStorageUsed()
        })

        Promise.all(promises).then(function (sizes) {
          var size = _.reduce(sizes, function (a, b) { return a + b }, 0)
          resolve(size)
        }, function (err) {
          reject(err)
        })
      })
    },

    calcAndSetLocalStorageUsed: function () {
      var self = this
      return this.calcLocalStorageUsed().then(function (size) {
        console.log('Project Local Storage Used: ', size)
        self.set({localStorageUsed: size})
      }, function (err) {
        console.log('ERR: ', err)
      })
    },

    deleteLocalStorage: function () {
      var self = this

      var promises = self.recordings.map(function (recording) {
        return recording.deleteLocalStorage()
      })

      return Promise.all(promises)
    }
  })
})()
