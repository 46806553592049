/* globals zc _ particlesJS */

(function () {
  'use strict'

  zc.views.LandingPageView = zc.views.PageView.extend({
    initialize: function (options) {
      zc.views.PageView.prototype.initialize.call(this, options)
    },

    template: _.template($('.landing-page-template').html()),

    className: 'landing-page page',

    initTestimonials: function () {
      this.$testimonials = this.$el.find('.testimonial')
      this.showTestimonialAt(this.getRandomTestimonialIndex())

      setInterval(this.nextTestimonial.bind(this), 10000)
    },

    getRandomTestimonialIndex: function () {
      return Math.floor(Math.random() * this.$testimonials.length)
    },

    getNextTestimonialIndex: function () {

    },

    nextTestimonial: function () {
      var self = this
      var $next = this.$testimonial.next()
      if (!$next.length) {
        $next = this.$testimonials.first()
      }
      this.hideTestimonial()
      setTimeout(function () {
        self.showTestimonial($next)
      }, 500)
    },

    showTestimonialAt: function (idx) {
      this.$testimonial = $(this.$testimonials[idx])
      this.showTestimonial(this.$testimonial)
    },

    showTestimonial: function ($testimonial) {
      this.$testimonial = $testimonial
      $testimonial.css({opacity: 1, 'z-index': 100})
    },

    hideTestimonial: function () {
      this.$testimonial.css({opacity: 0, 'z-index': ''})
    },

    planSelectCallback: function (plan) {
      console.log('PLAN: ', plan)
      window.location.href = '/dashboard'
    },

    renderPlans: function () {
      var plansView = new zc.views.PlansView({
        model: new zc.models.Billing(),
        el: this.$plans,
        showCouponForm: false,
        callback: this.planSelectCallback.bind(this)
      })

      this.$plans.append(plansView.render().el)
    },

    renderBackground: function () {
      particlesJS('particles', {
        'particles': {
          'number': {
            'value': 3,
            'density': {
              'enable': false,
              'value_area': 800
            }
          },
          'color': {
            'value': '#ffffff'
          },
          'shape': {
            'type': 'polygon',
            'stroke': {
              'width': 1,
              'color': 'rgba(255,255,255,0.1)'
            },
            'polygon': {
              'nb_sides': 6
            },
            'image': {
              'src': 'img/github.svg',
              'width': 100,
              'height': 100
            }
          },
          'opacity': {
            'value': 0.03,
            'random': false,
            'anim': {
              'enable': false,
              'speed': 1,
              'opacity_min': 0.1,
              'sync': false
            }
          },
          'size': {
            'value': 209.12412503255226,
            'random': false,
            'anim': {
              'enable': false,
              'speed': 40,
              'size_min': 0.1,
              'sync': false
            }
          },
          'line_linked': {
            'enable': false,
            'distance': 150,
            'color': '#ffffff',
            'opacity': 0.4,
            'width': 1
          },
          'move': {
            'enable': true,
            'speed': 0.5,
            'direction': 'top',
            'random': false,
            'straight': false,
            'out_mode': 'out',
            'bounce': false,
            'attract': {
              'enable': false,
              'rotateX': 600,
              'rotateY': 1200
            }
          }
        },
        'interactivity': {
          'detect_on': 'canvas',
          'events': {
            'onhover': {
              'enable': false,
              'mode': 'bubble'
            },
            'onclick': {
              'enable': false,
              'mode': 'push'
            },
            'resize': true
          },
          'modes': {
            'grab': {
              'distance': 400,
              'line_linked': {
                'opacity': 0.07403629423668587
              }
            },
            'bubble': {
              'distance': 400,
              'size': 40,
              'duration': 2,
              'opacity': 8,
              'speed': 3
            },
            'repulse': {
              'distance': 48.72463273808071,
              'duration': 0.4
            },
            'push': {
              'particles_nb': 4
            },
            'remove': {
              'particles_nb': 2
            }
          }
        },
        'retina_detect': true
      })
    },

    render: function () {
      var self = this
      this._render()
      this.$plans = this.$('.plans')

      this.renderPlans()

      _.defer(function () {
        self.renderBackground()
        self.initTestimonials()
      })

      return this
    }
  })
})()
