/* globals zc _ utils $ */

(function () {
  'use strict'

  zc.views.EmailVerificationPageView = zc.views.PageView.extend({
    initialize: function (options) {
      zc.views.PageView.prototype.initialize.call(this, options)

      this.sendVerification()
    },

    template: _.template($('.email-verification-page-template').html()),

    className: 'email-verification-page',

    events: {
      'click .resend-email': 'sendVerification'
    },

    sendVerification: function () {
      $.ajax({
        url: '/api/email-verification',
        type: 'POST'
      })
      .success(function () {
        utils.notify('success', 'Verification Email Sent')
      })
      .error(function (err) {
        utils.notify('error', 'There was a problem sending the verification email. Please contact support.  ' + err.status + ' : ' + err.responseText)
      })
    },

    render: function () {
      this.$el.html(this.template(this.model))
      return this
    }
  })
})()
