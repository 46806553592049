/* globals zc Backbone */

(function () {
  'use strict'

  zc.models.RecordAnalyser = Backbone.Model.extend({
    initialize: function (attrs, options) {
      this.actx = options.actx
      this.bufferNode = attrs.bufferNode || this.actx.createBuffer(2, 4096, this.actx.sampleRate)

      this.on('change:bufferNode', this.bufferNodeChange)
    },

    bufferNodeChange: function (model, bufferNode) {
      this.bufferNode = bufferNode
    }
  })
})()
