/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.MessageView = Backbone.View.extend({
    template: _.template($('.message-template').html()),

    className: 'message',

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      return this
    }
  })
})()
