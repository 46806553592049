/* globals zc _ Backbone app analytics */

(function () {
  'use strict'

  zc.views.SampleView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model, 'loading', this.showLoading)
      this.listenTo(this.model, 'change:loaded', this.loadedChange)
      this.listenTo(this.model, 'change:playing', this.playingChange)
      this.listenTo(this.model, 'change:loop', this.renderLoopState)
      this.listenTo(this.model, 'change:gain', this.renderVolume)
      this.listenTo(this.model, 'release', this.handleRelease)
      this.listenTo(this.model, 'change:name', this.render)
      this.listenTo(this.model, 'remove', this.remove)

      _.bindAll(this, 'handleVolumeMouseup', 'volumeDragCallback')
    },

    className: 'sample',

    template: _.template($('.sample-template').html()),

    events: {
      'click': 'handleClick',
      'click .volume': 'handleVolumeClick',
      'mousedown .volume': 'handleVolumeMousedown',
      'click .loop': 'toggleLoop',
      'click .delete': 'delete'
    },

    handleClick: function (e) {
      if (this.model.get('loaded')) {
        this.model.connect(app.soundboardOut)
        if (this.model.get('playing')) {
          console.log('Stopped playing sample', this.model.get('name'))
        } else {
          console.log('Started playing sample', this.model.get('name'))
        }
        this.model._trigger()
        analytics.track('Soundboard Sample Triggered', {
          name: this.model.get('name'),
          url: this.model.get('url')
        })
      }
    },

    handleVolumeClick: function (e) {
      e.stopPropagation()
    },

    handleVolumeMousedown: function (e) {
      console.log('mousedown')
      var x = e.offsetX
      var total = this.$el.outerWidth()
      var ratio = x / total
      this.model.set('gain', ratio)

      this.startVolumeDrag(e)
      this.listenToMouseup()
    },

    handleVolumeMouseup: function (e) {
      this.stopVolumeDrag(e)
      this.unlistenToMouseup()
    },

    startVolumeDrag: function (e) {
      $(document).bind('mousemove', this.volumeDragCallback)
      $('body').addClass('unselectable')
      this.startWindowPos = {x: e.pageX, y: e.pageY}
      this.startPxIntoEl = this.startWindowPos.x - this.$el.offset().left
    },

    stopVolumeDrag: function () {
      $(document).unbind('mousemove', this.volumeDragCallback)
      $('body').removeClass('unselectable')
    },

    listenToMouseup: function () {
      $(document).bind('mouseup', this.handleVolumeMouseup)
    },

    unlistenToMouseup: function () {
      $(document).unbind('mouseup', this.handleVolumeMouseup)
    },

    volumeDragCallback: function (e) {
      // where is the cursor now?
      // what is the difference from where the cursor started and where it is now?
      var pixelXDiff = e.pageX - this.startWindowPos.x
      var newPixelWidth = this.startPxIntoEl + pixelXDiff
      var outerWidth = this.$el.outerWidth()
      newPixelWidth = Math.min(newPixelWidth, outerWidth)
      newPixelWidth = Math.max(0, newPixelWidth)
      var volumeRatio = newPixelWidth / outerWidth
      this.model.set({gain: volumeRatio})
    },

    delete: function (e) {
      e.stopPropagation()
      var collection = this.model.collection
      collection.remove(this.model)
    },

    toggleLoop: function (e) {
      e.stopPropagation()
      var loop = this.model.get('loop')
      this.model.set({loop: !loop})
    },

    play: function () {
      var sample = this.model
      sample.connect(app.soundboardOut)
      sample.play()
    },

    stop: function () {
      this.model.stop()
    },

    loadedChange: function (model, loaded) {
      if (loaded) {
        this.hideLoading()
      } else {
        this.showLoading()
      }
    },

    showLoading: function () {
      this.$el.addClass('loading')
    },

    hideLoading: function () {
      this.$el.removeClass('loading')
    },

    playingChange: function (model, playing) {
      if (playing) {
        this.$el.css('transition-duration', this.model.get('attack') + 's')
        this.$el.addClass('playing')
      } else {
        this.$el.css('transition-duration', '0s')
        this.$el.removeClass('playing')
      }
    },

    handleRelease: function () {
      this.$el.css('transition-duration', this.model.get('release') + 's')
      this.$el.removeClass('playing')
    },

    renderLoopState: function () {
      var loop = this.model.get('loop')
      if (loop) {
        this.$loop.addClass('active')
      } else {
        this.$loop.removeClass('active')
      }
    },

    renderVolume: function () {
      var gain = this.model.get('gain')
      var percent = String(gain * 100) + '%'
      this.$volumeMeter.css({'width': percent})
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.$loop = this.$('.loop')
      this.$volumeMeter = this.$('.volume .meter')

      this.$el.attr('title', this.model.get('name'))

      if (!this.model.get('empty') && !this.model.get('loaded')) {
        this.showLoading()
      }

      this.renderLoopState()
      this.renderVolume()

      return this
    }
  })
})()
