/* globals zc _ app utils */

(function () {
  'use strict'

  zc.views.PostproductionView = zc.views.TrackView.extend({
    initialize: function (options) {
      zc.views.TrackView.prototype.initialize.call(this, options)
      this.listenTo(this.model, 'change:deliveredAt', this.deliveredAtChange)
      this.listenTo(this.model, 'change:completedAt', this.completedAtChange)
      this.listenTo(this.model, 'change:processing', this.processingChange)
      this.listenTo(this.model, 'change:duration', this.renderDuration)
      this.listenTo(this.model, 'change:errorMessage', this.errorChange)

      _.bindAll(this, 'refreshStatus')
    },

    template: _.template($('.postproduction-template').html()),

    className: 'postproduction track',

    download: function (e) {
      e.preventDefault()

      if (this.model.get('downloading')) {
        // don't allow double downloads
        return
      }

      this.model.set({progress: 0, downloading: true})

      this.model.downloadFile(['/' + app.user.id, this.model.getFinalMixFolder(), this.model.get('filename')].join('/'))
    },

    completedAtChange: function (model, completedAt) {
      if (completedAt) {
        this.model.set({uploading: true, progress: 85})
      }
    },

    deliveredAtChange: function (model, deliveredAt) {
      var view = this
      if (deliveredAt) {
        this.$progressBar.css({'transition-duration': '200ms'})
        this.model.set({'progress': 100})
        setTimeout(function () {
          view.model.set({processing: false, uploading: false})
          view.$el.addClass('finalized')
          view.model.set({'progress': 0})
        }, 1500)
      }
    },

    processingChange: function (model, processing) {
      if (processing) {
        this.$el.addClass('processing')
      } else {
        this.$el.removeClass('processing')
      }
    },

    refreshStatus: function () {
      var view = this
      // if this view is no longer present in the dom (eg the user moved to a different recording)
      // stop polling for data
      if (!document.body.contains(this.el)) {
        clearInterval(view.pollId)
        return
      }

      app.socket.emit('postproduction:read', {_id: this.model.id}, function (err, postproduction) {
        if (err) utils.notify('error', err)

        if (!postproduction) {
          return view.$processingStatus.text('Waiting in Queue...')
        }

        // set processing to true always here so we can delay the ui change
        postproduction.processing = true
        view.model.set(postproduction)

        if (postproduction.deliveredAt) {
          clearInterval(view.pollId)
        }

        if (postproduction.errorMessage) {
          view.model.set({processing: false})
          clearInterval(view.pollId)
        }
      })
    },

    showUploading: function () {
      this.$processingStatus.text('Transferring to Cloud...')
    },

    pollStatus: function () {
      this.pollId = setInterval(this.refreshStatus, 5 * 1000)
    },

    errorChange: function (model, error) {
      if (error) {
        this.showError(error)
      } else {
        this.hideError()
      }
    },

    showError: function (error) {
      this.$el.removeClass('fixing-error')
      this.$el.addClass('has-error')
      this.$errorMessage.text(error)
    },

    hideError: function () {
      this.$el.removeClass('has-error')
    },

    errorDependencyChange: function () {
      this.checkError()
    },

    checkError: function () {
      var attrs = this.model.attrs()
      if (attrs.status === 2) {
        this.model.set({'error': attrs.statusString})
      } else if (!attrs.processing && !attrs.deliveredAt && !attrs.uploading) {
        this.model.set({error: 'Uh Oh... Something went wrong :/'})
      }
    },

    renderDuration: function () {
      this.$duration.text(this.model.attrs().hmsDuration)
    },

    render: function () {
      var view = this
      var attrs = this.model.attrs()
      this.$el.html(this.template(attrs))
      this.$duration = this.$('.duration')
      this.$errorMessage = this.$('.error-message')
      this.$processingStatus = this.$('.processing-status')
      this.$progressBar = this.$('.progress-bar')
      this.$progressPercent = this.$('.progress-percent')

      if (attrs.processing) {
        this.$el.addClass('processing')
        this.pollStatus()
      }

      if (attrs.deliveredAt) {
        this.$el.addClass('finalized')
      }

      if (this.model.get('uploading')) {
        this.showUploading()
      }

      this.checkError()

      if (this.model.get('error')) {
        this.showError()
      }

      // force progress to render
      _.defer(function () {
        view.model.trigger('change:progress', view.model, view.model.get('progress'))
      })

      return this
    }

  })
})()
