/* globals zc _ */

(function () {
  'use strict'

  function Pixel (x, y) {
    this.x = x
    this.y = y
    this.hue = Math.floor(Math.random() * 360)
    var direction = Math.random() > 0.5 ? -1 : 1
    this.velocity = (Math.random() * 30 + 20) * 0.01 * direction
  }

  Pixel.prototype.update = function () {
    this.hue += this.velocity
  }

  Pixel.prototype.render = function (ctx) {
    var hue = Math.round(this.hue)
    ctx.fillStyle = 'hsl(' + hue + ', 100%, 50% )'
    ctx.fillRect(this.x, this.y, 1, 1)
  }

  zc.views.MovingBackgroundView = zc.views.BaseView.extend({
    template: _.template('<canvas class="moving-bg takeover" width="2" height="2"></canvas>'),
    startAnimation: function () {
      var canvas = this.$el.find('canvas').get(0)
      var ctx = canvas.getContext('2d')
      var self = this

      var pixels = [
        new Pixel(0, 0),
        new Pixel(0, 1),
        new Pixel(1, 0),
        new Pixel(1, 1)
      ]

      function animate () {
        pixels.forEach(function (pixel) {
          pixel.update()
          pixel.render(ctx)
        })
        self.rafId = requestAnimationFrame(animate)
      }

      animate()
    },
    stopAnimation: function () {
      cancelAnimationFrame(self.rafId)
    },
    render: function () {
      this._render()
    }
  })
})()
