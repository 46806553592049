/* globals zc _ Backbone app utils */

(function () {
  zc.views.PostproductionConfigView = Backbone.View.extend({
    initialize: function (options) {
      this.callback = options.callback
      this.tracks = this.model.tracks

      this.listenTo(this.model, 'change:loudnessTarget', this.loudnessTargetChange)
      this.listenTo(this.model, 'change:leveler', this.levelerChange)
      this.listenTo(this.model, 'change:gate', this.noiseGateChange)
      this.listenTo(this.model, 'change:crossGate', this.crossGateChange)
      this.listenTo(this.model.tracks, 'change:selected', this.trackSelectedChange)
      this.listenTo(app.user.stats, 'change:usedCredits', this.renderPostproductionTime)
      this.listenTo(app.user, 'change:onetimePostproductionCredits', this.renderPostproductionTime)

      this.msPerCredit = 3600000 // 1 hour
      this.pricePerCredit = 300
    },

    template: _.template($('.postproduction-config-template').html()),

    className: 'postproduction-config panel',

    events: {
      'click': 'checkForExit',
      'click .close': 'exit',
      'click .advanced-options': 'toggleAdvancedOptions',
      'change #loudness-target-input': 'loudnessTargetInputChange',
      'change #leveler-input': 'levelerInputChange',
      'change #noise-gate-input': 'noiseGateInputChange',
      'change #cross-gate-input': 'crossGateInputChange',
      'change #separate-tracks-input': 'separateTracksInputChange',
      'change #transcript-input': 'transcriptInputChange',
      'change #transcript-language': 'transcriptInputLanguageChange',
      'click .buy-more-time': 'renderBuyPostproductionCreditsModal',
      'click .run-postproduction': 'validate'
      // 'click .run-paid-postproduction': 'confirmPaymentAndValidate'
    },

    // storage for trackviews so we can access them
    trackViews: {},

    toggleAdvancedOptions: function (e) {
      if (this.$options.is(':visible')) {
        this.$options.fadeOut()
      } else {
        this.$options.fadeIn()
      }
    },

    transcriptInputChange: function (e) {
      this.model.set('transcript', e.target.checked)
      // set default value for the language
      this.model.set('transcriptLanguage', 'en')

      // this.$languageContainer.slideToggle()
      this.renderPostproductionTime()
    },

    transcriptInputLanguageChange: function (e) {
      this.model.set('transcriptLanguage', e.target.value)
    },

    separateTracksInputChange: function (e) {
      this.model.set('separateTracks', e.target.value)
    },

    loudnessTargetInputChange: function (e) {
      this.model.set('loudnessTarget', e.target.value)
    },

    loudnessTargetChange: function (model, loudnessTarget) {
      var $selected = this.$loudnessTargetInput.find(':selected')
      if ($selected.val() !== loudnessTarget) {
        $selected.attr('selected', false)
        this.$loudnessTargetInput.find('option[value=' + loudnessTarget + ']').attr('selected', true)
      }
    },

    levelerInputChange: function (e) {
      this.model.set('leveler', !!e.target.checked)
    },

    levelerChange: function (model, leveler) {
      this.$levelerInput.prop('checked', leveler)
    },

    noiseGateInputChange: function (e) {
      this.model.set('gate', !!e.target.checked)
    },

    noiseGateChange: function (model, gate) {
      this.$noiseGateInput.prop('checked', gate)
    },

    crossGateInputChange: function (e) {
      this.model.set('crossGate', !!e.target.checked)
    },

    crossGateChange: function (model, crossGate) {
      this.$crossGateInput.prop('checked', crossGate)
    },

    getNeededPostproductionTime: function () {
      var neededTime = this.tracks.where({selected: true})
        .map(function (track) {
          return track.get('duration')
        }).reduce(function (a, b) {
          return Math.max(a, b)
        }, 0)

      // if the user wants to run transcription
      if (this.model.get('transcript')) {
        neededTime += this.tracks.where({selected: true})
          .map(function (track) {
            return track.get('duration')
          }).reduce(function (a, b) {
            return a + b
          }, 0)
      }

      neededTime = Math.max(neededTime, 60 * 1000 * 3) // 3 minute minimum

      return neededTime
    },

    enforceOneTrackPerParticipant: function (track, selected) {
      if (!selected) return
      var self = this

      // if the selected track is the soundboar done, just return
      // we don't want to enforce any rules here
      if (track.get('type') === 'soundboard') {
        return
      }

      var tracks = track.collection
      var participantTracks = tracks.filter(function (t) {
        if (track.get('userId') === t.get('userId') && t.get('type') !== 'soundboard') {
          return true
        }
        return false
      })
      participantTracks.forEach(function (loopTrack) {
        if (loopTrack.id !== track.id) {
          loopTrack.set({selected: false})
          var trackView = self.trackViews[loopTrack.id]
          if (trackView) trackView.unselect()
        }
      })
    },

    trackSelectedChange: function (track, selected) {
      this.enforceOneTrackPerParticipant(track, selected)
      this.renderPostproductionTime()
    },

    validate: function () {
      var selectedTracks = this.tracks.where({selected: true})
      var error = false
      var neededTime = this.getNeededPostproductionTime()
      var neededCredits = neededTime / this.msPerCredit
      var availableCredits = app.user.getAvailablePostproductionCredits()
      if (!selectedTracks.length) {
        error = 'You must select at least one track'
      } else if (neededCredits > availableCredits) {
        error = 'You don\'t have sufficient postproduction time available'
      }

      if (!error) {
        if (this.price) {
          this.renderConfirmPurchase()
        } else {
          var args = {
            selectedTracks: selectedTracks,
            credits: neededCredits
          }
          this.callback(args)
        }
      } else {
        this.showError(error)
      }
    },

    showError: function (err) {
      this.$error.text(err)
    },

    buyPostproductionCreditsCallback: function (err, res) {
      if (err) return utils.notify('error', err)
      var purchasedCredits = res.purchasedCredits
      app.user.incPostproductionCredits(purchasedCredits)
      this.buyPostproductionCreditsModalView.exit()
    },

    calcPurchasePrice: function (timeToPurchase) {
      var creditsToPurchase = timeToPurchase / this.msPerCredit
      var price = Math.round(creditsToPurchase * this.pricePerCredit)
      return price
    },

    purchaseCredits: function (timeToPurchase) {
      var self = this

      var numCredits = timeToPurchase / 1000 / 60 / 60

      // minimum of 1 credit
      // var creditsToPurchase = Math.max(numCredits, 1)

      app.user.billing.purchasePostproductionCredits(numCredits)
        .then(function (charge) {
          var selectedTracks = self.tracks.where({selected: true})
          self.callback({selectedTracks: selectedTracks, credits: numCredits})
        })
    },

    confirmPurchaseCallback: function (confirmed) {
      this.confirmPurchaseModalView.exit()
      if (confirmed) {
        this.purchaseCredits(this.timeToPurchase)
      } else {
        console.log('Not Confirmed')
      }
    },

    renderConfirmPurchase: function () {
      var price = this.price
      var title = 'Confirm Purchase'
      var text = 'Please confirm that you would like to make a ' +
                 '<strong>one-time</strong> payment of ' +
                 '<strong>$' + Number(price / 100).toFixed(2) + '</strong>'

      this.confirmPurchaseModalView = new zc.views.ModalView({
        className: 'modal confirm-purchase-modal',
        model: new Backbone.Model({title: title, text: text}),
        ChildView: zc.views.ConfirmView,
        callback: this.confirmPurchaseCallback.bind(this)
      })

      this.confirmPurchaseModalView.render()
    },

    renderBuyPostproductionCreditsModal: function () {
      this.buyPostproductionCreditsModalView = new zc.views.ModalView({
        addClass: 'buy-postproduction-credits-modal',
        model: app.user.billing,
        ChildView: zc.views.BuyPostproductionCreditsView,
        callback: this.buyPostproductionCreditsCallback.bind(this)
      })
      this.buyPostproductionCreditsModalView.render()
    },

    renderPostproductionTime: function () {
      var totalCredits = app.user.getTotalPostproductionCredits()
      var availableCredits = app.user.getAvailablePostproductionCredits()
      var totalTime = totalCredits * this.msPerCredit
      var availableTime = availableCredits * this.msPerCredit
      var neededTime = this.getNeededPostproductionTime()
      // neededTime = 60 * 60 * 1000 * 4.11;

      if (neededTime > availableTime) {
        this.$el.addClass('out-of-time')
        this.timeToPurchase = Math.max(neededTime - availableTime, 60 * 60 * 1000) // min of 1 hr
        this.timeToPurchase = neededTime - availableTime
        this.price = this.calcPurchasePrice(this.timeToPurchase)
        // this.price = Math.max(this.price, 99);
        this.renderPaidPostproduction()
      } else {
        this.$el.removeClass('out-of-time')
        this.timeToPurchase = 0
        this.price = 0
      }

      var highTime = Math.max(totalTime, neededTime)

      var availableWidth = Math.min((availableTime / highTime) * 100, 100)
      this.$availableTimeHms.html(utils.msToHms(availableTime))
      this.$availableTimeBar.css({width: '' + availableWidth + '%'})

      var neededWidth = Math.min((neededTime / highTime) * 100, 100)
      this.$neededTimeHms.html(utils.msToHms(neededTime))
      this.$neededTimeBar.css({width: '' + neededWidth + '%'})
    },

    renderPaidPostproduction: function () {
      this.$runPaid.text('Run Postproduction For $' + Number(this.price / 100).toFixed(2))
    },

    renderTracks: function () {
      var view = this

      // group tracks by username
      var groupedTracks = _.groupBy(this.tracks.models, function (track) { return track.attributes.username })

      // see if the host has a soundboard track
      var soundboardTrack = []
      var hostTracks = groupedTracks[app.user.get('username')]
      groupedTracks[app.user.get('username')] = hostTracks.filter(function (track) {
        if (track.get('type') === 'soundboard') {
          soundboardTrack = [track]
          return false
        }
        return true
      })

      if (soundboardTrack.length) {
        groupedTracks['soundboard'] = soundboardTrack
      }

      for (var user in groupedTracks) {
        var tracks = groupedTracks[user]

        // sort the tracks by format
        var sortedTracks = _.sortBy(tracks, function (track) { return track.attributes.format })

        for (var i = 0; i < sortedTracks.length; i++) {
          var track = sortedTracks[i]
          if (track.get('finalized')) {
            track.set('selectable', true)
            view.renderTrack(track)
          }
        }
      }
    },

    renderTrack: function (track) {
      var trackView = new zc.views.TrackView({model: track})
      this.trackViews[track.id] = trackView
      var $track = trackView.render().$el
      this.$tracks.append($track)
    },

    remove: function () {
      this.tracks.forEach(function (track) {
        track.set({selectable: false, selected: false})
      })
    },

    render: function () {
      var attrs = this.model.attrs()
      this.$el.html(this.template(attrs))

      this.$tracks = this.$('.tracks')
      this.$error = this.$('.error')
      this.$timeStats = this.$('.time-stats')
      this.$availableTimeHms = this.$('.available-time-hms')
      this.$availableTimeBar = this.$('.available-time .bar')
      this.$neededTimeHms = this.$('.needed-time-hms')
      this.$neededTimeBar = this.$('.needed-time .bar')
      // this.$languageContainer = this.$('.language-container')

      this.$run = this.$('.run-postproduction')
      this.$runPaid = this.$('.run-paid-postproduction')

      this.$options = this.$('.options')
      this.$loudnessTargetInput = this.$('#loudness-target-input')
      this.$levelerInput = this.$('#leveler-input')
      this.$noiseGateInput = this.$('#noise-gate-input')
      this.$crossGateInput = this.$('#cross-gate-input')

      app.user.stats.fetch()
      this.renderPostproductionTime()

      this.renderTracks()

      return this
    }
  })
})()
