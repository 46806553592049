/* globals zc Backbone app */

(function () {
  'use strict'

  zc.models.Lobby = Backbone.Model.extend({
    initialize: function () {
      this.users = new zc.collections.Users([app.user])
    },

    getHost: function () {
      for (var i = 0; i < this.users.length; i++) {
        var user = this.users.at(i)
        if (user.isHost()) {
          return user
        }
      }
    },

    allMicsArmed: function () {
      var allArmed = true

      this.users.each(function (user) {
        if (!user.get('micArmed')) {
          allArmed = false
        }
      })

      return allArmed
    }
  })
})()
