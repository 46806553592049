/* globals zc app _ Backbone utils moment */

(function () {
  'use strict'

  zc.views.PlanInfoView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model.stats, 'change:usedCredits', this.render)
      this.listenTo(this.model.subscription, 'change', this.render)
    },

    template: _.template($('.plan-info-template').html()),

    events: {
      'click .change-plan': 'renderPlansModal'
    },

    planSelectCallback: function (err, subscription) {
      if (err) {
        utils.notify('error', err)
      } else {
        var message = 'You are now subscribed to the ' + subscription.plan.name + ' plan'

        // if the user downgraded, show a different message
        if (subscription.futureDowngrade) {
          message = 'You will start on the new plan at the end of the current billing cycle.'
        }
        utils.notify('success', message)
        this.model.subscription.set(subscription)
      };
      this.plansModal.exit()
    },

    renderPlansModal: function () {
      this.plansModal = new zc.views.ModalView({
        addClass: 'plans-modal',
        model: app.user.billing,
        ChildView: zc.views.PlansView,
        callback: _.bind(this.planSelectCallback, this),
        showCouponForm: true
      })
      this.plansModal.render()
    },

    render: function () {
      var attrs = this.model.subscription.toJSON()
      attrs.plan = this.model.subscription.plan.toJSON()
      attrs.activeUntilReadable = moment(attrs.activeUntil).format('M/D/YYYY')
      attrs.canceledAt = attrs.canceledAt && moment(attrs.canceledAt).format('M/D/YYYY')
      attrs.activeTimeRemaining = moment(attrs.activeUntil).fromNow()
      attrs.availablePostproductionCredits = this.model.getAvailablePostproductionCredits()
      attrs.recurringPostproductionCredits = this.model.subscription.plan.get('features').recurringPostproductionCredits
      this.$el.html(this.template(attrs))

      this.model.stats.fetch()

      return this
    }
  })
})()
