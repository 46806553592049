/* globals zc _ utils */

(function () {
  'use strict'

  zc.views.ProjectCreateFormView = zc.views.FormView.extend({

    className: 'project-create-form form panel',

    template: _.template($('.project-create-form-template').html()),

    createProject: function (name) {
      if (utils.slugify(name)) {
        this.callback({name: name})
        this.exit()
      } else {
        this.$error.text('Please enter a valid project name')
      }
    },

    values: function () {
      return {
        name: this.$projectNameInput.val()
      }
    },

    validateName: function (name) {
      if (!utils.slugify(name)) {
        return 'Please enter a valid project name'
      }

      if (name.length > 1000) {
        return 'Episode names must be shorter than 1000 characters'
      }

      return false
    },

    validateForm: function (attrs) {
      var validNameError = this.validateName(attrs.name)
      if (validNameError) {
        return validNameError
      }

      return false
    },

    submit: function () {
      var self = this
      var values = this.values()

      var validationError = this.validateForm(values)

      if (validationError) {
        return this.showError(validationError)
      }

      this.showLoading()
      this.callback(values).catch(function () {
        self.hideLoading()
      })
    },

    render: function () {
      var self = this
      zc.views.FormView.prototype.render.call(this)
      this.$projectNameInput = this.$('.project-name-input')

      _.defer(function () {
        self.$projectNameInput.focus()
      })

      return this
    }
  })
})()
