/* globals zc Backbone debug */

(function () {
  'use strict'

  var dbg = debug('zc:peakMeterView')

  zc.views.PeakMeterView = Backbone.View.extend({
    initialize: function (options) {
      this.actx = options.actx
      this.animationFrameManager = options.animationFrameManager
      this.input = options.input
      this.analyser = options.analyser // || this.actx.createAnalyser()
      this.analyser.smoothingTimeConstant = 0.95
      this.floatTimeData = new Float32Array(128)

      this.clipping = false
      this.lastClip = 0
      this.volume = 0
      this.clipLevel = options.clipLevel || 0.98
      this.averaging = options.averaging || 0.90
      this.clipLag = options.clipLag || 750

      // this.input.connect(this.analyser)
    },

    tagName: 'canvas',

    className: 'peak-meter',

    start: function () {
      this.peakMeterAnimation = this.peakMeterAnimation || {context: this, draw: this.drawMeter}
      this.animationFrameManager.addAnimation(this.peakMeterAnimation)
    },

    drawMeter: function (self) {
      if (self.lastDraw > 0) {
        self.cctx.clearRect(0, 0, self.canvas.width, self.canvas.height)
        // after we clear all the rectangles in the canvas we need to add back the background back
        self.cctx.fillStyle = '#ffffff'
        self.cctx.fillRect(0, 0, self.canvas.width, self.canvas.height)
        // change back to peak meter color
        self.cctx.fillStyle = '#bab0ff'

        if (dbg.enabled) {
          self.clearRectNow = performance.now()
          if (self.clearRectThen) dbg('clearRect', self.clearRectNow - self.clearRectThen)
          self.clearRectThen = self.clearRectNow
        }
      }

      self.analyser.fftSize = self.floatTimeData.length
      self.analyser.getFloatTimeDomainData(self.floatTimeData)
      // console.log(this.context.cid, 'floatTimeDataMax: ', Math.max.apply(Math, self.floatTimeData))

      // self.setVolume(self.getRmsVolume(self.floatTimeData))
      self.setVolume(self.getMaxVolume(self.floatTimeData))

      // round it so we don't trigger sub-pixel rendering
      var meterPixels = Math.round(self.volume * self.canvas.height)

      if (meterPixels > 1 || self.lastDraw > 0) {
        // console.log('peak', self.volume * self.canvas.width, this.context.cid)
        self.cctx.fillRect(0, self.canvas.height - meterPixels, self.canvas.width, meterPixels)
        self.lastDraw = meterPixels
        if (dbg.enabled) {
          self.fillRectNow = performance.now()
          if (self.fillRectThen) dbg('fillRect', self.fillRectNow - self.fillRectThen)
          self.fillRectThen = self.fillRectNow
        }
      }
    },

    stop: function () {
      this.animationFrameManager.removeAnimation(this.peakMeterAnimation)
      this.clear()
    },

    clear: function () {
      this.cctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    },

    checkClipping: function () {
      if (!this.clipping) { return false }
      if ((this.lastClip + this.clipLag) < window.performance.now()) { this.clipping = false }
      return this.clipping
    },

    getMaxVolume: function (f32) {
      return Math.max.apply(Math, f32)
    },

    getRmsVolume: function (buf) {
      var bufLength = buf.length
      var sum = 0
      var x

      // Do a root-mean-square on the samples: sum up the squares...
      for (var i = 0; i < bufLength; i++) {
        x = buf[i]
        if (Math.abs(x) >= this.clipLevel) {
          this.clipping = true
          this.lastClip = window.performance.now()
        }
        sum += x * x
      }

      // ... then take the square root of the sum.
      var rms = Math.sqrt(sum / bufLength)

      return rms
    },

    setVolume: function (vol) {
      // Now smooth this out with the averaging factor applied
      // to the previous sample - take the max here because we
      // want "fast attack, slow release."
      this.volume = Math.max(vol, this.volume * this.averaging)
    },

    render: function () {
      this.canvas = this.$el[0]
      this.canvas.height = 0 // hide it until we can change thr color from black
      this.cctx = this.canvas.getContext('2d', {alpha: false})
      this.canvas.height = this.$el.parent().height()
      this.cctx.fillStyle = '#ffffff'
      this.cctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
      this.cctx.fillStyle = '#bab0ff'
      return this
    }
  })
})()
