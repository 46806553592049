/* globals zc _ utils */

(function () {
  'use strict'

  zc.views.NicknameFormView = zc.views.FormView.extend({
    template: _.template($('.nickname-form-template').html()),

    className: 'nickname-form form panel',

    validateNickname: function (login) {
      if (!utils.validateNickname(login)) {
        return 'Nickname must be less than 30 characters in length and only contain characters a-z, 0-9, and _'
      }
    },

    validateForm: function (attrs) {
      var validNicknameError = this.validateNickname(attrs.nickname)
      if (validNicknameError) {
        return validNicknameError
      }

      return false
    },

    values: function () {
      return {
        nickname: this.$nickname.val()
      }
    },

    submit: function () {
      var values = this.values()

      var validationError = this.validateForm(values)

      if (validationError) {
        return this.showError(validationError)
      }

      this.callback(null, this.values().nickname)
    },

    render: function () {
      var view = this
      var attrs = this.model ? this.model.toJSON() : {}
      this.$el.html(this.template(attrs))
      this.$error = this.$('.error')
      this.$nickname = this.$('.nickname')

      _.defer(function () {
        view.$nickname.focus()
      })

      return this
    }
  })
})()
