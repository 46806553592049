/* globals zc _ */

(function () {
  'use strict'

  zc.views.LoginFormView = zc.views.FormView.extend({
    template: _.template($('.login-form-template').html()),

    className: 'login-form form panel',

    values: function () {
      return {
        login: this.$username.val().toLowerCase(),
        password: this.$password.val()
      }
    },

    url: function () {
      return '/api/login'
    },

    render: function () {
      var view = this
      var attrs = this.model ? this.model.toJSON() : {}
      this.$el.html(this.template(attrs))
      this.$error = this.$('.error')
      this.$username = this.$('.username')
      this.$password = this.$('.password')

      _.defer(function () {
        view.$username.focus()
      })

      return this
    }
  })
})()
