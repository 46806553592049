/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.NotificationView = Backbone.View.extend({
    initialize: function (options) {
      this.$el.addClass(this.model.get('type'))
      this.listenTo(this.model, 'enter', this.enter)
      this.listenTo(this.model, 'exit', this.exit)
    },

    className: 'notification',

    template: _.template($('.notification-template').html()),

    events: {
      'click .close': 'fadeOut'
    },

    enter: function () {
      this.$el.css({transform: 'translateY(0px)', opacity: 1})
    },

    exit: function () {
      this.fadeOut()
    },

    fadeOut: function () {
      var view = this
      setTimeout(function () {
        view.remove()
      }, 500)
      this.$el.css({opacity: 0})
    },

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      return this
    }
  })
})()
