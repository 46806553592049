/* globals zc _ Backbone app utils ua */

(function () {
  'use strict'

  var session = window.session

  zc.models.User = Backbone.Model.extend({
    initialize: function (attrs) {
      this.tracks = new zc.collections.Tracks(attrs.tracks)
      this.tentativeTracks = new zc.collections.Tracks()
      this.settings = new zc.models.Settings(attrs.settings || this.defaults.settings, {user: this})
      this.paymentSource = new zc.models.PaymentSource(attrs.paymentSource || {}, {user: this})
      this.subscription = new zc.models.Subscription(attrs.subscription || {}, {user: this})
      this.stats = new zc.models.Stats(attrs.stats, {user: this})
      this.audioInput = new Backbone.Model(attrs.audioInput)
      this.audioOutput = new Backbone.Model(attrs.audioOutput)

      // health checks
      this.criticalHealthChecks = new Backbone.Collection(attrs.criticalHealthChecks)
      this.warningHealthChecks = new Backbone.Collection(attrs.warningHealthChecks)

      this.on('change:loggedIn', this.loggedInChange)

      this.listenTo(this.settings, 'change:wavRecording', this.wavRecordingSettingChange)
      this.listenTo(this.tracks, 'change:percentUploaded', this.trackPercentUploadedChange)

      // only registered users will have isVerified keys
      this.set({isRegistered: attrs.isRegistered || !_.isUndefined(attrs.isVerified)})

      // set displayName
      var fullName = attrs.firstName ? attrs.firstName + ' ' + attrs.lastName : null
      this.set({_displayName: attrs.displayName, displayName: attrs.displayName || fullName || attrs.username})
    },

    urlRoot: '/api/users',

    defaults: {
      firstName: null,
      lastName: null,
      username: 'Mr. Rogers',
      email: null,
      login: null,
      displayName: null,
      isVerified: false,
      isRegistered: false,
      tos: 0,
      micArmed: false,
      audioInput: null,
      audioOutput: null,
      muted: false,
      readyToRecord: false, // set to true when fully prepared to record
      isRecording: false,
      uploading: false,
      uploadComplete: false,
      profilePic: '/media/images/profilepics/profile' + (Math.floor(Math.random() * 8) + 1) + '.jpeg',
      appVersion: window.servars && window.servars.version,
      userAgent: window.navigator.userAgent,
      platform: {
        sampleRate: 0,
        browser: ua.browser.name + ' ' + ua.browser.version,
        engine: ua.engine.name + ' ' + ua.engine.version,
        os: ua.os.name + ' ' + ua.os.version,
        cpu: ua.cpu.architecture,
        device: ua.device.model + ' ' + ua.device.type + ' ' + ua.device.vendor,
        hardwareConcurrency: navigator.hardwareConcurrency,
        deviceMemory: navigator.deviceMemory,
        connection: {
          downlink: navigator.connection ? navigator.connection.downlink : undefined,
          effectiveType: navigator.connection ? navigator.connection.effectiveType : undefined,
          rtt: navigator.connection ? navigator.connection.rtt : undefined
        },
        audioWorkletSupport: null // whether the recording was initialized with audioWorklet support
      },
      cloudToken: null,
      cloudQuota: {
        total: 0,
        used: 0,
        free: 0
      },
      localStorageQuota: {
        total: 0,
        used: 0,
        free: 0
      },
      status: null,
      settings: {
        wavRecording: false,
        hostVoip: true,
        soundboard: false,
        echoCancellation: true
      },
      notificationPermission: window.Notification && Notification.permission,
      storagePersistence: null,
      onetimePostproductionCredits: 0,
      fps: 60 // frames per second that the animations will adhere to.  Can be lowered for legacy hardwar to improve performance
    },

    attrs: function () {
      var attrs = this.toJSON()
      attrs.model = this
      attrs.cloudToken = this.getCloudToken()
      attrs.micLabel = this.formatMicLabel(this.audioInput.get('label'))
      attrs.subscription = this.subscription
      attrs.settings = this.settings
      attrs.healthCheckStatus = this.getHealthCheckStatus()
      return attrs
    },

    toExtendedJSON: function () {
      var json = this.toJSON()
      json.audioInput = this.audioInput.toJSON()
      json.audioOutput = this.audioOutput.toJSON()
      json.tracks = this.tracks.toJSON() // so any already created tracks get shared on room join
      json.warningHealthChecks = this.warningHealthChecks.toJSON()
      json.criticalHealthChecks = this.criticalHealthChecks.toJSON()
      json.settings = this.settings.toJSON()
      json.isHost = this.isHost()
      json.pipelineVersion = window.servars.pipelineVersion || ''

      return json
    },

    getHealthCheckStatus: function () {
      if (!this.criticalHealthChecks || !this.criticalHealthChecks.length) return 'pending'
      if (this.criticalHealthChecks.pluck('passed').indexOf(false) > -1) return 'failed'
      if (!this.warningHealthChecks || !this.warningHealthChecks.length) return 'pending'
      if (this.warningHealthChecks.pluck('passed').indexOf(false) > -1) return 'warning'
      return 'passed'
    },

    didPassHealthCheck: function () {
      var passed = false
      var status = this.getHealthCheckStatus()

      var nonPassStates = ['pending', 'failed']

      if (nonPassStates.indexOf(status) < 0) {
        passed = true
      }

      return passed
    },

    clearHealthChecks: function () {
      this.warningHealthChecks.reset()
      this.criticalHealthChecks.reset()
    },

    wavRecordingSettingChange: function (settings, wavRecording) {
      // if we have already created tentative tracks for the recording,
      // check that the track formats match the settings properly
      var isProjectPage = !!app.project && app.location === app.project
      var isLocal = this.isLocal()

      if (isProjectPage && isLocal && app.user.tracks.length) {
        var wavTrack = this.getTrack('wav') // check user.tracks first
        if (wavRecording) { // if wav recording is enabled find wav in (1. user.tracks 2. user.tentativeTracks 3. user.createTrack)
          var wavTrackPromise // then find any tentative wav track and add it to user.tracks if it exists
          wavTrack = wavTrack || this.tracks.add(this.tentativeTracks.findWhere({format: 'wav'}))
          if (wavTrack) {
            wavTrackPromise = Promise.resolve(wavTrack) // if we found the wavTrack, resolve with it
          } else {
            wavTrackPromise = this.createTrack({format: 'wav'}) // create wav track if it doesn't exist
          }

          wavTrackPromise.then(function (wavTrack) {
            // once we have a wavTrack we need to re-start recording preparations
            // with it in place
          })
        } else {
          wavTrack && this.tracks.remove(wavTrack)// remove wav track if it does exist
        }
      }
    },

    trackPercentUploadedChange: function (track, percentUploaded) {
      var isMp3 = track.get('format') === 'mp3'
      // var recordingInProgress = this.get('isRecording')
      if (isMp3) {
        this.set({cloudBackupPercent: percentUploaded})
      }
    },

    incPostproductionCredits: function (num) {
      var credits = this.get('onetimePostproductionCredits')
      this.set({onetimePostproductionCredits: credits + num})
    },

    formatMicLabel: function (label) {
      if (label === 'Default') label = 'System Default Microphone'
      if (!label) label = 'Unknown'
      return label
    },

    getFeature: function (featureKey) {
      var plan = this.subscription.plan
      return plan.get('features')[featureKey]
    },

    getCloudToken: function () {
      return this.get('cloudToken')
    },

    // set the user as logged in on the session
    setLoggedIn: function () {
      session.loggedIn = true
      session.userId = this.id
      // session.user = this.toJSON()
    },

    // set the user as logged out on the session
    setLoggedOut: function () {
      session.loggedIn = false
      session.userId = null
      // session.user = null
    },

    loggedIn: function () {
      if ((app.user === this) && session.loggedIn) {
        return true
      } else {
        return false
      }
    },

    getUsedPostproductionCredits: function () {
      return this.stats.get('usedCredits')
    },

    getAvailablePostproductionCredits: function () {
      var user = this

      // calc what is left of monthly recurring credits
      var usedCredits = user.getUsedPostproductionCredits()
      var availableCredits = user.getFeature('recurringPostproductionCredits') - usedCredits

      // minimum of zero available.  You can't go into debt on credits.
      availableCredits = Math.max(availableCredits, 0)

      // add one-time credits.  One credit is worth one hour
      availableCredits += user.get('onetimePostproductionCredits')

      return availableCredits
    },

    getTotalPostproductionCredits: function () {
      var recurringCredits = this.subscription.plan.get('features').recurringPostproductionCredits
      var onetimeCredits = this.get('onetimePostproductionCredits')
      var totalCredits = recurringCredits + onetimeCredits
      return totalCredits
    },

    getTrack: function (format, type) {
      type = type || 'microphone'
      return this.tracks.filter({format: format, type: type})[0]
    },

    // Creates a new track owned by this user both on the frontend and server side
    // then adds the user.tracks collection which will cause it to render in the UI
    createTrack: function (attrs) {
      var self = this
      return new Promise(function (resolve, reject) {
        var project = app.location
        var recording = project.recorder.recording

        var host = app.location.lobby.getHost()
        var cloudToken = host ? host.get('cloudToken') : null

        if (cloudToken) {
          attrs.token = cloudToken.token
          attrs.cloudDrive = cloudToken.provider
        }

        attrs.hostId = project.get('ownerId')
        attrs.hostUsername = project.get('owner')
        attrs.projectId = project.id
        attrs.projectSlug = project.get('slug')
        attrs.recordingId = recording.id
        attrs.recordingSlug = 'recording-' + (project.recordings.indexOf(recording) + 1)
        attrs.userId = self.id
        attrs.username = self.get('username')
        attrs.tentative = true

        app.socket.emit('upload:create', attrs, function (err, uploadAttrs) {
          if (err) { utils.notify('error', err); return reject(err) }

          var track = new zc.models.Track(uploadAttrs)
          self.tracks.add(track)
          resolve(track)
        })
      }).catch(utils.logRethrow)
    },

    isHost: function () {
      // the host is defined by who is the owner of the project
      return this.id === app.location.get('ownerId')
    },

    isLocal: function () {
      return this.id === app.user.id
    },

    ownsTrack: function (track) {
      return this.id === track.get('userId')
    },

    // canControlRecording: function () {
    //   // only hosts can control recording unless the
    //   // guest has become disconnected from the host
    //   // while recording is taking place
    //   return this.isHost() || (this.get('recording') && !app.socket.connected)
    // },

    isAllAudioUploaded: function () {
      var project = app.location

      if (!this.tracks.length) return false

      var trackFinalizedStatuses = this.tracks.map(function (track) {
        return track.get('finalized')
      })

      // get all the tracks that are not finalized yet
      // they might appear here if the user refresh the page
      var userUnfinalizedTracks = project.recorder.recording.tracks.filter(function (track) {
        return track.get('userId') === app.user.get('_id') && !track.get('finalized') && !track.get('uploading')
      })

      var allUploaded = trackFinalizedStatuses.indexOf(false) < 0 && userUnfinalizedTracks.length === 0

      // console.log('Username: ', this.get('username'), 'trackFinalizedStatuses: ', trackFinalizedStatuses, 'allUploaded: ', allUploaded)
      // console.log('Tracks: ', this.tracks)
      // console.log('Old tracks: ', userUnfinalizedTracks.length)

      return allUploaded
    },

    updateTos: function (newTosVersion) {
      var self = this
      this.trigger('loading')
      return $.ajax({
        type: 'PUT',
        url: '/api/users/' + this.id,
        data: {
          tos: newTosVersion
        }
      }).success(function (res) {

      }).error(function (err) {
        utils.notify('error', err)
      }).always(function () {
        self.trigger('doneLoading')
      })
    },

    getStatus: function () {
      var status = null

      var uploadingTrack = this.tracks.findWhere({uploading: true})
      var recorder = app.project.recorder
      // var localStorageManager = app.localStorageManager
      // var localStorageAlertLevel = localStorageManager.get('alertLevel')
      var attrs = this.attrs()
      if (uploadingTrack) {
        status = 'Uploading ' + uploadingTrack.get('format').toUpperCase() + '...'
      } else if (this.isAllAudioUploaded()) {
        status = 'All audio uploaded'
      } else if (attrs.micArmed) {
        if (this.get('paused')) {
          status = 'Paused'
        } else if (attrs.isRecording) {
          status = 'Recording in progress'
        // } else if (this.isLocal() && attrs.alertLevel) {
        //   status = app.localStorageManager.alertLevelMessages[localStorageAlertLevel]
        } else if (recorder.hasFinishedRecording()) {
          status = 'This recording has finished'
        } else if (attrs.healthCheckStatus === 'pending') {
          // if this is a guest
          var userIsHost = this.isHost()
          var isHostPresent = !!app.location.lobby.getHost()
          var waitingForHost = (!userIsHost && !isHostPresent)
          status = waitingForHost ? 'Waiting for host to join' : 'Running health checks'
        } else if (attrs.healthCheckStatus === 'failed') {
          status = 'Health check failed - Unable to record'
        } else if (attrs.healthCheckStatus === 'warning') {
          status = 'Health check passed but revealed warnings - Proceed with caution'
        } else if (attrs.healthCheckStatus === 'passed') {
          status = 'Health check passed - Ready to record'
        }
      } else {
        status = 'Waiting for microphone access'
      }

      return status
    }
  })
})()
