/* globals zc app debug _ Backbone utils Player localStorage AudioContext */

(function () {
  'use strict'

  var dbg = debug('zc:project')

  zc.models.Project = Backbone.Model.extend({
    initialize: function (attrs, options) {
      // could this line be why we have a bunch of empty recordings?
      attrs.recordings = (attrs.recordings && attrs.recordings.length) ? attrs.recordings : [new zc.models.Recording({projectId: this.id})]

      this.socket = app.socket

      dbg('Initializing project')

      // top level audio nodes
      this.actx = app.actx = attrs.actx || new AudioContext() // {sampleRate: 44100}) // setting sample rate causes pitch shift and static when it differs from hardware on chrome 74
      this.outgoingVoipStreamDestination = app.outgoingVoipStreamDestination = this.actx.createMediaStreamDestination()
      this.outgoingVoipStreamDestination.channelCount = 1

      this.outgoingVoipGain = app.outgoingVoipGain = app.actx.createGain()
      this.localAudioOut = app.localAudioOut = app.actx.createGain()
      this.recorderIn = app.recorderIn = app.actx.createGain()
      this.soundboardOut = app.soundboardOut = app.actx.createGain()

      this.soundboardOut.channelCountMode = 'explicit'
      this.soundboardOut.channelInterpretation = 'speakers'
      this.soundboardOut.channelCount = 1

      this.localAudioOutEl = app.localAudioOutEl = document.createElement('audio') // TODO: Use video el instead?? https://github.com/WebAudio/web-audio-api/issues/445
      this.localMicStreamIn = null // should be a MediaStreamSource node set by the recorder.js or call.js

      // wire up the local out to a audio element so we can change output devices
      // using the setSinkId workaround
      this.outputDestination = this.actx.createMediaStreamDestination()
      this.outputDestination.channelCount = 1

      // hack to prevent this playback frequeny/latency issue: https://bugs.chromium.org/p/chromium/issues/detail?id=638823
      var empty = this.actx.createBufferSource()
      empty.connect(this.outputDestination)

      // disable configurable local audio output for host.  Too many latency issues with sample playback and monitoring.
      if (app.user.id === attrs.ownerId) {
        dbg('Disable configurable local audio output for host')
        this.localAudioOut.connect(this.actx.destination)
      } else {
        this.localAudioOut.connect(this.outputDestination)
        this.localAudioOutEl.srcObject = this.outputDestination.stream
      }

      app.player = new Player(this.actx)
      app.player.loadSamples([
        {name: 'error', url: utils.cdnUrl('/media/sounds/classic-bloop.wav')},
        {name: 'userRaisedHand', url: utils.cdnUrl('/media/sounds/think-ping.wav')},
        {name: 'userLoweredHand', url: utils.cdnUrl('/media/sounds/thats-it.wav')},
        {name: 'userJoined', url: utils.cdnUrl('/media/sounds/think-ping.wav')}
      ])

      if (app.user.getFeature('soundboard')) {
        // soundboard sample playback
        this.soundboardOut.connect(this.localAudioOut)
        // this.soundboardOut.connect(this.recorderIn)
        this.soundboardOut.connect(this.outgoingVoipStreamDestination)

        this.soundboardSamples = new zc.collections.Samples(JSON.parse(localStorage.getItem('soundboardSamples')) || [
          { _id: '0123', name: 'Intro / Outro', url: utils.cdnUrl('/media/sounds/Upbeat Intro.wav'), loop: true, gain: 0.5 },
          { _id: '1243', name: 'Dramatic Piano', url: utils.cdnUrl('/media/sounds/piano.mp3'), loop: true },
          { _id: '2345', name: 'Drums', url: utils.cdnUrl('/media/sounds/drums.wav') },
          { _id: '3456', name: 'Ballpark', url: utils.cdnUrl('/media/sounds/ballpark.wav') }
        ])
      }

      this.lobby = new zc.models.Lobby()
      this.recordings = new zc.collections.Recordings(attrs.recordings, {parse: true, project: this})
      var activeRecordingId = options && options.activeRecordingId
      var recording = this.recordings.get(activeRecordingId) || this.recordings.at(this.recordings.length - 1)

      activeRecordingId && dbg('Current recording id: ' + activeRecordingId)

      recording.set({active: true}) // persist the host voip setting

      this.call = new zc.models.Call({roomId: this.id}, {actx: this.actx, socket: this.socket})
      this.recorder = new zc.models.Recorder({recording: recording}, {actx: this.actx, project: this, socket: this.socket})
      this.recorderIn.connect(this.recorder.input)
      this.chat = new zc.models.Chat()
      this.localStorageManager = app.localStorageManager

      this.buffers = new zc.collections.Buffers([this.recorder.buffer])

      console.log('Audio context samplerate: ', this.actx.sampleRate)

      this.audioSize = 0

      this.listenTo(app.user, 'change:localStorageQuota', this.localStorageQuotaChange)
      this.listenTo(app.user, 'initializationFailure', this.initializationFailure)
      this.listenTo(app.user.settings, 'change', this.userSettingsChange)
      this.listenTo(app.user.audioInput, 'change', this.userAudioInputChange)
      this.listenTo(app.user.criticalHealthChecks, 'reset', this.healthChecksChange)
      this.listenTo(app.user.warningHealthChecks, 'reset', this.healthChecksChange)
      this.listenTo(app.user.tracks, 'add', this.shareTrackWithRoom)

      this.listenTo(this.recorder, 'change:micArmed', this.micArmedChange)
      this.listenTo(this.recorder.buffer, 'change:wav', this.sendWavAudioStream)
      // this.listenTo(this.recorder.buffer, 'newDrawChunk', this.handleDrawChunk)
      this.listenTo(this.recorder, 'change:playing', this.playingChange)
      this.listenTo(this.recorder, 'change:paused', this.pausedChange)
      // this.listenTo(this.lobby.users, 'change:muted', this.mutedChange);
      this.listenTo(this.lobby.users, 'add', this.createUserBuffer)
      this.listenTo(this.lobby.users, 'add remove', this.checkAllArmed)
      // this.listenTo(this.lobby.users, 'add remove', this.checkAllHealthChecksPassed)
      // this.listenTo(this.lobby.users, 'resetHealthChecks', this.checkAllHealthChecksPassed)
      this.listenTo(this.lobby.users, 'remove', this.memberLeft)
      this.listenTo(this.lobby.users, 'change:micArmed', this.checkAllArmed)
      // this.listenTo(this.lobby.users, 'change:path', this.checkAllUploaded);
      this.listenTo(this.lobby.users, 'change:handRaised', this.handRaisedChange)
      this.listenTo(this.lobby.users, 'remoteUserMutedChangedLocally', this.remoteUserMutedChangedLocally)
      this.listenTo(this.lobby.users, 'localUserMutedChange', this.localUserMutedChange)
      this.listenTo(this.recorder, 'startOver', this.startOver)
      this.listenTo(this.chat, 'localTypingStarted', this.localChatTypingStarted)
      this.listenTo(this.chat, 'localTypingStopped', this.localChatTypingStopped)

      this.storageQuotaPromise = this.localStorageManager.checkStorageQuota()
    },

    localBackupThresholdDuration: 1000 * 60 * 1, // 1 minute

    urlRoot: '/projects',

    defaults: {
      name: 'Default Project',
      duration: 0,
      cloudDrive: null, // dropbox or google drive
      recording: null, // recording object
      isRecording: false,
      closeTabProtection: false, // if the beforeunload event listener is attached to the window
      allArmed: false,
      actxIsActive: false
    },

    attrs: function () {
      var attrs = this.toJSON()
      attrs.project = this
      return attrs
    },

    startAudioPlayback: function () {
      var self = this
      return this.localAudioOutEl.play().then(function () {
        console.log('Local Audio Playback Started Successfully')
        dbg('Local Audio Playback Started Successfully')

        if (app.actx.state === 'suspended') {
          self.trigger('requestAudioPlayback')
        }
      }).catch(function (err) {
        console.error('Error Starting Local Audio Playback', err)
        self.trigger('requestAudioPlayback')
      })
    },

    audioPlaybackAllowed: function () {
      if (app.actx.state === 'suspended') {
        app.actx.resume()
      }

      // start sound for local audio out
      app.localAudioOutEl && app.localAudioOutEl.play()

      this.recorder.trigger('audioContextResumed')
    },

    localChatTypingStarted: function () {
      this.socket.emit('chat:typingStarted')
    },

    localChatTypingStopped: function () {
      this.socket.emit('chat:typingStopped')
    },

    calcLocalStorageUsed: function () {
      var self = this
      return new Promise(function (resolve, reject) {
        var promises = self.recordings.map(function (recording) {
          return recording.calcLocalStorageUsed()
        })

        Promise.all(promises).then(function (sizes) {
          resolve(_.reduce(sizes, function (a, b) { return a + b }, 0))
        }, function (err) {
          reject(err)
        })
      })
    },

    recoverTracks: function () {
      // if the guest wants to recover tracks for the project

      // two actions will be taken
      // 1. force finalize any track associated with the user
      this.recorder.finishOldTracks()

      // 2. if we find other tracks for this project that are present/saved locally
      //  try and finalize them (upload or force download)
      this.lookForOldTracks()
    },

    /**
     * Used when a user (guest most likely) goes to a recover link for a project
     * Looks for tracks that are saved locally in the browser but don't belong to the current user
     */
    lookForOldTracks: function () {
      var self = this

      // queue of tracks to upload
      var tracksToFinalize = []
      // keep track if there is an upload in progress
      var prom = null
      /**
       * upload a track and not matter the result, got to the next one
       * @param  {Object} track The track model
       */
      var uploadNextTrack = function (track) {
        console.log('started track', track.id)
        prom = track.uploadFull().finally(function () {
          var track = tracksToFinalize.shift()
          // if there is a track in the queue and it's not finalized
          if (track) {
            console.log('finished track', track.id)
            uploadNextTrack(track)
          } else {
            prom = null
          }
        })
      }

      // go through each track for each recording to see if we have something saved locally
      this.recordings.each(function (rec) {
        rec.tracks.each(function (track) {
          track.persistentStore.getDbMeta().then(function (dbMeta) {
            // if there is something saved in the DB
            if (dbMeta && dbMeta.totalBytes > 0) {
              // set the userId so the track can be displayed and uploaded without problems
              // in theory the track belongs to this user but he/she had a different id when it was created
              track.set('userId', app.user.id)

              // self.recorder.recording.tracks.add(track)
              self.recorder.recording.tracks.trigger('add', track)

              // skip finalized tracks
              if (track.get('finalized')) return

              // if there is an upload in progress just queue this track
              if (prom) {
                console.log('adding track', track.id)
                tracksToFinalize.push(track)
              } else {
                // else start uploading
                uploadNextTrack(track)
              }
            }
          })
        })
      })
    },

    initializationFailure: function (user, err) {
      this.recorder.set({isRecording: false})
      this.trigger('stopCloseTabProtection', this)
      this.socket.emit('user:initialization:failure', {user: user, error: err})
    },

    /**
     * Called when the recorder fires the startOver event
     */
    startOver: function () {
      app.router.navigate([this.get('owner'), this.get('slug')].join('/'))
      this.reloadProjectPage()
    },

    healthChecksChange: function () {
      var data = {
        userId: app.user.id,
        criticalHealthChecks: app.user.criticalHealthChecks.toJSON(),
        warningHealthChecks: app.user.warningHealthChecks.toJSON()
      }

      this.socket.emit('change:healthChecks', data)
    },

    localStorageQuotaChange: function (user, localStorageQuota) {
      // only the guests send this events, and only the host should receive them
      if (!app.user.isHost()) {
        var data = {
          userId: user.id,
          localStorageQuota: localStorageQuota,
          hostId: this.get('ownerId')
        }

        this.socket.emit('change:localStorageQuota', data)
      }
    },

    handRaisedChange: function (user, handRaised) {
      if (user.isLocal() || (app.user.isHost() && !handRaised)) {
        var data = {userId: user.id, handRaised: handRaised}
        this.socket.emit('user:change:handRaised', data)
      }
    },

    /**
     * If the user has opened a link multiple times, this method can be used to other other sessions
     */
    endOtherSessions: function () {
      var self = this
      return new Promise(function (resolve, reject) {
        self.socket.emit('endOtherSession', {roomId: self.id}, function (err) {
          if (err) {
            console.error(err)
            reject(err)
            return
          }
          resolve()
          self.joinRoom()
        })
      })
    },

    reloadProjectPage: function () {
      console.time('alertReloading')
      utils.notify('alert', 'Reloading project...')
      console.timeEnd('alertReloading')
      var href = window.location.pathname
      if (!app.user.loggedIn()) {
        href += utils.setQueryStringParam(['username', app.user.get('username')])
      }
      window.location.href = href
    },

    localUserMutedChange: function (user, muted) {
      this.socket.emit('user:change:muted', {userId: user.id, muted: muted})
    },

    remoteUserMutedChangedLocally: function (user, muted) {
      this.socket.emit('user:change:muted', {userId: user.id, muted: muted})
    },

    createUserBuffer: function (user) {
      var buffer = new zc.models.Buffer({_id: user.id}, {actx: this.actx})
      this.buffers.add(buffer)
    },

    userSettingsChange: function (settings) {
      this.socket.emit('change:settings', {
        settings: settings.toJSON()
      })
    },

    userAudioInputChange: function (audioInput) {
      this.socket.emit('change:audioInput', {
        audioInput: audioInput.toJSON()
      })
    },

    micArmedChange: function (recorder, micArmed) {
      var self = this

      app.user.set('micArmed', micArmed)

      var promise = this.recorder.mediaDevices.fetch()
      promise.then(function (mediaDevices) {
        var attrs = {
          micArmed: micArmed,
          audioInput: self.call.audioInput.toJSON()
        }
        self.socket.emit('change:micArmed', attrs)
      })
      .catch(function (err) {
        console.error('Could not fetch list of media devices', err)

        var attrs = {
          micArmed: micArmed
        }

        self.socket.emit('change:micArmed', attrs)
      })
    },

    checkAllArmed: function () {
      // check to see if all users are armed and ready to record
      var allArmed = false

      var statuses = this.lobby.users.map(function (user) {
        return user.get('micArmed')
      })

      if (statuses.indexOf(false) < 0) {
        allArmed = true
      }

      this.recorder.set({allArmed: allArmed})
    },

    playingChange: function (model, playing) {
      this.buffers.each(function (buffer) {
        buffer.set({playing: playing})
      })
    },

    pausedChange: function (model, paused) {
      app.user.set({paused: paused})
      this.socket.emit('change:paused', paused)
    },

    joinRoom: function (cb) {
      cb = cb || function () {}
      var project = this
      var socket = this.socket
      if (!project.id) {
        console.error('Project id missing when trying to joinRoom', project.id, project.attributes)
      }
      console.log('Joining room')
      socket.emit('joinRoom', {id: project.id, user: app.user.toExtendedJSON()}, cb)
    },

    rejoinRoom: function (cb) {
      cb = cb || function () {}
      var project = this
      var socket = this.socket
      if (!project.id) {
        console.error('Project id missing when trying to joinRoom', project.id, project.attributes)
      }
      console.log('Rejoining room')
      socket.emit('rejoinRoom', {id: project.id, user: app.user.toExtendedJSON()}, cb)
    },

    shareTrackWithRoom: function (track) {
      app.socket.emit('track:created', {
        track: track.toJSON()
      })
    },

    addRoomMembers: function (members) {
      members.forEach(this.addRoomMember.bind(this))
    },

    addRoomMember: function (member) {
      console.log('New user joined the room', member.username)
      var user = this.lobby.users.get(member._id)

      if (!user) {
        user = new zc.models.User(member)
        this.lobby.users.add(user)
        // this.disableControlsIfNeeded(user)
      }

      if (user.isHost() && !app.user.isHost()) {
        var host = user
        // sync settings with host
        app.user.settings.set(host.settings.toJSON())

        // The host has joined the room. If a recording hasn't
        // already taken place, then get prepared to record
        // make sure we have access to the audio stream first
        if (!this.recorder.hasStartedRecording() && this.call.localAudio.mediaStream) {
          this.recorder.prepareToRecord()
        }
      }
    },

    startDevices: function () {
      var recorder = this.recorder
      var call = recorder.call

      return call.setPreferredDevicesPromise.then(function () {
        return call.getLocalAudio().then(function (localAudio) {
          if (app.user.settings.get('hostVoip')) {
            return call.start(localAudio)
          }

          return Promise.resolve(localAudio)
        })
      })
    },

    // ----------------------
    // Testing
    // ----------------------

    testTrack: function (format, seconds) {
      var self = this
      seconds = seconds || 60 * 60 * 1.7 // 1.5 hours
      var byteLength = seconds * 44100 * 2 // byteLength for 16bit audio at 44100 hz
      console.log('Testing Track: 16bit 44100hz')
      console.log('Seconds: ', seconds, 'ByteLength: ', byteLength)
      this.testCreateExistingTrack(format)
        .then(function (track) {
          return self.testTrackSaveAudioToStore(track, track.testGenerateAudio(byteLength))
        })
        .then(function (track) {
          self.testTrackDownloadFromLocal(track)
        })
    },

    testCreateTrack: function (format) {
      return new zc.models.Track({
        format: format,
        recordingId: self.recorder.recording.id
      })
    },

    testTrackSaveAudioToStore: function (track, audio) {
      return new Promise(function (resolve, reject) {
        track.testSaveAudio(audio).then(function (totalSavedBytes) {
          resolve(track)
        }, reject)
      })
    },

    testTrackDownloadFromLocal: function (track) {
      return new Promise(function (resolve, reject) {
        console.log('Downloading From Local')
        track.downloadFromLocal()
      })
    },

    testTrackUpload: function () {
      return new Promise(function (resolve, reject) {

      })
    }
  })
})()
