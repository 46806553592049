/* globals zc Backbone uploader streamify debug utils */

(function () {
  'use strict'

  var dbg = debug('zc:cloudStorage')

  zc.models.CloudStorage = Backbone.Model.extend({
    initialize: function (attrs, options) {
      attrs = attrs || {}
      this.socket = options.socket
    },

    name: 'cloudStorage',

    // indexes of chunks, not the chunks themselves
    chunksReceived: [],
    chunksUploaded: [],

    defaults: {
      bytesReceived: 0,
      bytesUploaded: 0,
      uploadUrl: null,
      downloadUrl: null,
      chunkSize: 1024 * 256, // 256kb
      minChunkSize: 1024 * 256, // 256kb min size for cloud uploads
      maxChunkSize: 1024 * 1024 * 100 // 100mb
    },

    attrs: function () {
      var attrs = this.toJSON()
      return attrs
    },

    // to support streaming
    push: function (chunk, isLast) {
      var chunkIndex = this.chunksReceived.length

      this.uploadChunk(chunkIndex, chunk, isLast)
      this.emit(chunk, isLast)
    },
    emit: function () {},

    getContentType: function () {
      var format = this.get('format')
      if (format === 'wav') {
        return 'audio/wav'
      } else if (format === 'mp3') {
        return 'audio/mpeg'
      } else {
        throw new Error('Unable to retrieve content type.  Missing format information.')
      }
    },

    createUploadUrl: function (projectId, path) {
      var self = this
      var contentType = this.getContentType()
      var action = 'write'
      return new Promise(function (resolve, reject) {
        self.socket.emit('uploadUrl:create', {
          projectId: projectId,
          path: path,
          contentType: contentType,
          action: action,
          origin: utils.getWindowOrigin()
        }, function (err, uploadUrl) {
          if (err) return reject(new Error(err))
          self.set({'uploadUrl': uploadUrl})
          resolve(uploadUrl)
        })
      }).catch(utils.logRethrow)
    },

    createDownloadUrl: function (projectId, path) {
      var self = this
      return new Promise(function (resolve, reject) {
        $.post('/api/cloud-storage/download-url', {
          projectId: projectId,
          path: path
        }).then(function (url) {
          self.set({'downloadUrl': url})
          resolve(url)
        }, reject)
      })
    },

    getChunkSizeFromBlob: function (blob) {
      var minChunkSize = this.get('minChunkSize')
      var maxChunkSize = this.get('maxChunkeSize')

      if (blob.size < minChunkSize) return minChunkSize

      var largestPossibleChunk = Math.round(blob.size / minChunkSize) * minChunkSize

      if (largestPossibleChunk > maxChunkSize) return maxChunkSize

      return largestPossibleChunk
    },

    getResumableUploadProgress: function () {
      var uploadUrl = this.get('uploadUrl')

      return new Promise(function (resolve, reject) {
        var metadata = null
        var byteOffset = 0
        var requestHeaders = {
          'Content-Range': 'bytes */*'
        }

        if (!uploadUrl) return reject(new Error('Cannot get resumable upload progress without a resumable upload url'))

        $.ajax({
          type: 'PUT',
          url: uploadUrl,
          headers: requestHeaders,
          global: false // to supress 308 error from bubbling up to error trackers
        }).then(function (res) {
          // this means the file is already uploaded and the response will contain the file metadata
          resolve({byteOffset: parseInt(res.size) - 1, metadata: res})
        }, function (res) {
          if (res.status === 308) {
            // this means the file is in progress or unfinalized and the response will contain the range header
            var range = res.getResponseHeader('range')
            if (range) {
              byteOffset = parseInt(range.split('-')[1])
            }
            resolve({byteOffset: byteOffset, metadata: metadata})
          } else {
            // this is a real error
            reject(new Error(res.statusCode + ' ' + res.statusMessage))
          }
        })
      })
    },

    upload: function (url, blob) {
      var self = this
      this.uploadInProgress = true

      var chunkSize = this.getChunkSizeFromBlob(blob)

      var upload = new uploader.Upload({
        id: this.id,
        contentType: this.getContentType(),
        url: url,
        file: blob,
        chunkSize: chunkSize,
        onProgress: function (progress) {
          dbg('onProgress: ', progress)
          self.set({bytesUploaded: progress.uploadedBytes})
        },
        onChunkUpload: function (data) {
          dbg('onChunkUpload: ', data)
          self.trigger('chunkUploaded', data)
        }
      })

      return new Promise(function (resolve, reject) {
        upload.start().then(function (arg) {
          dbg('Upload Successful: ', arg)
          resolve()
        }).catch(function (err) {
          console.error('Upload Failed - ', err)
          reject(err)
        }).then(function () {
          dbg('Upload no longer in progress')
          self.uploadInProgress = false
        })
      })
    },

    getNextChunkFromRecorder: function (index, isLast) {
      if (isLast) return this.memoryStore.mergedSlice(index)
      else return this.memoryStore.getChunk(index)
    },

    isBehind: function (isLast) {
      return isLast ? this.chunksUploaded.length < this.chunksReceived.length - 1 : this.chunksUploaded.length !== this.chunksReceived.length
    },

    uploadChunk: function (chunkIndex, chunk, isLast) {
      var self = this
      var behind = self.isBehind(isLast)
      dbg('Uploader Behind: %s, chunksUploaded: %s, chunksReceived: %s', behind, self.chunksUploaded.length, self.chunksReceived.toString())
      this.chunksReceived.push(chunkIndex)
      return new Promise(function (resolve, reject) {
        if (!self.get('uploadUrl')) {
          console.warn('Haven\'t received uploadUrl response yet.  Postponing upload.')
          return resolve()
        }

        if (self.uploadInProgress) {
          console.warn('Upload still in progress.  Waiting to upload next chunk.')
          if (isLast) {
            console.log('LAST CHUNK WAITING - index: ', chunkIndex, ' chunk.byteLength: ', chunk.byteLength, ' isLast: ', isLast)
            console.log('UPLOAD IN PROGRESS: ', self.uploadInProgress)
            self.once('chunkUploaded chunkUploadFailed', function () {
              // trigger last chunk to upload once the current upload completes
              console.log('Uploading final chunk - index: ', chunkIndex, ' chunk.byteLength: ', chunk.byteLength, ' isLast: ', isLast)
              console.log('UPLOAD IN PROGRESS: ', self.uploadInProgress)
              self.uploadChunk(chunkIndex, chunk, isLast)
            })
            return resolve()
          } else {
            // do nothing, we'll be behind but catch up at the end
            return resolve()
          }
        } else if (behind) {
          // we are behind and need to retry chunks
          chunkIndex = self.chunksUploaded.length
          console.warn('Uploads are behind.  Grabbing next chunk from memoryStore: ', chunkIndex)
          chunk = self.getNextChunkFromRecorder(chunkIndex, isLast)
        }

        self.uploadInProgress = true
        self.uploadChunkStartTime = performance.now()

        self.uploader.uploadChunk(chunkIndex, chunk, isLast).then(resolve, reject).then(function () {
          resolve()
          self.uploadChunkEndTime = performance.now()
          dbg(uploadTimeLogId, self.uploadChunkEndTime - self.uploadChunkStartTime)
        }).catch(reject)

        if (dbg.enabled) {
          var format = self.get('format')
          var uploadTimeLogId = format + ':uploadChunk:' + chunk.byteLength
          var sinceLastLogId = format + ':sinceLastUpload'
          if (self.uploadChunkEndTime) dbg(sinceLastLogId, self.uploadChunkStartTime - self.uploadChunkEndTime)
        }
      })
    },

    initStreamingUpload: function () {
      var self = this
      var chunkSize = self.get('chunkSize') || self.get('minChunkSize')

      self.uploader = new uploader.UploadStream({
        id: self.id,
        contentType: self.getContentType(),
        url: self.get('uploadUrl'),
        chunkSize: chunkSize,
        backoffRetryMillis: 1000,
        backoffRetryAttempts: 5, // exponential backoff totalling 5 retries over 31 seconds before giving up
        onProgress: function (progress) {
          dbg('CLOUD STORAGE PROGRESS: ', progress)
          // self.trigger('progress', progress)
          self.set({bytesUploaded: progress.uploadedBytes})
        },
        onChunkUpload: function (data) {
          dbg('CLOUD STORAGE CHUNK UPLOADED: ', data)
          self.chunksUploaded.push(data.chunkIndex)
          self.uploadInProgress = false
          self.trigger('chunkUploaded', data)
        },
        onChunkUploadFail: function (data) {
          self.uploadInProgress = false
          self.trigger('chunkUploadFailed', data)
        }
      })

      return self.uploader
    }
  })

  streamify.mixin(zc.models.CloudStorage.prototype)
})()
