/* globals zc _ Backbone utils */

(function () {
  // 'use strict';

  zc.views.RecordAnalyserView = Backbone.View.extend({
    initialize: function () {
      this.analyserNode = this.model.analyserNode
      this.timeData = new Uint8Array(this.analyserNode.frequencyBinCount)
      this.firstDraw = true
      this.readyToDraw = false
      this.pcm = []

      // this.listenTo(this.model, 'newChunk', this.drawWaveformTail);
      // this.listenTo(this.model, 'audioReady', this.drawWaveform);
      this.listenTo(this.model, 'change:micArmed', this.micArmedChange)
      $(window).resize(this.resizeCanvas.bind(this))

      _.bindAll(this, 'draw')
    },

    className: 'buffer',

    template: _.template($('.buffer-template').html()),

    micArmedChange: function (model, micArmed) {
      if (micArmed) {
        this.draw()
      } else {
        window.cancelAnimationFrame(this.analyserRAFID)
      }
    },

    drawWaveformTail: function (model, f32) {
      if (!f32) return

      var pcm = this.pcm
      pcm.push(f32)
      if (pcm.length > 5) {
        pcm.shift()
      }
      var cctx = this.cctx
      var width = this.canvas.width
      var height = this.canvas.height
      var yCenter = (height / 2) + 0.5
      var inc = 16

      // sample pcm and draw
      cctx.strokeStyle = '#e67e22'
      cctx.lineWidth = '1'
      cctx.fillStyle = '#e67e22'
      cctx.clearRect(0, 0, width, height)
      cctx.beginPath()
      var x = width
      var y = yCenter
      cctx.moveTo(x, y)

      for (var i = (pcm.length - 1); i >= 0; i--) {
        for (var j = (pcm[i].length - 1); j >= 0; j -= inc) {
          y = yCenter - Math.round(pcm[i][j] * height)
          x--
          cctx.lineTo(x, y)
          // console.log(x, y);
        }
      }
      cctx.stroke()
      // cctx.fill();
      cctx.closePath()
    },

    drawWaveform: function () {
      var cctx = this.cctx
      var height = this.canvas.height
      var yCenter = height / 2
      var pcm = this.pcm
      var inc = Math.round(pcm.length / this.canvas.width)

      // sample pcm and draw
      cctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      cctx.beginPath()
      var x = 0
      var y = yCenter
      var min = 0.5
      cctx.moveTo(x, y)

      for (var i = 0; i < pcm.length; i += inc) {
        // get absolute value of increment chunk
        var absPcm = utils.arrayAbs(pcm.subarray(i, (i + inc)))
        y = Math.round(absPcm * (height))
        y = y < min ? min : y
        x++
        cctx.fillRect(x, yCenter - (y / 2), 1, y)
        // console.log(x, y);
      }
      // cctx.stroke();
      cctx.fill()
      cctx.closePath()

      this.analyserRAFID = window.requestAnimationFrame(this.drawAnalyser)
    },

    draw: function () {
      var view = this
      var timeData = this.timeData
      this.analyserNode.getByteTimeDomainData(timeData)
      var drawHeightRatio = this.canvas.height / 256
      var drawWidthRatio = this.canvas.width / timeData.length
      this.cctx.lineWidth = 2
      this.cctx.strokeStyle = '#fff'
      this.cctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.cctx.beginPath()

      // var trimmed = false;
      // var wasAbove = false
      // var i;
      // for (i = 0; i < timeData.length; i++) {
      //   if (timeData[i] > 128) { wasAbove = true }
      //   if (timeData[i] <= 128 && wasAbove) {
      //     timeData = timeData.subarray(i, timeData.length);
      //     break;
      //   }
      // }

      for (var i = 0; i < timeData.length; i += 25) {
        this.cctx.lineTo(i * drawWidthRatio, this.canvas.height - (timeData[i] * drawHeightRatio) + 0.5)
      }

      this.cctx.stroke()
      this.cctx.closePath()

      // timout for throttling draws and conserving cpu
      setTimeout(function () {
        view.analyserRAFID = window.requestAnimationFrame(view.draw)
      }, 1000 / 24)
    },

    resizeCanvas: function () {
      this.canvas.width = this.$el.width()
      this.canvas.height = this.$el.height()
    },

    render: function () {
      var view = this
      this.$el.html(this.template(this.model.toJSON()))
      this.$canvas = this.$('.buffer-canvas')
      this.canvas = this.$canvas[0]
      this.cctx = this.canvas.getContext('2d')
      this.cctx.strokeStyle = '#e67e22'
      this.cctx.fillStyle = '#e67e22'
      // this.cctx.shadowColor = '#000000'
      // this.cctx.shadowBlur = 30;

      _.defer(function () {
        view.resizeCanvas()
      })

      if (this.model.get('micArmed') && !this.analyserRAFID) {
        this.draw()
      }

      return this
    }
  })
})()
