/* globals zc _ $ */

(function () {
  'use strict'

  zc.views.CouponFormView = zc.views.FormView.extend({
    initialize: function (options) {
      zc.views.FormView.prototype.initialize.call(this, options)

      this.listenTo(this.model, 'couponError', this.showError)
      this.listenTo(this.model, 'change:coupon', this.render)
    },

    template: _.template($('.coupon-form-template').html()),

    className: 'coupon-form form',

    events: {
      'click .submit': 'submit',
      'keypress input': 'inputKeypress',
      'click .remove': 'remove'
    },

    values: function () {
      return {
        couponCode: this.$couponCode.val()
      }
    },

    url: function () {
      return '/coupon'
    },

    validateForm: function (values) {
      if (!values.couponCode) {
        return 'Please enter a coupon code'
      }
      return false
    },

    submit: function (e) {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      var self = this
      var values = this.values()

      var validationError = this.validateForm(values)

      if (validationError) {
        return this.showError(validationError)
      }

      this.hideError()

      this.model.readCoupon(values.couponCode)
      .success(function (coupon) {
        if (!coupon.valid) return self.showError('This coupon is invalid')
        self.callback(null, coupon)
      })
      .error(function (error) {
        if (error.responseText) {
          self.showError(error.responseText)
        } else {
          self.showError('Unknown error')
        }
      })
    },

    remove: function () {
      this.model.set({coupon: null, couponCode: null})
    },

    render: function () {
      zc.views.FormView.prototype.render.call(this)
      this.$couponCode = this.$('.coupon-code-input')
      return this
    }
  })
})()
