/* globals zc Backbone utils app */

(function () {
  'use strict'

  zc.models.LocalStorageManager = Backbone.Model.extend({
    threshold: {
      caution: Math.pow(1024, 3) * 1.5, // 1.5GB
      warning: Math.pow(1024, 3) * 1, // 1GB
      critical: 0
    },

    alertLevelNames: [
      'ok', 'caution', 'warning', 'critical'
    ],

    alertLevelMessages: [
      'Plenty of space available',
      'Low disk space',
      'Critically low disk space',
      'No space left'
    ],

    alertLevelMessagesExtended: [
      'Plenty of space available',
      'Your available local storage space for backups is getting low.  Please consider deleting some files to free up more space.',
      'Your available local storage space for backups is critically low.  You must free up some space before recording.',
      'There is no space left on this device for saving audio data.'
    ],

    defaults: {
      total: 0,
      used: 0,
      free: 0,
      alertLevel: 0
    },

    attrs: function () {
      var attrs = this.toJSON()
      attrs.alertLevelName = this.alertLevelNames[attrs.alertLevel]
      attrs.alertLevelMessage = this.alertLevelMessages[attrs.alertLevel]

      attrs.formattedTotal = this.formattedDiskSpace(attrs.total)
      attrs.formattedUsed = this.formattedDiskSpace(attrs.used)
      attrs.formattedFree = this.formattedDiskSpace(attrs.free)
      return attrs
    },

    formattedDiskSpace: utils.formattedDiskSpace,

    queryStorageQuota: function () {
      var self = this
      return utils.queryStorageQuota().then(function (quota) {
        quota.alertLevel = self.calcAlertLevel(quota)
        quota.alert = self.alertLevelNames[quota.alertLevel]
        quota.message = self.alertLevelMessages[quota.alertLevel]
        self.set(quota)
        return quota
      })
    },

    calcAlertLevel: function (quota) {
      var free = quota.free
      var used = quota.used
      var total = quota.total
      var alertLevel
      if (free === -1 && used === -1 && total === -1) {
        alertLevel = 0 // storage queries aren't supported.  Ignore low disk space warning
      } else if (free < this.threshold.critical) {
        alertLevel = 3
      } else if (free < this.threshold.warning) {
        alertLevel = 2
      } else if (free < this.threshold.caution) {
        alertLevel = 1
      } else {
        alertLevel = 0
      }

      return alertLevel
    },

    checkStorageQuota: function () {
      var self = this
      return self.queryStorageQuota().then(function (quota) {
        // var sixWeeksAgo = moment(Date.now()).subtract(6, 'weeks')
        // var createdBefore = sixWeeksAgo
        // if (quota.free < self.threshold.warning) createdBefore = moment(Date.now()).subtract(2, 'weeks') // more urgently need to clear off space
        //
        // intentionally don't return these promises just incase the idb deletes hang
        // otherwise we could get stuck with nothing but a white page
        // self.getAndDeleteDatabases(createdBefore).then(function () {
        //   self.queryStorageQuota()
        // })

        return quota
      })
    },

    getAndDeleteDatabases: function (createdBefore) {
      // find deletable databases in local storage and delete them
      var self = this
      return self.getDeletableDatabases(createdBefore).then(function (dbNames) {
        return self.deleteDatabases(dbNames).then(function () {
          return self.setDeletedDatabases(dbNames)
        })
      })
    },

    getDeletableDatabases: function (createdBefore) {
      return new Promise(function (resolve, reject) {
        app.socket.emit('getDeletableDatabases', {userId: app.user.id, createdBefore: createdBefore}, function (err, dbNames) {
          if (err) return reject(err)
          resolve(dbNames)
        })
      })
    },

    deleteDatabases: function (dbNames) {
      var promises = []

      dbNames.forEach(function (dbName) {
        promises.push(utils.deleteIdbDatabase(dbName))
      })

      return Promise.all(promises)
    },

    setDeletedDatabases: function (dbNames) {
      if (!dbNames.length) return // nothing to do
      return new Promise(function (resolve, reject) {
        app.socket.emit('setDeletedDatabases', dbNames, function (err, confirm) {
          if (err) reject(err)
          console.log(err, confirm)
          resolve(confirm)
        })
      })
    }
  })
})()
