/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.AccessingMediaDevicesView = Backbone.View.extend({
    template: _.template($('.accessing-media-devices-template').html()),

    className: 'accessing-media-devices panel',

    render: function () {
      var attrs = this.model ? this.model.toJSON() : {}
      this.$el.html(this.template(attrs))
      return this
    }
  })
})()
