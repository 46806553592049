/* globals $ zc _ debug Backbone app utils */

(function () {
  'use strict'

  var dbg = debug('zc:trackView')

  /**
   * Used to render the box view for each recording file or postproduction
   * model: zc.models.Track
   */
  zc.views.TrackView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model, 'change:uploading', this.uploadingChange)
      this.listenTo(this.model, 'change:downloading', this.downloadingChange)
      // when the track has finished uploading just rerender
      this.listenTo(this.model, 'change:finalized', this.render)
      // this.listenTo(this.model, 'change:percentUploaded', this.percentUploadedChange)
      this.listenTo(this.model, 'change:progress', this.progressChange)
      this.listenTo(this.model, 'change:processing', this.processingChange)
      // this.listenTo(this.model, 'change:downloadPath', this.uploadComplete)
      this.listenTo(this.model, 'change:error', this.errorChange)
      this.listenTo(this.model, 'change:uploading change:finalized change:downloading', this.errorDependencyChange)
    },

    template: _.template($('.track-template').html()),

    className: 'track',

    events: {
      'click .download': 'download',
      'click .finalize': 'forceFinalize',
      'change .track-checkbox': 'toggleSelected',
      'contextmenu': 'renderContextMenu'
    },

    renderContextMenu: function (e) {
      e.preventDefault()
      e.stopPropagation()
      if (app.user.id === this.model.get('userId')) {
        if (this.contextMenu) {
          this.contextMenu.exit()
        }
        this.contextMenu = new zc.views.ContextMenuView({
          options: [
            {name: 'Recover Local Backup', callback: this.recoverLocalBackupCallback(this.model.get('path'))}
          ],
          pos: [e.clientX, e.clientY]
        })
        $('body').append(this.contextMenu.render().el)
      }
    },

    recoverLocalBackupCallback: function (path) {
      var self = this
      return function () {
        self.model.downloadFromLocal(path)
        .catch(function () {
          utils.notify('alert', 'No local backup could be found for this track.', {ttl: 3000})
        })
      }
    },

    /**
     * method used to update the track UI when a file is processing before upload
     * @param  {Track}    model       The Track model
     * @param  {Boolean}  processing  Processing status
     */
    processingChange: function (model, processing) {
      dbg(this.model.get('format'), this.model.id, ' uploadingChange ', processing)
      if (processing) {
        this.showProcessing()
      } else {
        this.hideProcessing()
      }
    },

    showProcessing: function () {
      this.$el.addClass('processing')
    },

    hideProcessing: function () {
      this.$el.removeClass('processing')
    },

    /**
     * method used to update the track UI when a file is uploading
     * @param  {Track}    model       The Track model
     * @param  {Boolean}  uploading   Processing status
     */
    uploadingChange: function (model, uploading) {
      dbg('%s:%s:uploadingChange:%s', this.model.get('format'), this.model.id, uploading)
      if (uploading) {
        this.showUploading()
      } else {
        this.hideUploading()
      }
    },

    showUploading: function () {
      this.hideProcessing() // to make sure these states don't overlap
      this.$el.addClass('uploading')
    },

    hideUploading: function () {
      this.$el.removeClass('uploading')
    },

    toggleSelected: function () {
      var selected = !this.model.get('selected')
      this.model.set({selected: selected})

      // don't use normal event listeners so we don't change the state other track views
      if (selected) {
        this.select()
      } else {
        this.unselect()
      }
    },

    select: function () {
      this.$el.removeClass('unselected')
      this.$el.addClass('selected')
    },

    unselect: function () {
      this.$el.removeClass('selected')
      this.$el.addClass('unselected')
    },

    download: function (e) {
      // e.preventDefault()
      console.log('Clicked to download the track:', this.model.get('format'))
      if (this.model.get('downloading')) {
        // don't allow double downloads
        return
      }

      this.model.downloadFile(this.model.get('path'))
    },

    downloadingChange: function (model, downloading) {
      dbg('%s:%s:downloadingChange:%s', this.model.get('format'), this.model.id, downloading)
      if (downloading) {
        this.showDownloading()
      } else {
        this.hideDownloading()
        this.model.set({progress: 0})
      }
    },

    showDownloading: function () {
      this.$el.addClass('downloading')
    },

    hideDownloading: function () {
      this.$el.removeClass('downloading')
    },

    showFinalized: function () {
      this.hideProcessing()
      this.hideUploading()
      this.$el.addClass('finalized')
    },

    hideFinalized: function () {
      this.$el.removeClass('finalized')
    },

    forceFinalize: function (e) {
      e.preventDefault()
      console.log('Clicked to force finalize the track:', this.model.get('format'))
      this.model.forceFinalizeUpload()
      this.$el.addClass('fixing-error')
      this.$errorMessage.text('Finalizing...')
    },

    /**
     * method used to update the progress when a file is uploading
     * @param  {Track} model    The Track model
     * @param  {Number} progress The progress
     */
    progressChange: function (model, progressPercent) {
      var progressPercentString = progressPercent + '%'
      dbg(this.model.get('format'), this.model.id, 'progressChange', progressPercent)
      this.$progressBar.css({width: progressPercentString})
      this.$progressPercent.text(progressPercentString)
    },

    // percentUploadedChange: function (model, percentUploaded) {
    //   var percentUploadedString = percentUploaded + '%'
    //   console.log('track percent uploaded: ', percentUploadedString)
    //   this.$progressBar.css({width: percentUploadedString})
    //   this.$progressPercent.text(percentUploadedString)
    // },

    // uploadComplete: function (model, downloadPath) {
    //   this.$el.addClass('finalized')
    //   this.model.set({progress: 0})
    // },
    //
    errorChange: function (model, error) {
      if (error) {
        this.showError(error)
      } else {
        this.hideError()
      }
    },

    showError: function (error) {
      this.$el.removeClass('fixing-error')
      this.$el.addClass('has-error')
      this.$errorMessage.text(error)
    },

    hideError: function () {
      this.$el.removeClass('has-error')
    },

    errorDependencyChange: function () {
      this.checkError()
    },

    checkError: function () {
      var processing = this.model.get('processing')
      var uploading = this.model.get('uploading')
      var downloading = this.model.get('downloading')
      var finalized = this.model.get('finalized')
      if (!processing && !uploading && !downloading && !finalized) {
        this.model.set({error: 'Unfinalized Track'})
      } else {
        this.model.set({error: null})
      }
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.$progressBar = this.$('.progress-bar')
      this.$status = this.$('.status')
      this.$progressPercent = this.$('.progress-percent')
      this.$errorMessage = this.$('.error-message')

      // add format class
      this.$el.addClass(this.model.get('format'))
      // add a special css class to distinguish the soundboard track
      this.$el.addClass(this.model.get('type') + '-track')

      if (this.model.get('finalized')) {
        this.showFinalized()
      } else if (this.model.get('uploading')) {
        this.hideFinalized()
        this.showUploading()
      } else if (this.model.get('processing')) {
        this.showProcessing()
      }

      this.checkError()

      if (this.model.get('error')) {
        this.showError()
      }

      // handle selectable states
      if (this.model.get('selectable')) {
        this.$el.addClass('selectable')
        if (this.model.get('selected')) {
          this.$el.addClass('selected')
        } else {
          this.$el.addClass('unselected')
        }
      }

      return this
    }

  })
})()
