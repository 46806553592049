/* globals servars */

(function () {
  window.utils = window.utils || {}

  window.utils.validate = {
    isTruthy: function (value) {
      return !!value
    },

    isFalsy: function (value) {
      return !value
    },

    isString: function (value) {
      return typeof value === 'string'
    },

    isNonEmptyString: function (value) {
      return !!value && this.isString(value)
    },

    isNotEmpty: function (value) {
      return this.isTruthy(value)
    },

    isUSZipCode: function (value) {
      return this.isNumericOfLength(5)(value)
    },

    isCanadianZipCode: function (value) {
      return new RegExp(/[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/).test(value)
    },

    isNumber: function (value) {
      return typeof value === 'number'
    },

    isNumberOfLength: function (length) {
      var self = this
      return function (value) {
        return self.isNumber(value) && value.toString().length === length
      }
    },

    isNumeric: function (value) {
      return !isNaN(parseFloat(value)) && isFinite(value)
    },

    isNumericOfLength: function (length) {
      var self = this
      return function (value) {
        return self.isNumeric(value) && value.toString().length === length
      }
    },

    isCreditCardNumber: function (value) {
      value = value ? value.toString().replace(/[ -]/g, '') : undefined
      return this.isNumericOfLength(16)(value)
    },

    isZip: function (value) {
      return this.isUSZipCode(value) || this.isCanadianZipCode(value)
    },

    isCVV: function (value) {
      return this.isNumericOfLength(3)(value)
    },

    isEmail: function (value) {
      return new RegExp(servars.validation.email).test(value)
    }
  }
})()
