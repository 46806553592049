/* globals zc Backbone */

(function () {
  'use strict'

  zc.models.Buffer = Backbone.Model.extend({
    initialize: function (attrs, options) {
      this.actx = options.actx
      this.startTime = new Date()

      // storing place for chunks received through socket.io until we have them all
      this.chunks = []
      this.totalChunksLength = 0
      this.untouchedChunks = []
      this.numChunks = 8
      this.lastSliceOffset = 0

      this.rawBuffer = new Float32Array((16384 / 4) * 8)
      this.pcmBuffer = new Float32Array((16384 / 4) * 8 * 8)
      this.rawOffset = 0
      this.pcmOffset = 0

      // place save the consolidated chunks
      // each buffers will genrally have a duration of 2.56 seconds and a length 122880
      // the exception to this may the the final buffer
      this.bigChunks = []
      this.totalBigChunksLength = 0

      this.buffers = []
      this.length = 0

      this.on('change:gain', this.gainChange)
      this.on('change:bufferData', this.bufferDataChange)
      this.on('change:playbackRate', this.updateSourcePlaybackRate)
      this.on('change:arrayBuffer', this.decodeArrayBuffer)
      this.on('change:playing', this.playingChange)
    },

    defaults: {
      'gain': 1,
      'playbackRate': 1,
      'bufferData': [],
      'arrayBuffer': null,
      'timeScale': 600 // 10 minutes - how much time is shown in view
    },

    resample: function (buffer, fromSampleRate, targetSampleRate, onComplete) {
      if (fromSampleRate === targetSampleRate) return onComplete(buffer)

      var audioBuffer = this.actx.createBuffer(1, buffer.length, fromSampleRate)
      audioBuffer.copyToChannel(buffer, 0, 0)
      var channel = audioBuffer.numberOfChannels
      var samples = audioBuffer.length * targetSampleRate / audioBuffer.sampleRate

      var offlineContext = new OfflineAudioContext(channel, samples, targetSampleRate)
      var bufferSource = offlineContext.createBufferSource()
      bufferSource.buffer = audioBuffer

      bufferSource.connect(offlineContext.destination)
      bufferSource.start(0)
      offlineContext.startRendering().then(function (renderedBuffer) {
        offlineContext = undefined
        onComplete(renderedBuffer.getChannelData(0))
      })
    },

    // audioStreamEnded: function () {
    //   var self = this;
    //   console.log('Buffer: audioStreamEnded');
    //
    //   // adjust the timescale to show entire buffer
    //   var duration = this.length / this.actx.sampleRate;
    //   this.set({'timeScale': duration});
    //
    //   console.log('Audio Ready');
    //   self.trigger('audioReady');
    // },

    playingChange: function (model, playing) {
      if (playing) {
        this.startPlaying()
      } else {
        this.stopPlaying()
      }
    },

    startPlaying: function (bufferIdx, time) {
      time = time || 0
      bufferIdx = bufferIdx || 0
    },

    stopPlaying: function () {

    },

    sequence: function (buffer, seconds, idx) {
      var model = this
      var actx = this.actx
      var startTime = actx.currentTime
      this.setInterval = setInterval(function () {
        var now = actx.currentTime
        var sinceStart = now - startTime
        var isTime = ((seconds - sinceStart) < 0.25)
        console.log(isTime, seconds - sinceStart)
        if (isTime) {
          model.playBufferAtTime(buffer, (seconds - sinceStart))
          idx++
          clearInterval(model.setInterval)
          model.sequence(model.buffers[idx], buffer.duration, idx)
        }
      }, 25)
    },

    playNextBuffer: function (time, idx) {
      idx++
      this.playBufferAtTime(this.buffers[idx], time)
    },

    playBufferAtTime: function (buffer, time) {
      var bufferSource = this.actx.createBufferSource()
      bufferSource.buffer = buffer
      bufferSource.connect(this.actx.destination)
      bufferSource.start(time)
    }
  })
})()
