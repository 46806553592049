/* globals zc _ Backbone $ app utils analytics */

(function () {
  'use strict'

  zc.views.InviteFormView = Backbone.View.extend({
    initialize: function (options) {
      this.callback = options.callback
      this.collection = this.collection || new zc.collections.Invites()

      this.listenTo(this.collection, 'add', this.renderInvite)
    },

    limit: 10,

    template: _.template($('.invite-form-template').html()),

    className: 'invite-form',

    events: {
      'keypress input': 'inputKeypress',
      'click .invite .remove': 'removeInvite',
      'click .add-invite': 'addInvite',
      'click .send': 'sendInvites',
      'click .copy-link': 'copyLink'
    },

    inputKeypress: function (e) {
      // stop other hotkeys on the page: soundboard, etc
      e.stopPropagation()

      // enter key
      if (e.keyCode === 13) {
        // first fire the blur event on this input
        $(e.target).blur()
        this.sendInvites()
      }
    },

    removeInvite: function (e) {
      $(e.target).parent('.invite').remove()
      // stop the modal from closing
      e.stopPropagation()
    },

    sendInvites: function () {
      var self = this
      var invites = this.collection

      // look for at least one invalid invite
      var hasInvalidInvites = invites.some(function (invite) {
        // we don't care about empty invite boxes
        if (!invite.get('email') && !invite.get('name')) return false

        // if we'll have an invalid email/name validate() will return false
        // and we need to return true so some() will know there is at least one invalid invite
        return !invite.validate()
      })

      // if at least one invite is invalid
      if (hasInvalidInvites) {
        utils.notify('alert', 'It looks like some invites are not valid')
        return
      }

      this.$sendButton.attr('disabled', true)

      // compute the object of invites that will be sent to the server
      // and filter out empty invites
      // TODO: a better way to do this?
      invites = invites.map(function (invite) {
        if (invite.get('email')) {
          return invite.toJSON()
        }
      }).filter(function (invite) { return invite })

      var project = app.project
      var data = {
        invites: invites,
        link: ['', project.get('owner'), project.get('slug')].join('/'),
        recordingName: project.get('name'),
        recordingId: project.recorder.recording.id
      }
      app.socket.emit('recording:invites', data, function (err, response) {
        self.$sendButton.attr('disabled', false)
        if (err) {
          utils.notify('alert', 'Could not send some email invites. Please check that all email addresses are valid.')
          return
        }

        if (self.callback) self.callback()
        utils.notify('success', 'Email invitations sent successfully.', {ttl: 5000})

        // send an event to track email invites
        analytics.track('EmailInviteSent', {
          category: 'social',
          label: invites.length
        })
      })
    },

    /**
     * Used to copy the invite link automatically from the input
     * @param  {Object} ev The jq event object
     */
    copyLink: function (ev) {
      var self = this
      var $el = $(ev.target)

      // fallback method using execCommand
      var fallbackCopy = function () {
        try {
          self.$inviteLink.select()
          var successful = document.execCommand('copy')
          if (!successful) {
            throw new Error('Could not copy text to clipboard')
          }
          $el.text('Link copied')
        } catch (err) {
          console.error('Fallback: Could not copy the link automatically')
          utils.notify('alert', 'Could not copy the link automatically. Press CMD/CTRL + C to copy.')
        }
      }

      // if we don't support the new clipboard API, fallback to execCommand
      if (!navigator.clipboard) {
        fallbackCopy()
        return
      }

      var link = window.location.href
      navigator.clipboard.writeText(link).then(function () {
        $el.text('Link copied')
      })
      .catch(function () {
        fallbackCopy()
      })
    },

    addInvite: function () {
      this.collection.add({})
    },

    renderInvite: function (invite) {
      var inviteView = new zc.views.InviteView({model: invite})
      this.$invites.append(inviteView.render().el)
    },

    renderInvites: function () {
      var self = this
      this.collection.forEach(function (invite) {
        self.renderInvite(invite)
      })
    },

    render: function () {
      this.$el.html(this.template({invites: this.collection.toJSON()}))

      this.$invites = this.$('.invites')
      this.$inviteTemplate = _.template($('.invite-template').html())
      this.$sendButton = this.$('.send')

      this.renderInvites()

      return this
    }
  })
})()
