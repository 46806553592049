/* globals zc _ Backbone app */

(function () {
  'use strict'

  zc.views.RecordingView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model.tracks, 'add', this.renderTrack)
      this.listenTo(this.model.postproductions, 'add', this.renderPostproduction)
      this.listenTo(this.model.transcriptions, 'add', this.renderTranscription)
      this.listenTo(this.model.footnotes, 'add', this.renderFootnote)
    },

    template: _.template($('.recording-template').html()),

    className: 'recording',

    renderTracks: function () {
      var view = this
      this.model.tracks.each(function (track) {
        view.renderTrack(track)
      })
    },

    renderTrack: function (track) {
      // only show tracks you have permission for
      if (app.user.isHost() || track.get('userId') === app.user.id) {
        if (track.get('tentative')) return // tentative: true means that the tracks was never 'truly' created by starting a recording. don't render these
        var trackView = new zc.views.TrackView({model: track})
        this.$tracks.append(trackView.render().el)
      }
    },

    renderPostproductions: function () {
      var view = this
      this.model.postproductions.each(function (postproduction) {
        view.renderPostproduction(postproduction)
      })
    },

    renderPostproduction: function (postproduction) {
      if (app.user.isHost()) {
        var postproductionView = new zc.views.PostproductionView({model: postproduction})
        this.$postproductions.append(postproductionView.render().el)
      }
    },

    renderTranscriptions: function () {
      var view = this
      this.model.transcriptions.each(function (transcription) {
        view.renderTranscription(transcription)
      })
    },

    renderTranscription: function (transcription) {
      if (app.user.isHost()) {
        var transcriptionnView = new zc.views.TranscriptionView({model: transcription})
        this.$transcriptions.append(transcriptionnView.render().el)
      }
    },

    renderFootnotes: function () {
      var view = this
      this.model.footnotes.each(function (footnote) {
        view.renderFootnote(footnote)
      })
    },

    renderFootnote: function (footnote) {
      if (app.user.isHost()) {
        var footnoteView = new zc.views.FootnoteView({model: footnote})
        this.$footnotes.prepend(footnoteView.render().el)
        setTimeout(function () {
          footnoteView.enter()
        }, 15)
      }
    },

    hideTracks: function () {
      this.$tracks.css({'display': 'none'})
    },

    showTracks: function () {
      this.$tracks.css({'display': 'block'})
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.$tracks = this.$('.tracks')
      this.$postproductions = this.$('.postproductions')
      this.$transcriptions = this.$('.transcriptions')
      this.$footnotes = this.$('.footnotes')

      this.renderTracks()
      this.renderPostproductions()
      this.renderTranscriptions()
      this.renderFootnotes()

      return this
    }

  })
})()
