/* globals app zc _ Backbone utils glWaveform */

(function () {
  'use strict'

  zc.views.BufferView = Backbone.View.extend({
    initialize: function (options) {
      // this.listenTo(this.model, 'change:bufferNode', this.drawBuffersWaveform);
      // this.listenTo(this.model, 'newDrawChunk', this.drawChunkWaveform)
      // this.listenTo(this.model, 'newDrawChunk', this.deliverDrawChunk)
      // this.listenTo(this.model, 'audioReady', this.drawBuffersWaveform);
      // this.listenTo(this.model, 'drawBrokenWaveform', this.drawBrokenWaveform)

      this.firstDraw = true
      this.readyToDraw = false
      this.lastTime = new Date()

      this.analyser = options.analyser
      this.floatTimeData = new Float32Array(1024)

      this.listenTo(this.model, 'change:isRecording', this.isRecordingChange)
      this.listenTo(this.model, 'remove', this.tearDown)
    },

    className: 'buffer',

    template: _.template($('.buffer-template').html()),

    drawBrokenWaveform: function (model, audioBuffer) {
      model.set('timeScale', audioBuffer.duration)
      var f32 = audioBuffer.sampleRate ? audioBuffer.getChannelData(0) : audioBuffer
      this.drawWaveformToFit(f32)
    },

    drawWaveformToFit: function (f32) {
      var wctx = this.wctx
      var wavCanvas = this.wavCanvas
      var height = wavCanvas.height
      var width = wavCanvas.width
      var f32PerPx = f32.length / width
      var inc = Math.max(Math.ceil(f32PerPx), 1)
      var x = 0
      var lastSliceMax = 0
      var threshold = 0.18

      wctx.beginPath()
      wctx.moveTo(x, height / 2)
      for (var i = 0; i < width; i++) {
        var sliceStart = i * inc
        var sliceEnd = sliceStart + inc
        var slice = f32.slice(sliceStart, sliceEnd)
        // var sliceSum = slice.reduce(function (a, b) { return a + b }, 0)
        // var signFactor = Math.min(1, Math.max(-1, sliceSum))
        var sliceMax = Math.max.apply(Math, slice)
        // var signedSliceMax = sliceMax * signFactor
        var discontinuity = Math.abs(sliceMax - lastSliceMax) > threshold
        lastSliceMax = sliceMax
        var yOffset = sliceMax * height
        var y = height / 2 + yOffset / 2
        if (i === 0) {
          wctx.beginPath()
          wctx.moveTo(x, y)
        }
        wctx.lineTo(x, y)
        if (discontinuity) {
          this.wctx.strokeStyle = 'red'
        } else {
          this.wctx.strokeStyle = '#bab0ff'
        }
        wctx.stroke()
        wctx.beginPath()
        wctx.moveTo(x, y)
        x++
        if (x >= width) break // break out early if we are past the edge of the canvas
      }
      // wctx.stroke()
    },

    isRecordingChange: function (model, isRecording) {
      this.isRecording = isRecording
      if (this.isRecording) {
        this.recordingStartPrep()
      }
    },

    tearDown: function (model) {
      this.drawWaveformTailAnimation && app.animationFrameManager.removeAnimation(this.drawWaveformTailAnimation)
      this.remove()
    },

    recordingStartPrep: function () {
      // reduce the size of the float time data while recording to optimize for performance
      this.drawX = 0
      // this.floatTimeData = new Float32Array(32)
      this.analyser.fftSize = this.floatTimeData.length

      this.$el.addClass('is-recording')
    },

    startDrawingWaveformTail: function () {
      this.drawWaveformTailAnimation = this.drawWaveformTailAnimation || {context: this, draw: this.drawWaveformTail}
      app.animationFrameManager.addAnimation(this.drawWaveformTailAnimation)
    },

    applyCanvasStyles: function (ctx) {
      ctx.fillStyle = '#ffffff'
      ctx.strokeStyle = '#bab0ff'
      ctx.lineWidth = 1
      ctx.lineCap = 'round'
    },

    drawWaveformTail: function (self) {
      var elWidth = self.$el.width()
      if (self.wavCanvas.width !== elWidth) {
        // bounce the image over to the buffer so we don't loose it when we resize the wavCanvas
        self.bctx.fillRect(0, 0, self.bufCanvas.width, self.bufCanvas.height)
        self.bctx.drawImage(self.wavCanvas, 0, 0)
        self.wavCanvas.width = elWidth
        // resizing the canvas seems to clear out the styles so reapply
        self.applyCanvasStyles(self.wctx)
        self.wctx.fillRect(0, 0, self.wavCanvas.width, self.wavCanvas.height)
        self.wctx.drawImage(self.bufCanvas, self.bufCanvas.width - self.bufCanvas.width, 0)
        self.bufCanvas.width = self.wavCanvas.width
        self.applyCanvasStyles(self.bctx)
      }

      self.analyser.getFloatTimeDomainData(self.floatTimeData)

      if (self.isRecording) {
        // clear the canvas on the first recording call to remove the tail waveform
        if (!self.canvasClearedForRecording) {
          self.wctx.fillRect(0, 0, self.wavCanvas.width, self.wavCanvas.height)
          self.canvasClearedForRecording = true
        }
        self.drawScrollingWaveform(self.floatTimeData)
      } else {
        self.wctx.fillRect(0, 0, self.wavCanvas.width, self.wavCanvas.height)
        self.drawWaveform(self.floatTimeData, 0, self.wavCanvas.width)
      }
    },

    drawWaveform: function (f32, startX, width) {
      var wctx = this.wctx
      var canvas = this.wavCanvas
      var height = canvas.height
      width = width ? Math.max(width, 1) : canvas.width // min width is 1
      width = Math.min(width, canvas.width) // max width is the canvas.width
      var pxPerF32 = width / f32.length
      var pxInc = Math.max(Math.ceil(pxPerF32), 1)
      var x = startX
      this.lastMaxF32 = this.lastMaxF32 || 0

      wctx.beginPath()
      wctx.moveTo(x, height / 2)
      for (var i = 0; i < f32.length; i++) {
        var yOffset = f32[i] * height
        var y = height / 2 + yOffset / 2
        if (i === 0) wctx.moveTo(x, y)
        wctx.lineTo(x, y)
        // wctx.stroke()
        x += pxInc
        if (x >= width) break // break out early if we are past the edge of the canvas
      }
      wctx.stroke()
    },

    drawScrollingWaveform: function (f32) {
      var self = this
      // var draw = function () {
      var wctx = self.wctx
      var height = self.wavCanvas.height
      var pxToTravel = 1

      // start shifting the canvas when we reach the end
      if (this.drawX >= self.wavCanvas.width - pxToTravel) {
        this.drawX = self.wavCanvas.width - pxToTravel

        // check to see if the canvas aren't the same size
        // and make bufCanvas match wavCanvas.
        if (self.bufCanvas.width !== self.wavCanvas.width) {
          self.bufCanvas.width = self.wavCanvas.width
          self.applyCanvasStyles(self.bctx)
        }

        self.bctx.fillRect(0, 0, self.bufCanvas.width, self.bufCanvas.height)
        self.bctx.drawImage(self.wavCanvas, -pxToTravel, 0)

        // self.wctx.clearRect(0, 0, self.wavCanvas.width, self.wavCanvas.height)
        self.wctx.drawImage(self.bufCanvas, 0, 0)
      }

      var sample = Math.max.apply(Math, f32)

      self.lineHeight = self.lineHeight || 0

      var lineHeight = sample ? Math.max(Math.round(sample * height), self.wctx.lineWidth) : self.wctx.lineWidth

      // self.lineHeight = Math.max(lineHeight, self.lineHeight * 0.50) // add some smoothing
      self.lineHeight = lineHeight // no smoothing

      // wctx.fillRect(self.wavCanvas.width - 1, (height / 2) - (self.lineHeight / 2), 1, self.lineHeight)
      var x = this.drawX
      var y = (height / 2) - (self.lineHeight / 2)
      var y1 = y + self.lineHeight
      wctx.beginPath()
      wctx.moveTo(x, y)
      wctx.lineTo(x, y1)
      wctx.stroke()

      if (this.drawX < self.wavCanvas.width - this.wctx.lineWidth) this.drawX += this.wctx.lineWidth
    },

    drawChunkWaveform: function (model, f32, idx) {
      var timeScale = model.get('timeScale')
      var duration = (f32.length / this.model.actx.sampleRate)
      var width = this.wavCanvas.width * (duration / timeScale)
      width = Math.max(width, 1)
      // var width = 2
      var bigBufferDuration = this.model.consolidationCutoff * duration
      var bigBuffersWidth = this.wavCanvas.width * ((bigBufferDuration * this.model.buffers.length) / timeScale)

      var startX = this.wavCanvas.width - (bigBuffersWidth + (idx * width))
      // if (startX >= (timeScale / 2)) { // if we have drawn to the half way point, start translating wavform over
      startX = this.wavCanvas.width - width
      this.bctx.clearRect(0, 0, this.bufCanvas.width, this.bufCanvas.height)
      this.bctx.drawImage(this.wavCanvas, -width, 0)
      this.wctx.clearRect(0, 0, this.wavCanvas.width, this.wavCanvas.height)
      this.wctx.drawImage(this.bufCanvas, 0, 0)
      // }

      this.drawWaveform(f32, startX, width)
    },

    initWaveform: function () {
      // var waveformDrawLib = utils.isCanvasWebglSupported() ? window.glWaveform : window.waveform2d
      // waveformDrawLib = window.waveform2d
      this.waveform = glWaveform({
        container: this.el,
        canvas: this.recCanvas,
        maxDb: -5,
        minDb: -95,
        // sampleRate: 11025,
        scale: 64,
        log: false, // logarithmic scale
        palette: ['#ffffff', '#bab0ff'],
        background: '#ffffff',
        // Enable alpha to make transparent canvas
        alpha: false,
        // Draw automatically every frame or only when data/options changes
        autostart: false,
        // Worker mode, a bit heavy for main thread to sample huge waveforms
        worker: false,
        // Pixel ratio
        pixelRatio: window.devicePixelRatio,
        // size of buffer to allocation for storage - 88200 by default
        bufferSize: 0,
        // I *think* this will prevent fully redrawing the entire waveform each render
        preserveDrawingBuffer: true,
        // Enable panning/zooming by dragging/scrolling
        pan: false,
        zoom: false
      })
    },

    startAnimation: function () {
      this.waveformDrawAnimation = this.waveformDrawAnimation || {context: this, draw: this.drawScrollingWaveform}
      app.animationFrameManager.addAnimation(this.waveformDrawAnimation)
    },

    deliverDrawChunk: function (buffer, f32) {
      if (!this.samples) this.samples = Array.from(f32)
      this.samples = this.samples.concat(Array.from(f32))

      if (!this.waveformDrawAnimation) {
        this.pxPerSecond = 5
        this.startAnimation()
      }
    },

    drawBufferWaveform: function (buffer, idx) {
      var timeScale = this.model.get('timeScale')
      var f32 = buffer.sampleRate ? buffer.getChannelData(0) : buffer
      var duration = (f32.length / this.model.actx.sampleRate)
      var width = this.wavCanvas.width * (duration / timeScale)
      this.drawWaveform(f32, this.lastEndX, width)
      this.lastEndX += width
    },

    drawBuffersWaveform: function (buffers) {
      var view = this
      buffers = buffers || this.model.buffers
      this.lastEndX = 0
      this.wctx.clearRect(0, 0, this.wavCanvas.width, this.wavCanvas.height)
      _.each(buffers, function (buffer, idx) {
        view.drawBufferWaveform(buffer, idx)
      })
    },

    drawSamplesPerLineWaveform: function (f32, samplesPerLine, draw) {
      var self = this
      var wctx = this.wctx
      var height = this.wavCanvas.height
      var inc = Math.min(Math.round(samplesPerLine), 1)

      wctx.strokeStyle = '#d0caff'
      wctx.fillStyle = '#bab0ff'

      // self.lineHeight = self.lineHeight || 50
      for (var i = 0; i < f32.length; i += inc) {
        // get max value of increment chunk
        var maxF32 = utils.arrayAbs(f32.slice(i, i + inc))

        // var rms = utils.audio.rootMeanSquare(f32.slice(i, i + inc))
        var lineHeight = (maxF32 * height)
        lineHeight = Math.max(lineHeight, 1)
        // self.lineHeight = Math.max(lineHeight, self.lineHeight * 0.50) // add some smoothing
        draw({lineHeight: lineHeight, px: 1})
      }

      self.singles.push({lineHeight: this.lineHeight, px: 1})
      if (self.singles.length > self.wavCanvas.width) { self.singles.shift() } // console.log('self.singles shifted and now has length: ', self.singles.length, self.singles) }
    },

    resizeBuffer: function () {
      this.wavCanvas.height = this.$el.height()
      this.wavCanvas.width = this.$el.width()
      this.bufCanvas.height = this.wavCanvas.height
      this.bufCanvas.width = this.wavCanvas.width
    },

    render: function () {
      var view = this
      this.$el.html(this.template(this.model.toJSON()))

      this.$wavCanvas = this.$('.wav-canvas')
      this.$wavCanvas.css('opacity', 0) // hide until we paint it to avoid black flash
      this.wavCanvas = this.$wavCanvas[0]
      this.wctx = this.wavCanvas.getContext('2d', {alpha: false})

      // create clone buffer canvas for translations
      this.bufCanvas = document.createElement('canvas')
      this.bctx = this.bufCanvas.getContext('2d', {alpha: false})
      this.bufCanvas.width = this.wavCanvas.width
      this.bufCanvas.height = this.wavCanvas.height
      // this.$el.append(this.bufCanvas) // for debugging only
      _.defer(function () {
        view.resizeBuffer()
        view.applyCanvasStyles(view.wctx)
        view.applyCanvasStyles(view.bctx)
        view.wctx.fillRect(0, 0, view.wavCanvas.width, view.wavCanvas.height)
        view.bctx.fillRect(0, 0, view.bufCanvas.width, view.bufCanvas.height)

        view.$wavCanvas.css('opacity', '') // remove previously set inline opacity style to reveal canvas
      })

      return this
    }
  })
})()
