/* globals servars zc utils Backbone debug io */

(function () {
  'use strict'

  var dbg = debug('zc:websocket')

  zc.models.SocketConnection = Backbone.Model.extend({
    initialize: function (attrs, options) {
      dbg('Initializing websocket manager')
    },

    defaults: {},

    attrs: function () {
      var attrs = this.toJSON()
      return attrs
    },

    connect: function () {
      var self = this

      var socket = this.socket = io.connect(window.location.protocol + '//' + servars.websocketDomain + ':' + window.location.port, {
        secure: (window.location.protocol === 'https:'),
        transports: ['websocket']
      })

      socket.on('connect', function () { // when a successful connection is made
        self.set({'connected': true})
        console.log('Socket connect successful')
      })

      socket.on('connect_error', function (err) { // when a connect attempt throws an error
        console.error('Socket connect error: ', utils.serializeError(err))
      })

      socket.on('connect_timeout', function (timeout) { // when a connect times out
        console.error('Socket connect timeout. Timeout: ', timeout)
      })

      socket.on('reconnecting', function (attempt) { // fired on a successful reconnection
        console.log('Socket reconnecting.  Attempt: ', attempt)
      })

      socket.on('reconnect_attempt', function (attempt) { // fired on a reconnection attempt
        console.log('Socket reconnect attempt. Attempt: ', attempt)
      })

      socket.on('reconnect_failed', function () { // fired when couldn't reconnect within reconnectionAttempts
        console.error('Socket reconnect attempts failed')
      })

      // when reconnection is successful
      socket.on('reconnect', function (attempt) { // fired on a successful reconnection
        console.log('Socket reconnect. Attempt: ', attempt)
      })

      socket.on('reconnect_error', function (err) { // fired on a reconnect error
        console.error('Socket reconnect error: ', utils.serializeError(err))
      })

      socket.on('disconnect', function (reason) {
        self.set({connected: false})
        if (reason === 'io server disconnect') {
          // the disconnection was initiated by the server and we may need to reconnect manually
          console.warn('Socket disconnect initiated by server')
        }
        console.warn('Socket disconnect. Reason: ', reason)
      })

      socket.on('error', function (err) {
        console.error('Websocket error: ', utils.serializeError(err))
      })

      return this
    },

    initSocketEvents: function (app) {
      var project = app.project
      var socket = this.socket

      // in case we get disconnected and reconnect, rejoin room
      socket.on('connect', function () {
        // wait for joinRoom to complete before we emit any events that were buffered during disconnection
        // this makes sure that the server is ready to handle the events and won't crash the server
        if (socket.sendBuffer.length) {
          var eventBuffer = socket.sendBuffer
          socket.sendBuffer = []
          project.rejoinRoom(function () {
            socket.sendBuffer = eventBuffer
            socket.emitBuffered()
          })
        } else {
          project.rejoinRoom()
        }

        // app.set('connected', socket.connected)
        if (project.$disconnectedAlert) {
          project.$disconnectedAlert.remove()
          utils.notify('success', 'Reconnected', {ttl: 1500})
        }
      })

      socket.on('disconnect', function () {
        dbg('Disconnected from socket server')
        project.$disconnectedAlert = utils.notify('alert', 'Lost connection to server.  Attempting to reconnect...')
      })

      // socket.on('error', function (err) {
      //   console.error('Socket Connection Error: ', err)
      // })
      //
      // socket.on('reconnect', function () { // fired on a successful reconnection
      //   console.log('Socket reconnection: ', arguments)
      // })

      socket.on('roomJoined', function (data) {
        project.addRoomMembers(data.room.members)

        if (!project.initiated) {
          project.startDevices().catch(utils.notifyError)
          project.initiated = true
        } else {
          console.log('already initiated project')

          // if the project is already initiated when we join the room
          // then it indicates that this is a rejoin - probably due
          // to a lapse in the network connection.
          //
          // if voipReady === true on the local user then we just need
          // to call call.voipReady again to reconnect the voip connections
          if (app.user.get('voipReady')) project.call.voipReady()
        }
      })

      socket.on('alreadyJoined', function (data) {
        if (data.redirect) {
          window.location.href = '/alreadyJoined'
        } else {
          project.trigger('alreadyJoined')
        }
      })

      socket.on('redirect:home', function () {
        window.location.href = '/dashboard'
      })

      socket.on('newMember', function (data) {
        project.addRoomMember(data.member)
      })

      socket.on('memberLeft', function (data) {
        console.log('User left the room', data.member.username)
        var user = project.lobby.users.get(data.member._id)
        if (user) {
          project.lobby.users.remove(user)
          project.buffers.remove(project.buffers.get(user.id))
        }
        // project.enableControlsIfNeeded()
      })

      socket.on('startOver', function () {
        project.trigger('stopCloseTabProtection')
        project.startOver()
      })

      socket.on('kickUser', function (data) {
        if (app.user.id === data.userId) {
          project.trigger('stopCloseTabProtection')
          window.location.pathname = '/kicked'
        }
      })

      socket.on('user:change:healthChecks', function (data) {
        var user = project.lobby.users.get(data.userId)

        if (user) {
          user.criticalHealthChecks.reset(data.criticalHealthChecks)
          user.warningHealthChecks.reset(data.warningHealthChecks)
        } else {
          console.warn('Tried to update health checks for non existent user')
        }
      })

      socket.on('user:change:localStorageQuota', function (data) {
        var user = project.lobby.users.get(data.userId)

        if (user) {
          user.set({localStorageQuota: data.localStorageQuota})
        } else {
          console.warn('Tried to update localStorageQuota for non existent user')
        }
      })

      socket.on('user:change:settings', function (data) {
        var user = project.lobby.users.get(data.userId)
        user.settings.set(data.settings)
        if (user.isHost()) { // the hosts settings rule them all
          app.user.settings.set(data.settings)
        }
      })

      socket.on('user:change:audioInput', function (data) {
        var user = project.lobby.users.get(data.userId)
        user.audioInput.set(data.audioInput)
      })

      socket.on('user:change:micArmed', function (data) {
        var user = project.lobby.users.get(data.userId)
        user.audioInput.set(data.audioInput)
        user.set({micArmed: data.micArmed})
      })

      socket.on('user:change:isRecording', function (data) {
        var user = project.lobby.users.get(data.user._id)

        var recAttrs = {isRecording: data.isRecording}
        user.set(recAttrs)

        if (user.isHost()) {
          project.recorder.set(recAttrs)
        }
      })

      socket.on('user:change:muted', function (data) {
        var user = project.lobby.users.get(data.userId)
        user.set({muted: data.muted})
        if (user.isLocal()) {
          user.trigger('localUserMutedChange', user, data.muted)
          // project.localUserMutedChange(user, data.muted);
        }
      })

      socket.on('user:change:paused', function (data) {
        var user = project.lobby.users.get(data.user._id)
        user.set({paused: data.paused})
        project.recorder.set({paused: data.paused})
      })

      socket.on('user:track:created', function (data) {
        var user = project.lobby.users.get(data.userId)
        if (user) user.tracks.add(data.track)
      })

      socket.on('track:change:processing', function (trackAttrs) {
        // when peoples tracks start uploading, we want to add them to the hosts page
        // by moving the track from user.tracks to recording.tracks
        if (app.user.isHost()) {
          trackAttrs.tentative = false // just incase this version of the track never got updated as non-tentative
          var user = project.lobby.users.get(trackAttrs.userId)
          if (!user) return // nothing to do if there is no user

          var track = project.recorder.recording.tracks.get(trackAttrs._id) || user.tracks.get(trackAttrs._id)
          if (!track) {
            // create the track if it doesn't exits.  it should
            trackAttrs.username = user.get('username')
            track = new zc.models.Track(trackAttrs)
          } else {
            track.set(trackAttrs)
          }

          project.recorder.recording.tracks.add(track) // renders the track in the UI
        }
      })

      socket.on('track:change:uploading', function (trackAttrs) {
        // when peoples tracks start uploading, we want to add them to the hosts page
        if (app.user.isHost()) {
          trackAttrs.tentative = false // just incase this version of the track never got updated as non-tentative
          var user = project.lobby.users.get(trackAttrs.userId)
          if (!user) return // nothing to do if there is no user

          var track = project.recorder.recording.tracks.get(trackAttrs._id) || user.tracks.get(trackAttrs._id)
          if (!track) {
            // create the track if it doesn't exits.  it should
            trackAttrs.username = user.get('username')
            track = new zc.models.Track(trackAttrs)
          } else {
            track.set(trackAttrs)
          }

          project.recorder.recording.tracks.add(track) // renders the track in the UI
        }
      })

      // socket.on('track:change:bytesRecorded', function (data) {
      //   var track = project.recorder.recording.tracks.get(data._id)
      //   if (track) {
      //     track.set({bytesRecorded: data.bytesRecorded})
      //   }
      // })
      //
      // socket.on('track:change:bytesSaved', function (data) {
      //   var track = project.recorder.recording.tracks.get(data._id)
      //   if (track) {
      //     track.set({bytesSaved: data.bytesSaved})
      //   }
      // })
      //
      // socket.on('track:change:bytesUploaded', function (data) {
      //   var track = project.recorder.recording.tracks.get(data._id)
      //   if (track) {
      //     track.set({bytesUploaded: data.bytesUploaded})
      //   }
      // })

      socket.on('track:change:percentSaved', function (data) {
        var user = project.lobby.users.get(data.userId)
        if (!user) return
        var track = user.tracks.get(data._id)
        if (track) {
          track.set({
            bytesRecorded: data.bytesRecorded,
            bytesSaved: data.bytesSaved,
            bytesUploaded: data.bytesUploaded
            // percentSaved: data.percentSaved
          })
        }
      })

      socket.on('track:change:percentUploaded', function (data) {
        var user = project.lobby.users.get(data.userId)
        if (!user) return
        var track = user.tracks.get(data._id)
        if (track) {
          track.set({
            bytesRecorded: data.bytesRecorded,
            bytesSaved: data.bytesSaved,
            bytesUploaded: data.bytesUploaded
            // percentUploaded: data.percentUploaded
          })
        }
      })

      socket.on('track:change:finalized', function (data) {
        var track = project.recorder.recording.tracks.get(data._id)
        if (track) {
          track.set(data)
        }
      })

      socket.on('user:initialization:failure', function (data) {
        if (app.user.isHost()) {
          // notify the host
          utils.notify('error', data.user.username + ' ran into an error initializing their sesssion for recording: ' + data.error + '<br /><br />Aborting recording to prevent audio loss.  If this problem persists, please contact support at support@zencastr.com')

          // stop the recording, this should propagate to all parties
          project.recorder.set({isRecording: false})
        }
      })

      socket.on('user:change:deferUploads', function (data) {
        var user = project.lobby.users.get(data.user._id)
        user.set({deferUploads: data.deferUploads})

        if (user.isHost()) {
          project.set({deferUploads: data.deferUploads})
        }
      })

      // socket.on('user:drawChunk', function (data) {
      //   var buffer = project.buffers.get(data.user._id)
      //
      //   // create it if it doesn't exist
      //   // if (!buffer) {
      //   //   // use the associated user id as the buffer id
      //   //   buffer = new zc.models.Buffer({id: meta.user}, {actx: project.actx});
      //   //   project.buffers.add(buffer);
      //   // }
      //
      //   var f32Chunk = new Float32Array(data.arrayBuffer)
      //
      //   // console.log(data);
      //   // push chunks to buffer
      //   buffer.trigger('newDrawChunk', buffer, f32Chunk, data.idx)
      // })

      socket.on('incomingAudio', function (chunk, meta) {
        // check for existing buffer for user
        var buffer = project.buffers.get(meta.user)

        // create it if it doesn't exist
        if (!buffer) {
          // use the associated user id as the buffer id
          buffer = new zc.models.Buffer({id: meta.user}, {actx: project.actx})
          project.buffers.add(buffer)
        }

        var f32Chunk = new Float32Array(chunk)

        // push chunks to buffer
        buffer.trigger('newChunk', buffer, f32Chunk)
      })

      // socket.on('audioStreamEnded', function (data) {
      //   var buffer = project.buffers.get(data.user);
      //   buffer.trigger('audioStreamEnded');
      //   console.log('STREAM ENDED');
      // });

      socket.on('chat:message', function (message) {
        project.chat.messages.add(message)
      })

      socket.on('user:chat:typingStarted', function (user) {
        project.chat.trigger('remoteTypingStarted', project.chat, user)
      })

      socket.on('user:chat:typingStopped', function (user) {
        project.chat.trigger('remoteTypingStopped', project.chat, user)
      })

      socket.on('user:change:handRaised', function (data) {
        var user = project.lobby.users.get(data.userId)
        user.set({handRaised: data.handRaised})
      })
    }
  })
})()
