/* globals zc app debug servars navigator */
(function () {
  'use strict'

  var dbg = debug('zc')
  var session = window.session

  var appAttrs = {}
  if (session.user) {
    appAttrs.user = new zc.models.User(session.user)
  }

  // if we have a pipelineVersion
  if (servars.pipelineVersion) {
    if (window.trackJs) {
      window.trackJs.addMetadata('pipelineVersion', servars.pipelineVersion)
    }

    if (window.Intercom) {
      window.Intercom('update', {'pipelineVersion': servars.pipelineVersion})
    }
  }

  window.app = new zc.models.App(appAttrs)
  window.appView = new zc.views.AppView({model: app, el: $('.app')})
  window.appView.render()
  window.app.start()

  if (!servars.production && dbg.enabled) {
    (function () {
      // milliseconds
      var lastTime = (new Date()).getTime()
      var acceptableDelta = 50
      var tick = 50
      var hung = false

      function hangman () {
        var now = (new Date()).getTime()
        if (now - lastTime > (tick + acceptableDelta)) {
          hung = true
        } else if (hung) {
          hung = false
          console.warn('Possible browser hangup detected.')
        }
        lastTime = now
      }

      setInterval(hangman, tick)
    })()
  }
})()
