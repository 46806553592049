/* globals zc _ Backbone app */

(function () {
  'use strict'

  zc.views.ProjectView = zc.views.PageView.extend({
    initialize: function (options) {
      zc.views.PageView.prototype.initialize.call(this, options)

      this.listenTo(this.model.recorder, 'change:isRecording', this.isRecordingChange)
      this.listenTo(this.model.recorder, 'change:recording', this.recordingChange)

      this.listenTo(this.model, 'userAudioUploaded', this.userAudioUploaded)
      this.listenTo(this.model, 'alreadyJoined', this.alreadyJoined)

      this.listenTo(this.model, 'startCloseTabProtection', this.startCloseTabProtection)
      this.listenTo(this.model, 'stopCloseTabProtection', this.stopCloseTabProtection)
      this.listenTo(this.model, 'localBackup', this.localBackup)

      this.listenTo(this.model, 'showAudioProcessingPopup', this.showAudioProcessingPopup)
      this.listenTo(this.model, 'hideAudioProcessingPopup', this.hideAudioProcessingPopup)
      this.listenTo(this.model, 'showCloseWarning', this.showCloseWarning)
      this.listenTo(this.model, 'requestAudioPlayback', this.requestAudioPlayback)

      this.listenTo(this.model.recordings, 'click', this.recordingLinkClick)
    },

    template: _.template($('.project-template').html()),

    className: 'project page',

    recordingLinkClick: function (recording) {
      this.model.recordings.setAll({active: false})
      var isLast = recording.collection.indexOf(recording) === recording.collection.length - 1
      var urlComponents = [this.model.get('owner'), this.model.get('slug')]

      if (!isLast) {
        urlComponents.push(recording.id)
      }

      var url = urlComponents.join('/')
      app.router.navigate(url)
      this.model.recorder.set({recording: recording})
    },

    recordingChange: function (model, recording) {
      if (this.lastRecording) {
        this.lastRecording.trigger('exit')
      }
      recording.set({active: true})
      this.lastRecording = recording
      this.renderRecording()

      if (recording.get('duration')) {
        this.$lobby.hide()
      } else {
        this.$lobby.show()
      }
    },

    isRecordingChange: function (model, isRecording) {
      if (isRecording) {
        this.startCloseTabProtection()
        this.disableRecordingLinks()
        this.hideHeader()
        this.optimizeAnimations()
        this.recordingView.hideTracks()
      } else {
        this.enableRecordingLinks()
        this.showHeader()
        this.unoptimizeAnimations()
        this.recordingView.showTracks()
      }
    },

    optimizeAnimations: function () {
      app.animationFrameManager.optimize()
      this.$logo.hide()
    },

    unoptimizeAnimations: function () {
      app.animationFrameManager.unoptimize()
      this.$logo.show()
    },

    hideHeader: function () {
      var headerHeight = this.$header.outerHeight()
      this.$header.css({'margin-top': -headerHeight})
    },

    showHeader: function () {
      this.$header.css({'margin-top': ''})
    },

    showCloseWarning: function () {
      var self = this

      // if the modal is already up, exit
      if (this.closeWarningModalView) return

      this.closeWarningModalView = new zc.views.ModalView({
        addClass: 'close-warning-modal',
        model: new Backbone.Model({
          title: 'WAIT!! Please Don\'t Close This Tab',
          text: 'Your audio can only finish uploading if the browser tab remains open. ' +
                'You will receive a notification when your uploads are complete. ' +
                '<span class="tip">You only need to wait for your own tracks to finish.</span>',
          confirmText: 'Ok, I\'ll Wait',
          cancelText: null
        }),
        ChildView: zc.views.ConfirmView,
        callback: function (confirmed) {
          self.closeWarningModalView.exit()
        }
      })

      this.closeWarningModalView.$el.addClass('wait-for-uploads-modal')

      this.closeWarningModalView.render()
    },

    showAllUploadedNotice: function () {
      var self = this

      this.allUploadedModalView = new zc.views.ModalView({
        addClass: 'all-uploaded-modal',
        model: new Backbone.Model({
          title: 'You\'re All Done!',
          text: 'All of your tracks have finished uploading and it is now safe to close this tab.',
          confirmText: 'Ok, got it',
          cancelText: null
        }),
        ChildView: zc.views.ConfirmView,
        callback: function (confirmed) {
          self.allUploadedModalView.exit()
        }
      })

      this.allUploadedModalView.render()
    },

    requestAudioPlayback: function () {
      var self = this
      var requestAudioPlaybackModal = new zc.views.ModalView({
        addClass: 'request-audio-playback-modal',
        force: true,
        model: new Backbone.Model({
          title: 'Enable Audio Playback',
          text: 'Click below to allow audio playback from Zencastr',
          confirmText: 'Allow Audio Playback',
          cancelText: false
        }),
        ChildView: zc.views.ConfirmView,
        callback: function (confirmation) {
          if (!confirmation) {
            console.error('Audio playback request rejected')
          }

          requestAudioPlaybackModal.exit()

          self.model.audioPlaybackAllowed()
        }
      })

      requestAudioPlaybackModal.render()
    },

    disableRecordingLinks: function () {
      this.model.recordings.setAll({disabled: true})
    },

    enableRecordingLinks: function () {
      this.model.recordings.setAll({disabled: false})
    },

    userAudioUploaded: function (track) {
      if (app.user.ownsTrack(track) && app.user.isAllAudioUploaded()) {
        this.stopCloseTabProtection()
        if (this.closeWarningModalView) {
          this.closeWarningModalView.exit()
        }
        this.showAllUploadedNotice()
      }
    },

    alreadyJoined: function () {
      var self = this
      var alreadyJoinedModal = new zc.views.ModalView({
        addClass: 'request-audio-playback-modal',
        force: true,
        model: new Backbone.Model({
          title: 'Opened in another tab',
          text: 'It seems you\'ve opened this link in another tab.',
          confirmText: 'End other session',
          cancelText: false
        }),
        ChildView: zc.views.ConfirmView,
        callback: function (confirmation) {
          if (confirmation) {
            self.model.endOtherSessions().then(function () {
              alreadyJoinedModal.exit()
            })
          }
        }
      })

      alreadyJoinedModal.render()
    },

    disableNavLinksRouting: function () {
      $('.nav').on('click', this.stopNavLink)
    },

    enableNavLinksRouting: function () {
      $('.nav').off('click', this.stopNavLink)
    },

    stopNavLink: function (e) {
      e.stopPropagation()
    },

    startCloseTabProtection: function () {
      // this.disableNavLinksRouting()
      window.addEventListener('beforeunload', this.closeTabConfirm)
      window.onhashchange = this.closeTabConfirm
      this.model.set('closeTabProtection', true)
    },

    stopCloseTabProtection: function () {
      // this.enableNavLinksRouting()
      window.removeEventListener('beforeunload', this.closeTabConfirm)
      window.onhashchange = function () {}
      this.model.set('closeTabProtection', false)
    },

    closeTabConfirm: function (e) {
      var confirmationMessage = "WARNING! Your uploads haven't completed. You will have to return and retry the uploads later.  Do you still want to leave?";

      (e || window.event).returnValue = confirmationMessage // Gecko + IE
      return confirmationMessage                            // Webkit, Safari, Chrome
    },

    localBackup: function () {
      var localBackupPopupView = new zc.views.LocalBackupPopupView({
        model: this.model
      })
      localBackupPopupView.render()
    },

    showRecordingTips: function (cb) {
      var recordingTipsPopupView = new zc.views.RecordingTipsPopupView({model: this.model, callback: cb})
      recordingTipsPopupView.render()
    },

    startAudioPlayback: function () {
      this.model.startAudioPlayback()
    },

    renderRecorder: function () {
      var recorderView = new zc.views.RecorderView({model: this.model.recorder, el: this.$recorder})
      recorderView.render()
    },

    renderRecordingLinks: function () {
      var view = this
      this.model.recordings.each(function (recording) {
        view.renderRecordingLink(recording)
      })
    },

    renderRecordingLink: function (recording) {
      var recordingLinkView = new zc.views.RecordingLinkView({model: recording})
      this.$recordings.prepend(recordingLinkView.render().el)
    },

    renderRecording: function () {
      var recording = this.model.recorder.get('recording')
      this.recordingView = new zc.views.RecordingView({model: recording, el: this.$recording})
      this.$recording.css({opacity: 0})
      this.recordingView.render()
      this.$recording.animate({opacity: 1})
    },

    renderLobby: function () {
      var lobbyView = new zc.views.LobbyView({model: this.model.lobby, project: this.model, el: this.$lobby})
      lobbyView.render()
    },

    renderChat: function () {
      var chatView = new zc.views.ChatView({model: this.model.chat, el: this.$chat})
      chatView.render()
    },

    showAudioProcessingPopup: function () {
      if (!this.audioProcessingModal) {
        this.audioProcessingModal = new zc.views.ModalView({
          model: this.model,
          ChildView: zc.views.AudioProcessingView,
          force: true
        })
        this.audioProcessingModal.render()
      }
    },

    hideAudioProcessingPopup: function () {
      if (this.audioProcessingModal) {
        this.audioProcessingModal.exit()
      }
    },

    renderBuffers: function () {
      var view = this
      view.model.buffers.forEach(function (buffer) {
        view.renderBuffer(buffer)
      })
    },

    renderBuffer: function (buffer) {
      var bufferView = new zc.views.BufferView({model: buffer, id: 'buffer-' + buffer.id})
      this.$buffers.append(bufferView.render().el)
    },

    removeBuffer: function (buffer) {
      var bufferEl = this.$('#buffer-' + buffer.id)
      bufferEl.remove()
    },

    checkSubscription: function () {
      new zc.views.CheckSubscriptionView({}).render()
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.$header = this.$('> .header')
      this.$recorder = this.$('.recorder')
      this.$lobby = this.$('.lobby')
      this.$chat = this.$('.chat')
      this.$buffers = this.$('.buffers')
      this.$recordings = this.$('.recordings')
      this.$recording = this.$('.content .recording')
      this.$logo = this.$header.find('.logo-grad use')

      this.renderRecorder()
      this.renderRecording()
      this.renderChat()

      if (app.user.isHost() && this.model.recordings.length > 1) {
        this.renderRecordingLinks()
      }

      // start local audio playback here once the view is intantiated
      // so we can respond to errors with ui if needed
      this.startAudioPlayback()

      this.renderLobby()

      // disabled for now
      // we were having issues with user doing single page nav to
      // the pricing page and their application state is all screwed
      this.disableNavLinksRouting()

      // Prompt for credit card if pro and missing payment source
      // We wait a bit to make sure it's always on top of other modals
      setTimeout(this.checkSubscription, 1500)

      return this
    }
  })
})()
