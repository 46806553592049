/* globals zc _  */

(function () {
  'use strict'

  zc.views.UnsupportedBrowserView = zc.views.PageView.extend({

    template: _.template($('.unsupported-browser-template').html()),

    className: 'unsupported-browser-page',

    render: function () {
      return this._render()
    }
  })
})()
