/* globals zc _ Backbone utils app StripeCheckout analytics */

(function () {
  'use strict'

  zc.models.Billing = Backbone.Model.extend({
    subscribe: function () {

    },

    defaults: {
      interval: 'month',
      email: '',
      panelLabel: 'Subscribe',
      couponCode: null, // coupon id string
      coupon: null // coupon object from stripe
    },

    plans: window.servars.activePlans,

    postproductionCreditBundles: [],

    attrs: function () {
      var attrs = this.toJSON()
      attrs.model = this
      attrs.plans = {
        hobbyist: this.plans[attrs.interval][0],
        professional: this.plans[attrs.interval][1]
      }

      attrs.hobbyistAmount = attrs.plans.hobbyist.amount
      attrs.professionalAmount = attrs.plans.professional.amount

      attrs.postproductionCreditBundles = this.postproductionCreditBundles
      attrs.canTrial = !app.user || app.user.subscription.canTrial()
      attrs.trialing = app.user && app.user.subscription.trialing()
      attrs.currentPlanId = !app.user || app.user.subscription.plan.id
      // attrs.intervalAbbrev = attrs.interval == 'month' ? 'mo' : 'yr';
      attrs.intervalAbbrev = 'mo'  // showing the price per month even for yearly plans (dat psychology)

      if (attrs.interval === 'year') {
        attrs.hobbyistAmount = attrs.hobbyistAmount / 12
        attrs.professionalAmount = attrs.professionalAmount / 12
      }

      var coupon = this.get('coupon')
      if (coupon && coupon.valid) {
        attrs.hobbyistAmount = this.applyCoupon(attrs.hobbyistAmount)
        attrs.professionalAmount = this.applyCoupon(attrs.professionalAmount)
        attrs.couponDescription = this.getCouponDescription()
      }

      return attrs
    },

    getPlanById: function (planId) {
      var plan = _.findWhere(this.plans[this.get('interval')], {_id: planId})
      if (!plan) {
        plan = _.findWhere(this.plans.trial, {_id: planId})
      }
      return plan
    },

    createPaymentSourceForm: function (tokenCallback) {
      return StripeCheckout.configure({
        key: window.servars.stripePublicKey,
        image: utils.cdnUrl('/media/images/lavendar-square-small.png'),
        color: '#8bafff',
        locale: 'auto',
        token: tokenCallback,
        panelLabel: this.get('panelLabel'),
        email: this.get('email'),
        opened: function () {},
        closed: function () {}
      })
    },
    applyCoupon: function (amount) {
      var coupon = this.get('coupon')

      if (coupon.amount_off) {
        return amount - coupon.amount_off
      }

      if (coupon.percent_off) {
        return amount - amount * (coupon.percent_off / 100)
      }

      return amount
    },

    getCouponDescription: function () {
      var coupon = this.get('coupon')
      var interval = this.get('interval')
      var desc = ''

      if (coupon.amount_off) {
        // the amount comes from stripe multiplied by 100. eg: for $5 we get 500
        desc += '$' + coupon.amount_off / 100
      } else if (coupon.percent_off) {
        desc += coupon.percent_off + '%'
      }

      if (coupon.duration === 'forever') {
        desc += ' Off forever'
      } else if (interval === 'year') {
        desc += ' Off the entire year'
      } else if (interval === 'month' && coupon.duration === 'once') {
        desc += ' Off your first month'
      } else if (interval === 'month' && coupon.duration_in_months) {
        desc += ' Off the first ' + coupon.duration_in_months + ' months'
      }

      desc += '!' // exciting

      return desc
    },

    createSubscription: function (data) {
      var self = this

      this.trigger('loading')

      var payload = {
        userId: data.user.id,
        planId: data.planId,
        trialEnd: data.trialEnd,
        source: data.source
      }

      var coupon = this.get('coupon')
      if (coupon) {
        payload.coupon = coupon.id
      }

      return $.ajax({
        url: '/api/subscriptions',
        type: 'POST',
        data: payload
      }).success(function (sub) {
        analytics.track('Subscribed', {
          planId: data.planId,
          trialEnd: data.trialEnd,
          amount: sub.plan.amount,
          interval: sub.plan.interval
        })
      }).error(function (res) {
        if (res.responseText === 'payment_required') {
          utils.notify('alert', 'You need to provide a credit card to take advantage of this of the Professional trial.')
          // show the stripe popup
          self.set({email: app.user.get('email'), panelLabel: 'Add Payment Source'})

          self.createPaymentSourceForm(function (token) {
            // add the card to the local user
            app.user.paymentSource.set(token.card)
            // try again
            data.source = token
            self.createSubscription(data).success(function () {
              // hacky way to redirect to dashboard
              window.location = '/dashboard'
            })
          }).open()
        } else {
          utils.notify('error', 'Error: ' + res.responseText)
        }
      }).always(function (res) {
        self.trigger('doneLoading')
      })
    },

    readSubscription: function (data) {
      var self = this

      this.trigger('loading')

      return $.ajax({
        url: '/api/subscriptions/' + data.subscriptionId,
        type: 'GET'
      }).success(function (res) {
        app.user.set(res)
      }).error(function (res) {
        utils.notify('error', res.responseText)
      }).always(function (res) {
        self.trigger('doneLoading')
      })
    },

    updateSubscription: function (data) {
      var self = this

      this.trigger('loading')

      var payload = {
        userId: data.user.id,
        planId: data.planId,
        trialEnd: data.trialEnd,
        source: data.source,
        reason: data.reason
      }

      data.subscriptionId = data.subscriptionId || app.user.subscription.id

      var coupon = this.get('coupon')
      if (coupon) {
        payload.coupon = coupon.id
      }

      return $.ajax({
        url: '/api/subscriptions/' + data.subscriptionId,
        type: 'PUT',
        data: payload
      }).success(function (res) {
      }).error(function (res) {
        utils.notify('error', res.responseText)
      }).always(function (res) {
        self.trigger('doneLoading')
      })
    },

    cancelSubscription: function (data) {
      var self = this

      this.trigger('loading')

      return $.ajax({
        url: '/api/subscriptions/' + data.subscriptionId + '/cancel',
        type: 'POST',
        data: data
      }).success(function (res) {
        app.user.set(res)
      }).error(function (res) {
        utils.notify('error', res.responseText)
      }).always(function (res) {
        self.trigger('doneLoading')
      })
    },

    updateCustomer: function (data) {
      var self = this

      this.trigger('loading')

      return $.ajax({
        url: '/api/customers',
        type: 'PUT',
        data: {
          userId: data.user.id,
          coupon: data.coupon, // coupon code string
          email: data.email,
          source: data.paymentSource
        }
      }).success(function (res) {
        app.user.set(res)
        if (res.paymentSource) {
          app.user.paymentSource = new zc.models.PaymentSource(res.paymentSource, {user: app.user})
        }
      }).error(function (res) {
        utils.notify('error', res.responseText)
      }).always(function (res) {
        self.trigger('doneLoading')
      })
    },

    purchasePostproductionCredits: function (numCredits) {
      var self = this
      this.trigger('loading')
      return new Promise(function (resolve, reject) {
        app.socket.emit('postproductionCredits:purchase', numCredits, function (err, charge) {
          if (err) return reject(err)
          resolve(charge)

          analytics.track('One-off Purchase', {
            product: 'postproductionCredits',
            numCredits: numCredits,
            amount: charge.amount
          })
        })
      }).catch(function (err) {
        self.trigger('doneLoading')
        utils.notify('error', err)
        throw err // stops promise chain
      }).then(function (res) {
        self.trigger('doneLoading')
        return res
      })
    },

    purchasePostproductionCreditBundle: function (bundleId) {
      var self = this
      this.trigger('loading')
      return new Promise(function (resolve, reject) {
        app.socket.emit('postproductionCreditBundle:purchase', bundleId, function (err, charge) {
          if (err) return reject(err)
          resolve(charge)
          analytics.track('One-off Purchase', {
            product: 'postproductionCreditBundle',
            bundleId: bundleId,
            amount: charge.amount
          })
        })
      }).catch(function (err) {
        self.trigger('doneLoading')
        utils.notify('error', err)
        throw err // stops promise chain
      }).then(function (charge) {
        self.trigger('doneLoading')
        return charge
      })
    },

    readCoupon: function (couponCode) {
      var self = this

      this.trigger('loading')

      return $.ajax({
        url: '/api/coupons/' + couponCode,
        type: 'GET'
      }).success(function (res) {

      }).error(function (res) {
        console.error(res.responseText)
        self.trigger('couponError', res.responseText)
      }).always(function (res) {
        self.trigger('doneLoading')
      })
    },

    applyCouponCode: function (couponCode, userId) {
      var self = this
      var canUseCoupon = utils.canUseCoupon(couponCode)
      this.trigger('loading')

      if (!canUseCoupon) {
        throw new Error('Cannot use coupon code again')
      }
      return $.ajax({
        url: '/api/coupons/' + couponCode,
        type: 'POST',
        data: {
          userId: userId
        }
      }).success(function (customer) {
        if (customer && customer.discount && customer.discount.coupon) {
          var coupon = customer.discount.coupon
          var trackingParams = { name: coupon.name }
          if (coupon.amount_off) {
            trackingParams.amountOff = (coupon.amount_off / 100).toFixed(0)
          }
          if (coupon.percent_off) {
            trackingParams.percentOff = coupon.percent_off
          }
          analytics.track('CouponApplied', trackingParams)
        }
        utils.setUsedCoupon(couponCode)
        self.trigger('doneLoading')
      }).error(function (res) {
        console.error(res.responseText)
        self.trigger('coupon apply error', res.responseText)
      }).always(function (res) {
        self.trigger('doneLoading')
      })
    },

    /**
     * Used to get the billing history for a user
     * @return {Array} The history
     */
    getBillingHistory: function () {

    }

  })
})()
