/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.ProjectGroupView = Backbone.View.extend({
    initialize: function (options) {
      this.projects = this.collection

      this.listenTo(this.projects, 'add', this.renderProjectLink)
      this.listenTo(this.projects, 'reset', this.handleProjectsReset)
      this.listenTo(this.projects, 'loading', this.showProjectsLoading)
      this.listenTo(this.projects, 'doneLoading', this.hideProjectsLoading)
    },

    className: 'projects',

    template: _.template($('.projects-template').html()),

    events: {
      'click .toggle-archived': 'toggleShowArchivedProjects'
    },

    toggleShowArchivedProjects: function () {
      var archived = this.model.get('archived')
      if (archived) {
        this.$toggleArchived.text('Show Archived')
      } else {
        this.$toggleArchived.text('Hide Archived')
      }
      this.model.set({archived: !archived})
    },

    showProjectsLoading: function () {
      this.$el.find('a,button').addClass('disabled')
    },

    hideProjectsLoading: function () {
      this.$el.find('a,button').removeClass('disabled')
    },

    handleProjectsReset: function (projects) {
      this.renderProjectLinks()
      this.renderPagination()
    },

    renderProjectLinks: function () {
      var view = this
      this.$projectLinks.empty()
      console.log('Rendering Project Links')
      this.collection.each(function (project) {
        view.renderProjectLink(project)
      })
    },

    renderProjectLink: function (project) {
      var projectLinkView = new zc.views.ProjectLinkView({model: project})
      this.$projectLinks.append(projectLinkView.render().el)
    },

    renderPagination: function () {
      var paginationView = new zc.views.PaginationView({model: this.model.pagination, el: this.$pagination})
      paginationView.render()
    },

    render: function () {
      this.$el.html(this.template())
      this.$projectLinks = this.$('.project-links')
      this.$pagination = this.$('.pagination')
      this.$toggleArchived = this.$('.toggle-archived')

      this.renderProjectLinks()
      this.renderPagination()

      return this
    }
  })
})()
