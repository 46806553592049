/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.EchoCancellationSettingView = Backbone.View.extend({
    initialize: function () {
      this.settings = this.model
      this.listenTo(this.settings, 'change:echoCancellation', this.updateSelection)
    },

    template: _.template($('.echo-cancellation-setting-template').html()),

    className: 'echo-cancellation-setting',

    events: {
      'change .echo-cancellation-setting-select': 'selectChange'
    },

    updateSelection: function () {
      var echoCancellation = this.settings.get('echoCancellation')
      this.$('.echo-cancellation-setting-select option[value="' + echoCancellation + '"]').attr('selected', true)
    },

    selectChange: function (e) {
      this.settings.set({echoCancellation: JSON.parse(e.target.value)})
      console.log('Changed echo cancellation setting: ', e.target.value ? 'on' : 'off')
    },

    render: function () {
      this.$el.html(this.template(this.settings.toJSON()))
      this.updateSelection()

      return this
    }
  })
})()
