/* globals zc _ Backbone app $ */

(function () {
  'use strict'

  zc.views.ModalView = Backbone.View.extend({
    initialize: function (options) {
      this.options = options || {}
      this.ChildView = options.ChildView
      this.callback = options.callback
      this.force = options.force // user can't click away or exit modal
      this.noBlurBackground = options.noBlurBackground || false
      this.boundGlobalKeypress = this.globalKeypress.bind(this)

      var className = options.addClass ? this.className + ' ' + options.addClass : this.className
      this.$el.addClass(className)
    },

    className: 'modal',

    template: _.template('<div class="content" role="dialog" aria-modal="true"></div>'),

    events: {
      'click': 'handleClick'
    },

    startEventListeners: function () {
      // These events notify us if this modal's ChildView also launches another modal.
      // In this case, we want to blur this modal while the ensuing modal is in the foreground.
      this.listenTo(app, 'modalEnter', this.onModalEnter)
      this.listenTo(app, 'modalExit', this.onModalExit)

      // exit if the route chages
      this.listenTo(app.router, 'all', this.exit)
    },

    stopEventListeners: function () {
      this.stopListening(this.model, 'modalEnter')
      this.stopListening(this.model, 'modalExit')
      this.stopListening(app.router, 'all')
    },

    handleClick: function (e) {
      var $target = $(e.target)
      // exit if the modal is clicked outside of the content area
      // unless this mode is turned off
      if (!this.force && !this.options.stayOpen && !$target.is(this.$content) && !$target.closest(this.$content).length) {
        this.exit()
      }
    },

    enter: function () {
      this.$content.css({opacity: 1, transform: 'translateY(0px)'})
      if (!this.noBlurBackground) {
        $('.page').addClass('blur')
      }

      // trigger event so any modals currently rendered will know to
      // take a back seat.  Pass this view's cid so we can know which
      // modal view fired the event
      app.trigger('modalEnter', this.cid)

      // only start event listeners after triggering modalEnter so
      // we don't trigger our own listeners
      this.startEventListeners()
      this.bindHotkeys()
    },

    exit: function () {
      var view = this
      this.$content.css({opacity: 0, transform: ''})

      if (!this.noBlurBackground) {
        // this trick is necessary because if the tab is not focused and we call enter and exit for a popup
        // the blur doesn't seem to get removed
        setTimeout(function () {
          $('.page').removeClass('blur')
        }, 0)
      }

      // we can now stop listening
      this.stopEventListeners()
      this.unbindHotkeys()

      // trigger modalExit only after this view has stopped listening for events
      app.trigger('modalExit', this.cid)

      setTimeout(function () {
        if (view.childView) {
          view.childView.remove()
        }
        view.remove()
      }, 500)
    },

    onModalEnter: function (cid) {
      if (cid === this.cid) return // don't respond to own events
      this.blur()
    },

    onModalExit: function (cid) {
      if (cid === this.cid) return // don't respond to own events
      this.focus()
    },

    blur: function () {
      this.$el.addClass('blur')
    },

    focus: function () {
      this.$el.removeClass('blur')
    },

    bindHotkeys: function () {
      this.$window = $(window)
      this.$window.bind('keydown', this.boundGlobalKeypress)
    },

    unbindHotkeys: function () {
      this.$window && this.$window.unbind('keydown', this.boundGlobalKeypress)
    },

    globalKeypress: function (e) {
      if (e.keyCode === 27) { // esc
        if (!this.force) {
          this.exit()
        }
      }
    },

    renderChildView: function () {
      this.childView = new this.ChildView(this.options)
      this.$content.append(this.childView.render().el)
    },

    render: function () {
      var view = this

      var attrs = this.model
        ? this.model.attrs ? this.model.attrs() : this.model.toJSON()
        : {}

      this.$el.html(this.template(attrs))
      $('.app').append(this.$el)

      this.$content = this.$('.content')

      _.defer(function () {
        view.enter()
      })

      if (this.ChildView) {
        this.renderChildView()
      }

      return this
    }

  })
})()
