/* globals zc _ Backbone app analytics utils */

(function () {
  'use strict'

  zc.views.RecorderView = Backbone.View.extend({
    initialize: function () {
      _.bindAll(this, 'sampleBuffer')
      this.listenTo(this.model, 'change:micArmed', this.micArmedChange)
      this.listenTo(this.model, 'change:allArmed', this.allArmedChange)
      this.listenTo(this.model.recording, 'change:allUploaded', this.allUploadedChange)
      this.listenTo(this.model, 'change:isRecording', this.isRecordingChange)
      this.listenTo(this.model, 'change:paused', this.pausedChange)
      this.listenTo(this.model, 'loading:postproduction', this.showPostproductionLoading)
      this.listenTo(this.model, 'doneLoading:postproduction', this.hidePostproductionLoading)
      this.listenTo(this.model, 'enableControls', this.enableControls)
      this.listenTo(this.model, 'disableControls', this.disableControls)
      this.listenTo(this.model, 'change:recording', this.render)
      // this.listenTo(app.user, 'change:handRaised', this.handRaisedChange);
      this.listenTo(this.model.call, 'showAccessing', this.showAccessingMediaDevices)
      this.listenTo(this.model.call, 'hideAccessing', this.hideAccessingMediaDevices)
      this.listenTo(this.model.call, 'samplePlaybackDestinationReady', this.samplePlaybackDestinationReady)
      // this.listenTo(this.model, 'change:recording', this.handleRecordingChange);
      // this.listenTo(this.model, 'change:buffer', this.drawBuffer);
      // _.bindAll(this, 'drawAnalyser');

      _.bindAll(this, 'handleHotkeyKeypress')
      $(window).bind('keypress', this.handleHotkeyKeypress)
    },

    template: _.template($('.recorder-template').html()),

    events: {
      'click .record': 'toggleRecord',
      'click .new-recording': 'startOver',
      'click .start-over': 'startOver',
      'click .pause': 'togglePause',
      'change .defer-uploads-input': 'deferUploadsChange',
      'click .postproduction': 'renderPostproductionConfig',
      'click .invite': 'showInviteLink',
      // 'click .invite-link-wrapper input': 'showInviteLink',
      // 'click .invite-link-wrapper .close': 'hideInviteLink',
      'click .add-footnote': 'addFootnoteClick',
      'click .activate-mic': 'activateMicClick',
      // 'click .raise-hand': 'toggleHandRaised',
      'click .audio-settings': 'renderAudioSettings',
      'click .toggle-voip': 'toggleVoip'
    },

    // toggleHandRaised: function () {
    //   app.user.set({handRaised: !app.user.get('handRaised')});
    // },

    // handRaisedChange: function (user, handRaised) {
    //   if (handRaised) {
    //     this.$raiseHand.addClass('hand-raised');
    //   } else {
    //     this.$raiseHand.removeClass('hand-raised');
    //   }
    // },

    toggleVoip: function () {
      var $text = this.$toggleVoip.find('.text-box')
      if (_.keys(this.model.call.peers).length) {
        this.model.call.stop()
        $text.text('Restart Call')
      } else {
        this.model.call.start().catch(utils.notifyError)
        $text.text('Hang Up Call')
      }
    },

    addFootnoteClick: function (e) {
      e.preventDefault()

      this.hideInviteLink(e)
      this.renderFootnoteForm()
    },

    hideFootnoteForm: function () {
      if (this.footnoteFormView) {
        this.footnoteFormView.exit()
      }
    },

    activateMicClick: function (e) {
      e.preventDefault()
      this.model.call.getLocalStream()
    },

    handleHotkeyKeypress: function (e) {
      if (e.keyCode === 102) { // f hotkey
        this.renderFootnoteForm()
      }
    },

    showInviteLink: function () {
      var inviteLinkModalView = new zc.views.ModalView({
        addClass: 'invite-link-modal',
        ChildView: zc.views.InviteFormView,
        // model: new Backbone.Model(),
        collection: new zc.collections.Invites([{}, {}, {}]),
        callback: function () {
          inviteLinkModalView.exit()
        }
      })
      inviteLinkModalView.render()
    },

    hideInviteLink: function (e) {
      e.preventDefault()
      this.$inviteLinkWrapper.removeClass('visible').hide()
    },

    deferUploadsChange: function (e) {
      if (e.target.checked) {
        this.model.project.set({deferUploads: true})
      } else if (this.model.get('isRecording') || this.model.buffer.chunks.length) {
        e.target.checked = true
         // you can't turn off deferred uploads if you have started recording already
      } else {
        this.model.project.set({deferUploads: false})
      }
    },

    createPostproduction: function (args) {
      var self = this

      this.model.trigger('loading:postproduction')
      this.model.recording.createPostproduction(args, function (err, postproduction) {
        self.model.trigger('doneLoading:postproduction')
        if (!err && postproduction) {
          // self.$postproduction.fadeOut();

          analytics.track('Created Postproduction', {
            recordingId: self.model.recording.id,
            projectName: self.model.project.get('name'),
            projectId: self.model.project.id,
            credits: args.credits
          })
        }
      })
    },

    showPostproductionLoading: function () {
      this.$postproduction.addClass('loading')
    },

    hidePostproductionLoading: function () {
      this.$postproduction.removeClass('loading')
    },

    micArmedChange: function (model, micArmed) {
      if (micArmed) {
        // this.enableControls();
        this.showRecordingVisuals()
      } else {
        // this.disableControls();
        this.hideRecordingVisuals()
      }
    },

    allArmedChange: function (model, allArmed) {
      var recorder = this.model
      if (allArmed) {
        this.hideWaitingForMics()
      } else if (!recorder.get('isRecording')) {
        this.showWaitingForMics()
      } else if (recorder.hasFinishedRecording()) {
        this.$startOver.show()
      }
    },

    allUploadedChange: function (model, allUploaded) {
      if (allUploaded) {
        this.allowDownload()
        this.allowPostproduction()
      }
    },

    showWaitingForMics: function () {
      this.$controls.addClass('waiting-for-mics')
    },

    hideWaitingForMics: function () {
      this.$controls.removeClass('waiting-for-mics')
    },

    toggleRecord: function (e) {
      e.preventDefault()
      var self = this
      var isRecording = !this.model.get('isRecording')

      if (isRecording) {
        console.log('Clicked "Start recording"')
        var allHealthChecksStatus = self.model.checkAllUsersHealthCheckStatus()
        var passable = (allHealthChecksStatus === 'passed' || allHealthChecksStatus === 'warning')
        if (!passable) {
          if (allHealthChecksStatus === 'pending') {
            return utils.notify('alert', 'Please wait until all health checks have completed before starting a recording', {ttl: 3000})
          } else {
            return utils.notify('error', 'Unable to start a recording with failed health checks')
          }
        }
        // if (!this.model.project.lobby.allMicsArmed()) {
        //   utils.notify('alert', 'Can\'t start recording. Not all participants microphones are armed.');
        //   return;
        // }

        if (!app.socket.connected) {
          utils.notify('error', 'Error: You cannot begin a recording if you are disconnected from the server.')
          return
        }

        if (this.model.recording.id) {
          self.model.set({isRecording: isRecording})
        } else {
          this.model.createRecording(function (err, recordingId) {
            if (err) {
              utils.notify('error', 'Error creating recording: ' + err)
            } else {
              self.model.set({isRecording: isRecording})
            }
          })
        }
      } else {
        console.log('Clicked "Stop recording"')
        this.model.set({isRecording: isRecording})
      }
    },

    isRecordingChange: function (model, isRecording) {
      if (isRecording) {
        // if (this.inFinishedState) return this.render() // if a user joins late they will initial render a finished state - rerender to start fresh
        this.startedRecording()
      } else {
        this.stoppedRecording()
      }
    },

    stopRecording: function (e) {
      e.preventDefault()
      this.model.set({isRecording: false})
    },

    startedRecording: function () {
      this.showRecordingState()
      this.timerView.startPollingTimer()
      analytics.track('Started Recording', {
        projectId: app.location.id,
        projectName: app.location.get('name'),
        recordingId: app.location.recorder.recording.id
      })
      this.startRecordingAnalyticsInterval()
    },

    startRecordingAnalyticsInterval: function () {
      var self = this
      this.recordingAnalyticsInterval = setInterval(function () {
        if (app.location.recorder) {
          analytics.track('Recording In Progress', {
            projectId: app.location.id,
            projectName: app.location.get('name'),
            recordingId: app.location.recorder.recording.id,
            duration: self.model.timer.duration()
          })
        } else {
          self.stopRecordingAnalyticsInterval()
        }
      }, 1000 * 60 * 5) // Every five minutes.  This keeps sessions alive for real-time analytics.
    },

    stopRecordingAnalyticsInterval: function () {
      this.recordingAnalyticsInterval && clearInterval(this.recordingAnalyticsInterval)
    },

    stoppedRecording: function () {
      this.showFinishedState()
      this.timerView.stopPollingTimer()
      analytics.track('Completed Recording', {
        projectId: app.location.id,
        projectName: app.location.get('name'),
        recordingId: app.location.recorder.recording.id,
        duration: this.model.timer.duration()
      })
      this.stopRecordingAnalyticsInterval()
    },

    showRecordingState: function () {
      this.$el.removeClass('finished')
      this.$el.addClass('is-recording')
      this.$audioSettings.addClass('disabled')
      this.$stop.removeClass('disabled')
      this.$pause.removeClass('disabled')
      this.$pause.removeClass('paused')
      this.$inviteLinkWrapper.removeClass('visible')
      // this.$pause.css({display: 'inline-block'});
    },

    showPausedState: function () {
      this.$pause.addClass('paused')
      this.$el.removeClass('is-recording')
    },

    showFinishedState: function () {
      this.inFinishedState = true
      this.$el.removeClass('is-recording')
      this.$el.addClass('finished')
      this.$audioSettings.removeClass('disabled')
      this.$invite.hide()
      this.$toggleVoip.show()
      this.allowDownload()
      this.$addFootnote.hide()
      // this.hideRecordingVisuals();
      // this.hideFirstRow();
      // this.disallowRecording();
      // this.disableControls();
      // this.$audioSettings.hide();

      if (this.model.recording.canAllowPostproduction()) {
        this.$postproduction.show()
      }
    },

    togglePause: function (e) {
      e.preventDefault()
      this.model.set({paused: !this.model.get('paused')})
    },

    pausedChange: function (model, paused) {
      if (paused) {
        this.timerView.stopPollingTimer()
        this.showPausedState()
      } else {
        this.timerView.startPollingTimer()
        this.showRecordingState()
      }
    },

    startOver: function (e) {
      e.preventDefault()
      var recordings = this.model.project.recordings
      // get the last recording and see if that one has not started
      var createNewRecording = !recordings.at(recordings.length - 1).hasNotStarted()
      this.model.startOver(createNewRecording)
    },

    hideFirstRow: function () {
      var firstRowHeight = this.$firstRowTools.outerHeight()
      this.$firstRowTools.css({'margin-top': -firstRowHeight})
    },

    showRecordingVisuals: function () {
      // this.recordAnalyserView.resizeCanvas();
      this.$recordingVisuals.css({opacity: 1})
    },

    hideRecordingVisuals: function () {
      this.$recordingVisuals.css({opacity: 0})
    },

    play: function () {
      this.model.set({playing: true})
    },

    allowRecording: function () {
      this.enableControls()
      this.$notRecording.show()
    },

    disallowRecording: function () {
      this.$record.addClass('disabled')
      // this.disableControls();
    },

    allowDownload: function () {
      this.$viewOnCloudDrive.show()
    },

    allowPostproduction: function () {
      this.$postproduction.css({display: 'block', opacity: 0})
      this.$postproduction.animate({opacity: 1})
    },

    // handleMicChange: function (model, micArmed) {
    //   if (micArmed) {
    //     this.drawAnalyser();
    //   } else {
    //     navigator.cancelAnimationFrame(this.analyserRAFID);
    //   }
    // },

    handleIsRecordingChange: function (model, isRecording) {
      if (isRecording) {
        this.sampleBuffer()
      } else {
        navigator.cancelAnimationFrame(this.sampleBufferRAFID)
      }
    },
    //
    // drawAnalyser: function () {
    //   var analyserNode = this.model.analyserNode;
    //   var canvas = this.freqAnalyserCanvas;
    //   var canvasWidth = canvas.width;
    //   var canvasHeight = canvas.height;
    //
    //   // analyzer draw code here
    //   var SPACING = 3;
    //   var BAR_WIDTH = 1;
    //   var numBars = Math.round(canvasWidth / SPACING);
    //   var freqByteData = new Uint8Array(analyserNode.frequencyBinCount);
    //
    //   this.model.analyserNode.getByteFrequencyData(freqByteData);
    //
    //   this.freqAnalyserContext.clearRect(0, 0, canvasWidth, canvasHeight);
    //   this.freqAnalyserContext.fillStyle = '#F6D565';
    //   this.freqAnalyserContext.lineCap = 'round';
    //   var multiplier = analyserNode.frequencyBinCount / numBars;
    //
    //   // Draw rectangle for each frequency bin.
    //   for (var i = 0; i < numBars; ++i) {
    //     var magnitude = 0;
    //     var offset = Math.floor( i * multiplier );
    //     // gotta sum/average the block, or we miss narrow-bandwidth spikes
    //     for (var j = 0; j< multiplier; j++)
    //         magnitude += freqByteData[offset + j];
    //     magnitude = magnitude / multiplier;
    //     var magnitude2 = freqByteData[i * multiplier];
    //     this.freqAnalyserContext.fillStyle = "hsl( " + Math.round((i*360)/numBars) + ", 100%, 50%)";
    //     this.freqAnalyserContext.fillRect(i * SPACING, canvasHeight, BAR_WIDTH, -magnitude);
    //   }
    //
    //
    //   this.analyserRAFID = window.requestAnimationFrame(this.drawAnalyser);
    // },
    //
    sampleBuffer: function () {
      var view = this
      this.model._recorder.getBuffer(function (buffer) {
        view.model.buffer.set({bufferData: buffer})
        console.log('buffer sampled', buffer[0][buffer[0].length - 1])
      })

      this.sampleBufferRAFID = window.requestAnimationFrame(this.sampleBuffer)
    },

    // drawWaveform: function (model, buffer) {
    //   var data = buffer[0];
    //   var canvas = this.$oscopeCanvas[0];
    //   var ctx = canvas.getContext('2d');
    //   var height = canvas.height;
    //   var width = canvas.width;
    //   var step = Math.ceil(data.length / width);
    //   var amp = height / 2;
    //   ctx.fillStyle = "silver";
    //   ctx.clearRect(0, 0, width, height);
    //   for(var i=0; i < width; i++){
    //     var min = 1.0;
    //     var max = -1.0;
    //     for (var j=0; j<step; j++) {
    //       var datum = data[(i*step)+j];
    //       if (datum < min)
    //         min = datum;
    //       if (datum > max)
    //         max = datum;
    //     }
    //     ctx.fillRect(i,(1+min)*amp,1,Math.max(1,(max-min)*amp));
    //   }
    // },

    enableControls: function () {
      this.$controls.removeClass('disabled')
    },

    disableControls: function () {
      this.$controls.addClass('enabled')
    },

    setRoleClass: function () {
      if (app.user.isHost()) {
        this.$el.addClass('host')
      } else {
        this.$el.addClass('guest')
      }
    },

    showAccessingMediaDevices: function () {
      var self = this
      // if we are already displaying the popup, ignore subsequent calls
      if (this.accessingMediaDevicesModal) return

      this.accessingMediaDevicesModal = new zc.views.ModalView({
        addClass: 'accessing-media-devices-modal',
        model: this.model.recorder,
        force: true,
        ChildView: zc.views.AccessingMediaDevicesView
      })

      self.accessingMediaDevicesModal.render()
      // this.showAccessingMediaTimeout = setTimeout(function () {
      // }, 500)
    },

    hideAccessingMediaDevices: function () {
      // clearTimeout(this.showAccessingMediaTimeout)

      // only call exit if the modal has been previously rendered
      if (this.accessingMediaDevicesModal) {
        this.accessingMediaDevicesModal.exit()
        this.accessingMediaDevicesModal = null
      }
    },

    renderPostproductionConfig: function () {
      var view = this
      this.postproductionConfigModal = new zc.views.ModalView({
        addClass: 'postproduction-config-modal',
        model: view.model.recording,
        ChildView: zc.views.PostproductionConfigView,
        callback: function (args) {
          view.postproductionConfigModal.exit()
          view.createPostproduction(args)
        }
      })
      this.postproductionConfigModal.render()
    },

    renderFootnoteForm: function () {
      var footnoteFormModalView = new zc.views.ModalView({
        addClass: 'footnote-form-modal',
        ChildView: zc.views.FootnoteFormView,
        model: this.model,
        callback: function () {
          footnoteFormModalView.exit()
        }
      })
      footnoteFormModalView.render()

      // this.footnoteFormView = new zc.views.FootnoteFormView({model: this.model});
      // this.$actions.append(this.footnoteFormView.render().el);
    },

    renderAudioSettings: function () {
      console.log('Opened settings modal')
      // this.$audioSettings.find('.icon').css({transform: 'rotate(-180deg)'});
      this.audioSettingsModal = new zc.views.ModalView({
        addClass: 'audio-settings-modal',
        model: this.model,
        settings: app.user.settings,
        callback: this.audioSettingsCallback.bind(this),
        ChildView: zc.views.AudioSettingsView
      })

      this.audioSettingsModal.render()
    },

    audioSettingsCallback: function () {
      console.log('Closed settings modal')
      this.audioSettingsModal.exit()
    },

    renderDuration: function () {
      this.timerView = new zc.views.TimerView({model: this.model.timer, el: this.$timer})
      this.timerView.render()
    },

    renderRecordAnalyser: function () {
      this.recordAnalyserView = new zc.views.RecordAnalyserView({model: this.model, el: this.$oscope})
      this.recordAnalyserView.render()
    },

    renderFrequencyAnalyser: function () {
      var frequencyAnalyserView = new zc.views.FrequencyAnalyserView({model: this.model, el: this.$frequencyAnalyser})
      frequencyAnalyserView.render()
    },

    renderSoundboard: function () {
      this.soundboardView = new zc.views.SoundboardView({
        el: this.$soundboard,
        collection: this.model.project.soundboardSamples
      })

      this.soundboardView.render()
    },

    render: function () {
      var attrs = this.model.attrs()
      this.$el.html(this.template(attrs))
      this.$audioSettings = this.$('.audio-settings')
      this.$recordingVisuals = this.$('.recording-visuals')
      this.$oscope = this.$('.oscilloscope')
      this.$frequencyAnalyser = this.$('.frequency-analyser')
      this.$controls = this.$('.controls')
      this.$invite = this.$('.invite')
      this.$actions = this.$('.actions')
      this.$startOver = this.$('.start-over')
      this.$record = this.$('.record')
      this.$pause = this.$('.pause')
      this.$stop = this.$('.stop')
      this.$resume = this.$('.resume')
      this.$viewOnCloudDrive = this.$('.view-on-cloud-drive')
      this.$timer = this.$('.timer')
      this.$postproduction = this.$('.postproduction')
      this.$inviteLinkWrapper = this.$('.invite-link-wrapper')
      this.$addFootnote = this.$('.add-footnote')
      this.$inviteLink = this.$('.invite-link')
      this.$firstRowTools = this.$('.top-tools .first-row')
      this.$raiseHand = this.$('.raise-hand')
      this.$soundboard = this.$('.soundboard')
      this.$toggleVoip = this.$('.toggle-voip')

      this.renderDuration()
      // this.renderRecordAnalyser();
      // this.renderAudioSettings();

      // this.model.mediaDevices.fetch()

      // this.renderFrequencyAnalyser();

      if (app.user.isHost() && app.user.getFeature('soundboard') && app.user.settings.get('soundboard')) {
        this.renderSoundboard()
      }

      this.showRecordingVisuals()

      this.setRoleClass()

      // removed finished class from root element
      // if we should continue to show the finished state
      // it will be added again below
      this.$el.removeClass('finished')
      if (this.model.hasFinishedRecording()) {
        this.showFinishedState()
      }

      if (this.model.get('micArmed')) {
        this.showRecordingVisuals()
      }

      return this
    }

  })
})()
