/* globals zc _ utils Backbone VL analytics */

(function () {
  'use strict'
  var WaitListModel = Backbone.Model.extend({
    defaults: {
      firstname: null,
      lastname: null,
      leaderboard: null,
      rank: null,
      isMobile: false
    }
  })
  zc.views.WaitlistView = zc.views.BaseView.extend({
    initialize: function (options) {
      options = options || {}
      _.bindAll(this, 'onWaitlistClosed')
      this.cookieOnClose = options.cookieOnClose || false
      var attrs = {
        isMobile: utils.isMobile()
      }
      this.model = new WaitListModel(attrs)
    },

    template: _.template($('.waitlist-template').html()),

    className: 'waitlist takeover',

    events: {
      'submit form': 'onFormSubmit',
      'click .dismiss': 'dismiss',
      'click .close': 'dismiss',
      'click .invite': 'openShareModal'
    },

    setModel: function () {
      var attrs = (VL.options && VL.options.lead) ? Object.assign({}, VL.options.lead) : {}
      attrs.isMobile = utils.isMobile()
      this.model = new WaitListModel(attrs)
      this.render()
    },

    show: function () {
      this.setModel()
      this.renderMovingBackground()
      $('.app').addClass('waitlist-open')
      $(document.body).delegate('.vl-close-btn', 'click', this.onWaitlistClosed)
      this.$el.addClass('shown')
      this.updateBg()
    },

    hide: function () {
      this.$submit.find('.signup').show()
      this.$submit.find('.loader').removeClass('show')
      $('.app').removeClass('waitlist-open')
      $(document.body).undelegate('.vl-close-btn', 'click', this.onWaitlistClosed)
      this.movingBgView.stopAnimation()
      this.$el.removeClass('shown')
      if (this.cookieOnClose) {
        utils.setCookie(['waitlist_seen_recording', true])
      }
    },

    onFormSubmit: function (e) {
      e.preventDefault()

      var self = this
      this.$submit.find('.signup').hide()
      this.$submit.find('.loader').addClass('show')
      VL.options.form_fields.form_firstName = this.$firstName.val()
      VL.options.form_fields.form_email = this.$email.val()
      VL.options.form_fields.form_lastName = this.$lastName.val()
      VL.createLead(function () {
        VL.openModal() // open the share modal
        analytics.track('Waitlist Signup', {
          email: self.$email.val()
        })
        utils.setCookie(['in_waitlist', true])
      })
    },

    dismiss: function (e) {
      e.preventDefault()
      this.hide()
    },

    onWaitlistClosed: function (e) {
      this.hide()
    },

    openShareModal: function (e) {
      e.preventDefault()
      VL.openModal()
    },

    renderMovingBackground: function () {
      this.movingBgView = new zc.views.MovingBackgroundView({model: new Backbone.Model({})})
      this.movingBgView.render()
      this.movingBgView.startAnimation()
      this.$el.append(this.movingBgView.$el)
    },

    updateBg: function () {
      var height = this.$el.find('.content').get(0).offsetHeight + 200
      var elHeight = this.$el.height()
      if (height > elHeight) {
        this.$el.find('.takeover').height(height)
      }
    },

    render: function () {
      this._render()
      this.$firstName = this.$('#first_name')
      this.$lastName = this.$('#last_name')
      this.$email = this.$('#email')
      this.$submit = this.$('button')
    }
  })
})()
