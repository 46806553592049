/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.FootnoteView = Backbone.View.extend({
    template: _.template($('.footnote-template').html()),

    className: 'footnote',

    enter: function () {
      this.$el.css({top: 0, opacity: 1})
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))

      _.defer(function () {
        // view.enter();
      })
      return this
    }
  })
})()
