/* globals zc Backbone app utils _ */

(function () {
  'use strict'

  var logMediaDevices = function (devices) {
    var text = ''
    devices.forEach(function (device) {
      text += device.kind + ' ' + device.label + ' ' + device.deviceId + '\n'
    })
    console.log('Devices for user', app.user.get('displayName'))
    console.log(text)
  }

  zc.collections.MediaDevices = Backbone.Collection.extend({
    initialize: function () {
      var self = this

      // sometimes a user can plug/unplug the currently used output device (which governs audioContex.sampleRate)
      // if this happens, and the new output device has a different sample rate, there can be distortion in the input and output streams
      // so we need to listen for device changes event and then update our devices collection as well as check that the sample rate hasn't changed
      navigator.mediaDevices.ondevicechange = function (e) {
        console.log('Media devices change detected')
        var bustPromiseCache = true
        self.fetch(bustPromiseCache).then(function () {
          utils.audio.checkForSampleRateMismatch(app.actx.sampleRate)
        })
      }

      // Check for changes in media devices and log which ones change
      self.on('reset', function (devices, options) {
        var prevDevices = options.previousModels.map(function (d) { return d.toJSON() })
        devices = devices.map(function (d) { return d.toJSON() })

        // ignore all new devices the first time
        if (!prevDevices.length) return

        var removedDevices = _.filter(prevDevices, function (prevDevice) {
          return !_.findWhere(devices, {groupId: prevDevice.groupId})
        })

        var addedDevices = _.filter(devices, function (device) {
          return !_.findWhere(prevDevices, {groupId: device.groupId})
        })

        if (removedDevices.length) {
          self.trigger('devicesRemoved', removedDevices)
          console.log('User', app.user.get('displayName'), 'removed a device', removedDevices)
        }

        if (addedDevices.length) {
          self.trigger('devicesAdded', addedDevices)
          console.log('User', app.user.get('displayName'), 'added a device', addedDevices)
        }
      })
    },

    fetch: function (bustPromiseCache) {
      var coll = this
      if (this.fetchPromise && !bustPromiseCache) return this.fetchPromise

      this.fetchPromise = navigator.mediaDevices.enumerateDevices().then(function (devices) {
        var devicesJson = devices.map(function (d) { return d.toJSON() })
        coll.reset(devicesJson)
        return devices
      })

      return this.fetchPromise
    },

    getByDeviceId: function (id) {
      return this.findWhere({deviceId: id})
    },

    getInputDeviceById: function (id, kind) {
      kind = kind || 'audioinput'
      return this.findWhere({deviceId: id, kind: kind})
    },

    getOutputDeviceById: function (id, kind) {
      kind = kind || 'audiooutput'
      return this.findWhere({deviceId: id, kind: kind})
    },

    getInputDevices: function (kind) {
      kind = kind || 'audioinput'
      return new Backbone.Collection(this.where({kind: kind}))
    },

    getOutputDevices: function (kind) {
      kind = kind || 'audiooutput'
      return new Backbone.Collection(this.where({kind: kind}))
    },

    getDefaultInputDevice: function (kind) {
      kind = kind || 'audioinput'

      return this.getInputDeviceById('default', kind) || this.findWhere({kind: kind})
    },

    getDefaultOutputDevice: function (kind) {
      kind = kind || 'audiooutput'
      return this.getOutputDeviceById('default', kind) || this.findWhere({kind: kind})
    },

    getPreferredDevices: function () {
      var self = this
      var preferred = {}
      return this.fetch().then(function (mediaDevices) {
        // DEBUGGING FOR MISSING DEFAULT AUDIO DEVICE ON WINDOWS MACHINES
        logMediaDevices(self.toJSON())

        if (!mediaDevices.length) throw new Error('Unable to find any connected media devices')

        // check for pre-selected audio input device
        var defaultAudioInput = self.getDefaultInputDevice('audioinput')
        var audioInputIds = self.getInputDevices('audioinput').pluck('deviceId')
        var storedAudioInputId = localStorage.getItem('audioInputId')
        // if there are not input devices
        if (audioInputIds.indexOf(storedAudioInputId) === -1 && !defaultAudioInput) {
          throw new Error('There seems to be no microphone connected')
        }
        var audioInputId = (audioInputIds.indexOf(storedAudioInputId) > -1) ? storedAudioInputId : defaultAudioInput.get('deviceId')
        var audioInput = self.getInputDeviceById(audioInputId)
        preferred.audioInput = audioInput

        var outputDevices = self.getOutputDevices('audiooutput')

        // firefox doesn't support enumerating output devices yet
        if (outputDevices.length) {
          // check for pre-selected audio output device
          var defaultAudioOutput = self.getDefaultOutputDevice('audiooutput')
          var audioOutputIds = self.getOutputDevices('audiooutput').pluck('deviceId')
          var storedAudioOutputId = localStorage.getItem('audioOutputId')
          var audioOutputId = (audioOutputIds.indexOf(storedAudioOutputId) > -1) ? storedAudioOutputId : defaultAudioOutput.get('deviceId')
          var audioOutput = self.getOutputDeviceById(audioOutputId)
          preferred.audioOutput = audioOutput
        }

        return preferred
      })
    }
  })
})()
