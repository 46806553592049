/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.VoipSettingView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model, 'change:hostVoip', this.updateVoipSelection)
    },

    template: _.template($('.voip-setting-template').html()),

    className: 'voip-setting',

    events: {
      'change .voip-setting-select': 'voipSelectChange'
    },

    updateVoipSelection: function () {
      var hostVoip = this.model.get('hostVoip')
      this.$('.voip-setting-select option[value="' + hostVoip + '"]').attr('selected', true)
    },

    voipSelectChange: function (e) {
      this.model.set({hostVoip: JSON.parse(e.target.value)})
      console.log('Changed voip setting: ', e.target.value ? 'on' : 'off')
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.updateVoipSelection()

      return this
    }
  })
})()
