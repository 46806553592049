/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.LocalStorageInfoView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model, 'change', this.render)
    },

    template: _.template($('.local-storage-info-template').html()),

    alertTemplate: _.template($('.local-storage-alert-template').html()),

    hideLoading: function () {
      this.$el.removeClass('loading')
    },

    setAlertLevelClass: function (alertLevelName) {
      var self = this

      // remove all alert level classes
      this.model.alertLevelNames.forEach(function (name) {
        self.$el.removeClass(name)
      })

      // add new class
      this.$el.addClass(alertLevelName)
    },

    checkSupport: function (attrs) {
      if (attrs.total === -1 && attrs.used === -1 && attrs.free === -1) {
        this.$el.addClass('unsupported')
      } else {
        this.$el.removeClass('unsupported')
      }
    },

    renderAlert: function (attrs) {
      this.setAlertLevelClass(attrs.alertLevelName)
      this.$alert.html(this.alertTemplate(attrs))
    },

    render: function () {
      var attrs = this.model.attrs()
      this.$el.html(this.template(attrs))
      this.$alert = this.$('.alert')

      this.checkSupport(attrs)

      if (attrs.alertLevel) {
        this.renderAlert(attrs)
      }

      if (attrs.quota) {
        this.hideLoading()
      }

      return this
    }
  })
})()
