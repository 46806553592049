/* globals servars zc _ ua utils Backbone app debug */

(function () {
  'use strict'

  var dbg = debug('zc:recorder')

  zc.models.Recorder = Backbone.Model.extend({
    initialize: function (attrs, options) {
      dbg('Initializing recorder')

      this.actx = options.actx
      this.project = options.project
      this.socket = options.socket
      this.call = this.project.call
      this.mediaDevices = this.call.mediaDevices
      this.recording = attrs.recording
      this.timer = new zc.models.Timer({duration: attrs.recording.get('duration')})
      this.buffer = new zc.models.Buffer({_id: app.user.id}, {actx: this.actx})

      this.healthCheckManager = new zc.models.HealthCheckManager({}, {app: app, project: this.project, recorder: this})

      // this.recAnalyser = new zc.models.RecordAnalyser({}, {actx: this.actx})
      this.analyserNode = this.actx.createAnalyser()
      // this.recAnalyser.analyserNode = this.analyserNode
      this.monitorGain = this.actx.createGain()
      this.monitorGain.gain.value = this.get('monitorGain')
      this.compressor = this.actx.createDynamicsCompressor()

      // dry input without compression or other effects
      this.input = this.actx.createGain()

      this.input.channelCountMode = 'explicit'
      this.input.channelInterpretation = 'speakers'
      this.input.channelCount = 1

      this.input.connect(this.analyserNode) // data viz
      this.input.connect(app.outgoingVoipStreamDestination) // to remote participants

      // if the user is using firefox, we need to handle this a bit differently
      this._pcmMimeType = 'audio/webm;codecs=pcm'
      this._webmPcmSupport = MediaRecorder.isTypeSupported(this._pcmMimeType)

      // Timestamps used to know when the media recorder has started/stopped
      // used to verify that we've got the correct number of samples
      this.stats = {
        'microphone': {
          // helps use know how much delay was added when a mic was unplugged
          'delay': 0,
          'start': null,
          'stop': []
        },
        'soundboard': {
          'delay': 0,
          'start': null,
          'stop': []
        }
      }

      /**
       * Manually set to true to start saving the webn files for input
       * should only be used in DEV
       * @type {Boolean}
       */
      this.debugWebmFiles = false
      this._debugWebmData = {}

      /**
       * Used to tel MediaRecorder how long do we want to webm chunks for the mic
       * @type {Number}
       */
      this._microphoneMediarecorderChunkSize = 100
      /**
       * Used to tel MediaRecorder how long do we want to webm chunks for the soundboard
       * @type {Number}
       */
      this._soundboardMediarecorderChunkSize = 190

      this.on('change:isRecording', this.isRecordingChange)
      this.on('change:recording', this.recordingChange)

      this.on('change:paused', this.pausedChange)
      this.on('change:monitor', this.monitorChange)
      this.on('change:monitorGain', this.monitorGainChange)
      this.on('change:deferUploads', this.deferUploadsChange)
      this.listenTo(this.call.audioInput, 'change', this.audioInputChange)
      this.listenTo(this.call.audioOutput, 'change', this.audioOutputChange)
      this.listenTo(this.call, 'localStreamAdded', this.gotLocalStream)
      this.listenTo(this.call, 'trackEnded', this.trackEnded)
      this.listenTo(app.user, 'change:muted', this.mutedChange)
      this.listenTo(app.user.tracks, 'chunkUploaded', this.updateRecording)
      this.listenTo(app.user.tracks, 'change:finalized', this.trackFinalizedChange)
      // this.listenTo(app.user.tracks, 'change:uploading', this.trackUploadingChange)

      // prepareToRecord dependencies
      this.listenTo(app.user.tracks, 'add', this.prepareToRecordDependencyChange)
      this.listenTo(this.call, 'localStreamAdded', this.prepareToRecordDependencyChange)

      _.bindAll(this, 'gotLocalStream', 'startMicrophone')
    },

    defaults: {
      cloudDrive: null, // dropbox or google
      micArmed: false,
      micGain: 1,
      isRecording: false,
      deferUploads: false,
      recording: null,
      recordingSilence: false,
      duration: 0,
      allArmed: false,
      monitor: false,
      monitorGain: 1,
      ownerId: null
    },

    attrs: function () {
      var attrs = this.toJSON()
      attrs.slug = this.project.get('slug') + '/' + this.recording.getSlug()
      attrs.cloudDrive = attrs.recording.get('cloudDrive')
      if (attrs.cloudDrive) {
        attrs.cloudDriveLink = attrs.cloudDrive === 'dropbox' ? 'https://www.dropbox.com/home/Apps/zencastr/' + attrs.slug : 'https://drive.google.com/drive/my-drive'
      }
      attrs.projectName = this.project.get('name')
      attrs.finishedRecording = this.hasFinishedRecording()
      attrs.hostVoip = app.user.settings.get('hostVoip')
      attrs.voipSwitchClass = attrs.hostVoip ? 'on' : ''
      return attrs
    },

    /**
     * Start recording methods
     */
    startRecording: function () {
      // set recording state on tracks
      app.user.tracks.forEach(function (track) {
        var trackRecAttrs = {_id: track.id, recording: true, tentative: false}
        track.updateUpload(trackRecAttrs).catch(function (err) { console.error(err) }) // save non-tentative state to server - recording val isn't in db
        track.set(trackRecAttrs)
      })

      // this.compressor.connect(this.actx.destination) // for debugging so we can hear
      this.healthCheckManager.startMidRecordingHealthChecks()

      if (this.processor) {
        // if the user has soundboard on
        if (this.hasSoundboard) {
          // create a channel merger that will merge the streams
          // for both mic and soundboard
          this.merger = this.actx.createChannelMerger(2)

          this.input.connect(this.merger, 0, 0)
          this.project.soundboardOut.connect(this.merger, 0, 1)

          this.merger.connect(this.processor)
        } else {
          // if not soundboard, just connect the mic
          this.input.connect(this.processor)
        }

        this.outputNode = this.actx.createMediaStreamDestination()
        this.processor.connect(this.outputNode)
      }

      // start the mediaRecorder it exists
      if (this.mediaRecorder) {
        this.mediaRecorder.start(this._microphoneMediarecorderChunkSize)
        console.log('Started Recording with MediaRecorder State:  ', this.mediaRecorder.state)
      }

      if (this.soundboardMediaRecorder) {
        this.soundboardMediaRecorder.start(this._soundboardMediarecorderChunkSize)
      }

      // start record timer
      this.timer.start()

      // start getting the transcribed data for the audio only
      // @TODO: re-enable when we have the new platform server
      // this.webSpeechService.startRecording(Date.now(), this.recording.id)
    },

    checkHealth: function () {
      return this.healthCheckManager.preRecordingHealthCheck()
    },

    prepareToRecord: function (bustPromiseCache) {
      var self = this
      dbg('prepareToRecord called')

      // We may already be preparing or prepared to record
      // If so, just return the promise unless bustPromiseCache is true
      if (this.preparingPromise && !bustPromiseCache) return this.preparingPromise
      app.user.set('readyToRecord', false)

      // this function may need to be called again
      // with `bustPromiseCache === true` in order to
      // initialize new tracks that weren't present the
      // first time. So all the subsequent functions
      // need to be idempotent
      this.preparingPromise = utils.promiseSerial([
        this.initAudioContext.bind(this),
        this.initAudioRecordingPipeline.bind(this),
        this.initRecordingNode.bind(this),
        this.ensureAccessToLocalAudioStream.bind(this),
        this.createUserTracks.bind(this),
        this.initializeTracksForRecording.bind(this),
        this.getSampleRate.bind(this),
        this.checkHealth.bind(this)
      ]).then(function () {
        console.log('Ready to record', app.user.get('displayName'))
        app.user.set('readyToRecord', true)
      }).catch(function (err) {
        console.error(app.user.get('displayName'), 'Error preparing to record', err)
        return self.abortRecordingAndReportError(err)
      })

      return this.preparingPromise
    },

    initAudioContext: function () {
      var self = this
      dbg('initAudioContext')
      return new Promise(function (resolve) {
        if (app.actx.state === 'suspended') {
          self.once('audioContextResumed', resolve)
        } else {
          resolve()
        }
      })
    },

    /**
     * Used to either start the MediaRecorder or ScriptProcessorNode
     * deppending on browser and needs
     */
    initRecordingNode: function (stream) {
      dbg('Initializing media recorder')

      // if already initialized, return
      if (this.mediaRecorder || this.processor) return

      // create resampler worker
      try {
        if (this._webmPcmSupport) {
          // for all other cases use the MediaRecorder api
          this.initMediaRecorder(null, 'microphone')
          if (this.hasSoundboard) {
            this.initMediaRecorder(null, 'soundboard')
          }
        } else {
          this.initScriptProcessorNode()
        }
      } catch (err) {
        console.error('Exception while creating MediaRecorder: ' + err)
      }
    },

    initMediaRecorder: function (stream, tag) {
      var self = this

      // if we've received a stream, use that
      if (stream && stream.mediaStream) {
        stream = stream.mediaStream
      // if not, get the stream for each case
      // for voice, from call
      } else if (tag === 'microphone') {
        stream = this.call.localAudio.mediaStream
      // for soundboard from soundboardOut
      } else if (tag === 'soundboard') {
        this.merger = this.actx.createChannelMerger(2)

        this.project.soundboardOut.connect(this.merger, 0, 0)
        this.project.soundboardOut.connect(this.merger, 0, 1)

        var dest = this.actx.createMediaStreamDestination()
        this.merger.connect(dest)

        // this.project.soundboardOut.connect(dest)
        stream = dest.stream
      } else {
        console.error('Trying to create a MediaRecorder with invalid arguments', stream, tag)
        throw new Error('Invalid arguments for initMediaRecorder')
      }

      // make sure the stream is a MediaStream
      if (!(stream instanceof MediaStream)) {
        throw new Error('stream is not an instance of MediaStream')
      }

      var mimeType = 'audio/webm;codecs=pcm'
      var mediaRecorder = new MediaRecorder(stream, {mimeType: mimeType})
      console.log('Created MediaRecorder for', tag, 'with mime type', mimeType)
      mediaRecorder.ondataavailable = function (e) {
        self.audioPipeline.inputPort.postMessage({
          chunk: e.data,
          tag: tag
        })

        // if we have debug on, save all the webm chunks and download them at the end
        if (self.debugWebmFiles) {
          self._debugWebmData[tag] = self._debugWebmData[tag] ? new Blob([self._debugWebmData[tag], e.data]) : e.data
        }
      }
      mediaRecorder.onstart = function (e) {
        if (self._stopMediaReocrderTimestamp) {
          // if this is the second mediaRecorder, also fire `reinit`
          // we get the end timestamp of the previous media recorder as a resolve value
          self._stopMediaReocrderTimestamp.then(function (timestamp) {
            var delay = Date.now() - timestamp
            self.stats['microphone'].delay += delay

            console.warn('Reinitializing media recorder. Time delay to add (ms):', delay)
            // we need to restart just the microphone processor
            self.audioPipeline.inputPort.postMessage({
              reinit: true,
              tag: 'microphone',
              delay: delay
            })
          })
        } else {
          // start timestamp
          self.stats[tag].start = performance.now()
        }
      }
      mediaRecorder.onstop = function (e) {
        self.audioPipeline.inputPort.postMessage({
          isLast: true,
          tag: tag
        })
        self.stats[tag].stop[1] = performance.now()

        if (self.debugWebmFiles) {
          if (self._debugWebmData[tag]) {
            utils.forceDownload(self._debugWebmData[tag], tag + '.webm')
            self._debugWebmData[tag] = null
          }
        }
      }

      if (tag === 'microphone') {
        this.mediaRecorder = mediaRecorder
      } else if (tag === 'soundboard') {
        this.soundboardMediaRecorder = mediaRecorder
      }
    },

    initScriptProcessorNode: function () {
      var self = this
      console.log('Initializing script processor node')

      var inputChannels = this.hasSoundboard ? 2 : 1
      this.processor = this.actx.createScriptProcessor(16384, inputChannels, 1) // 16384 is max

      this.processor.onaudioprocess = function (e) {
        var buff = e.inputBuffer

        var message = {
          microphone: buff.getChannelData(0),
          sampleRate: buff.sampleRate
        }

        if (self.hasSoundboard) {
          message['soundboard'] = buff.getChannelData(1)
        }

        self.audioPipeline.inputPort.postMessage(message)

        if (!self.stats.microphone.start) {
          self.stats.microphone.start = self.stats.soundboard.start = performance.now()
        }

        // Fake output
        for (var channel = 0; channel < e.outputBuffer.numberOfChannels; channel++) {
          var outputData = e.outputBuffer.getChannelData(channel)
          for (var n = 0; n < outputData.length; n++) {
            outputData[n] = (n & 1) / 10000
          }
        }
      }
    },

    initAudioRecordingPipeline: function () {
      var self = this

      dbg('initAudioRecordingPipeline')

      if (this.initAudioRecordingPipelinePromise) return this.initAudioRecordingPipelinePromise

      this.initAudioRecordingPipelinePromise = new Promise(function (resolve, reject) {
        dbg('Initializing audio recording pipeline')

        window.fetch(servars.audioPipelineWorker, {method: 'GET'})
        .then(function (resp) {
          return resp.blob()
        }).then(function (blob) {
          // create resampler worker
          self.audioPipeline = new Worker(window.URL.createObjectURL(blob))

          // Create an input and output channel for the resampler pipeline.
          // The input port will be given to the audioWorkletNode or the
          // scriptProcessorNode depending on browser support.
          var pipelineInputChannel = new MessageChannel()
          self.audioPipeline.inputPort = pipelineInputChannel.port1

          self.audioPipeline.addEventListener('error', function (err) {
            console.error('Audio pipeline error:', err.message ? err.message : err)
          })

          self.audioPipeline.addEventListener('message', function (e) {
            var command = e.data.command

            switch (command) {
              case 'resamplerProfiles':
                app.user.tracks.forEach(function (track) {
                  track.set('resamplerProfile', e.data.profiles[0])
                })
                break
              case 'log':
                if (e.data.type === 'warn') {
                  console.warn(e.data.message)
                } else if (e.data.type === 'error') {
                  console.error(e.data.message)
                } else {
                  console.log(e.data.message)
                }
                break
              case 'lastChunkProcessed':
                var track = app.user.getTrack(e.data.format, e.data.type)
                if (track) {
                  track.set({lastChunkProcessed: true})
                }
                break
              case 'stats':
                var tag = e.data.tag

                var samplesDecoded = e.data.message.samples_decoded
                var samplesEncoded = e.data.message.samples_encoded
                var framesEncoded = e.data.message.frames_encoded
                var framesDecoded = e.data.message.frames_decoded

                var sampleRate = e.data.message.sampleRate

                var stopTimestamp = self.stats[tag].stop
                var expectedTime = (stopTimestamp[1] - self.stats[tag].start) / 1000
                var expectedSamples = expectedTime * sampleRate
                var recordedTime = samplesDecoded / sampleRate

                var args = [
                  'Recording end stats:',
                  // tag | start time | end 1 | end 2 |
                  tag,
                  self.stats[tag].start,
                  stopTimestamp[0],
                  stopTimestamp[1],
                  // expectedTime | recordedTime | mic delay added (if present)
                  expectedTime,
                  recordedTime,
                  self.stats[tag].delay,
                  // expected samples | samples decoded out of webm | samples encoded to from mp3 |
                  expectedSamples,
                  samplesDecoded,
                  samplesEncoded,
                  // frames decoded | frames encoded |
                  framesDecoded,
                  framesEncoded,
                  sampleRate,
                  // recordingId | browser | platform
                  self.recording.id,
                  ua.browser.name + ' ' + ua.browser.version,
                  ua.os.name
                ]
                console.log.apply(null, args)
                track = app.user.getTrack('mp3', tag)
                if (track) {
                  var data = Object.assign(e.data.message, {
                    startTimestamp: self.stats[tag].start,
                    stopTimestamp: stopTimestamp[1],
                    expectedTime: expectedTime,
                    recordedTime: recordedTime,
                    delay: self.stats[tag].delay,
                    expectedSamples: expectedSamples,
                    recordedSamples: samplesDecoded,
                    timeDifference: (recordedTime / expectedTime - 1) * 100,
                    sampleDifference: (samplesDecoded / expectedSamples - 1) * 100
                  })
                  track.saveDriftStats(data)
                  // when we receive the stats for the soundboard
                  if (tag === 'soundboard') {
                    // check if we had any content saved
                    var peak = data['lavfi.astats.Overall.Peak_level']
                    // if we have no peak for this track than nothing got recorded
                    if (peak) {
                      // else, we have content. finalize the track
                      track.set({lastChunkProcessed: true})
                      // add it to the view
                      self.showUserTrack(track)
                    } else {
                      // delete the track in the db
                      track.deleteUpload()
                      // and remove it from the collection
                      app.user.tracks.remove(track)
                    }
                  }
                }
                break
              case 'abort':
                // something went wrong with encoding and we need to abort the recording
                console.error(e.data.error)
                self.abortRecordingAndReportError('Error with encoding pipeline.')
                break
              case 'ready':
                // wait for the pipeline to initialize
                resolve()
                break
            }

            // only log commands that are not logs
            if (command !== 'log' && command !== 'stats') {
              console.log('Audio pipeline message:', e.data)
            }
          })
          // init worker
          var debugPipeline = !servars.bundleMedia
          self.audioPipeline.postMessage({
            command: 'init',
            config: {
              name: 'audioPipeline',
              debug: debugPipeline,
              // if pcm is not supported then we send raw data through script processor
              rawInput: !self._webmPcmSupport,
              rawOutput: app.user.settings.get('wavRecording'),
              recordSoundboard: self.hasSoundboard,
              mp3ChunkSize: 32768, // 32768 saving every 2s, 65536 every 4s
              rawChunkSize: 88200 * 2, // once per second
              upstreamPort: pipelineInputChannel.port2,
              commonScripts: servars.pipelineScripts.common,
              wasmLocation: servars.wasmLocation
            }
          }, [pipelineInputChannel.port2])
        })

        // listen to the users speech and transcribe it if we can (chrome only)
        // @TODO: re-enable when we have the new platform server
        // this.webSpeechService = new WebSpeech()
      })

      return this.initAudioRecordingPipelinePromise
    },

    /**
     * Stop recording methods
     */
    stopRecording: function () {
      this.endRecorderStream()

      if (this.project.soundboardSamples) {
        this.project.soundboardSamples.stopAll()
      }

      // stop listening for transcriptions
      // @TODO: re-enable when we have the new platform server
      // this.webSpeechService.stopRecording()

      this.healthCheckManager.stopMidRecordingHealthChecks()
    },

    endRecorderStream: function () {
      var self = this

      if (this.mediaRecorder) {
        if (this.mediaRecorder.state === 'recording') {
          this.mediaRecorder.stop()
          if (this.soundboardMediaRecorder) {
            this.soundboardMediaRecorder.stop()
          }

          this.stats.microphone.stop[0] = this.stats.soundboard.stop[0] = performance.now()

          dbg('MediaRecorder stopped and in state: ' + this.mediaRecorder.state)
        } else {
          console.error('MediaRecorder in unexpected state when stopping: ', this.mediaRecorder)
        }
      }

      // if we're using a ScriptProcessorNode
      if (this.processor) {
        if (this.merger) {
          this.merger.disconnect(this.processor)
        } else {
          this.input.disconnect(this.processor)
        }

        this.processor.disconnect(this.outputNode)
        this.audioPipeline.inputPort.postMessage({ isLast: true })
        this.stats.microphone.stop[1] = this.stats.soundboard.stop[1] = performance.now()
      }

      console.log('Stop recording. Timer:', this.timer.formattedDuration())
      this.timer.stop()

      app.user.tracks.forEach(function (track) {
        if (!track.get('error')) track.set({processing: true})

        // set track durations
        track.set({duration: self.timer.duration(), recordingSessionEnded: true})

        // don't add the soundboard to the view now
        if (track.get('type') === 'soundboard') return

        self.showUserTrack(track)
      })
    },

    checkAllUsersHealthCheckStatus: function () {
      return this.healthCheckManager.checkAllUsersStatus()
    },

    checkAllUsersHealthCheckPassed: function () {
      return this.healthCheckManager.checkAllPassed()
    },

    abortRecordingAndReportError: function (err) {
      var self = this
      app.user.trigger('initializationFailure', app.user, err.toString())
      utils.notify('error', 'Critical error appeared. Aborting now. Error Details: ' + err)
      dbg('Aborting recording')

      app.user.tracks.forEach(function (track) {
        self.set({initializedForRecording: false})
      })
    },

    /**
     * Change events
     */
    isRecordingChange: function (model, isRecording) {
      var self = this
      if (isRecording) {
        console.log('====> Started recording')
        utils.promiseSerial([
          this.prepareToRecord.bind(self),
          self.startRecording.bind(self)
        ]).catch(function (err) {
          self.abortRecordingAndReportError(err)
        })
      } else {
        console.log('=====> Stopping recording')
        this.stopRecording()
        this.updateRecording()
      }

      // pass the isRecording state down
      app.user.set({isRecording: isRecording})

      this.socket.emit('change:isRecording', {
        isRecording: isRecording,
        recordingId: this.recording.id,
        path: this.recording.getCloudFolder(),
        trackIds: app.user.tracks.pluck('_id'),
        user: app.user.toExtendedJSON()
      })
    },

    prepareToRecordDependencyChange: function () {
      var self = this
      var hasNotStartedRecording = this.recording.hasNotStarted()
      var hostIsPresent = !!this.project.lobby.getHost()
      var insideRePrepareToRecordWindow = hostIsPresent && hasNotStartedRecording

      // ideally we would initialize this inside initialize() but the app is not fully setup then so we do it here
      this.hasSoundboard = app.user.isHost() && app.user.settings.get('soundboard')

      if (insideRePrepareToRecordWindow && !this.queuedPrepareToRecordDependencyChange) {
        this.queuedPrepareToRecordDependencyChange = true
        var requirements = []
        // wait for any currently running preparations to finish first
        if (this.preparingPromise) requirements.push(this.preparingPromise)

        var bustPromiseCache = !!this.preparingPromise

        Promise.all(requirements).then(function () {
          // clear health checks and re-prepare to record
          // with busted promise cache
          app.user.clearHealthChecks()
          self.queuedPrepareToRecordDependencyChange = false
          self.prepareToRecord(bustPromiseCache)
        })
      }
    },

    deferUploadsChange: function (project, deferUploads) {
      app.user.set({deferUploads: deferUploads})
      this.socket.emit('change:deferUploads', deferUploads)
    },

    recordingChange: function (model, recording) {
      this.timer.reset()
      this.timer.set({duration: recording.get('duration')})
      this.recording = recording
    },

    trackFinalizedChange: function (track, finalized) {
      if (finalized) {
        dbg(track.get('format') + ' has finalized')
        this.uploadCompleted(track)
      } else {
        console.warn('Track changed from finalized to unfinalized. This probably should not happen')
      }
    },

    monitorChange: function (recorder, monitor) {
      if (monitor) {
        this.monitorGain.disconnect()
        this.monitorGain.connect(app.localAudioOut)
      } else {
        this.monitorGain.disconnect(app.localAudioOut)
      }
    },

    monitorGainChange: function (recorder, monitorGain) {
      this.monitorGain.gain.value = monitorGain
    },

    audioInputChange: function (audioInput) {
      localStorage.setItem('audioInputId', audioInput.get('deviceId'))
      dbg('Audio input changed')

      if (this.get('micArmed')) {
        this.stopMicrophone()
      }

      // if we are not hosting the voip, manually start the mic
      var voipDisabled = !app.user.settings.get('hostVoip')
      if (voipDisabled) {
        this.call.disconnectLocalStream()
        this.call.getLocalAudio()
      }
    },

    audioOutputChange: function (audioOutput) {
      var audioOutputId = audioOutput.get('deviceId')
      if (!audioOutputId) return console.error('No Audio Output Id')
      localStorage.setItem('audioOutputId', audioOutputId)
      this.setLocalStreamSink(audioOutputId)
    },

    // listen for when this track ends
    // this might happen if the user changes the input device mid recording
    // or a different error appears
    trackEnded: function () {
      var self = this
      // we only care about this event when we are recording
      if (!self.get('isRecording')) return

      if (this.mediaRecorder) {
        this.call.mediaDevices.fetch(true).then(function (devices) {
          var audioInputs = devices.filter(function (device) { return device.kind === 'audioinput' })

          // if we have another mic to choose from
          // restart the process
          if (audioInputs.length) {
            // override the onstop method so we can reinint the audio pipeline
            self.mediaRecorder.onstop = function () {
              // add a promise and when new stream comes in and this gets resolved
              // start the new media recorder
              self._stopMediaReocrderTimestamp = Promise.resolve(Date.now())
            }
            self.mediaRecorder.stop()
          } else {
            // abort the recording
            self.abortRecordingAndReportError(new Error('Could not get stream from new input device.'))
          }
        })
      }
    },

    mutedChange: function (user, muted) {
      if (muted) {
        console.log('User muted')
        this.muteMicrophone()
      } else {
        console.log('User unmuted')
        this.unmuteMicrophone()
      }
    },

    pausedChange: function (model, paused) {
      if (paused) {
        this.pause()
      } else {
        this.resume()
      }
    },

    /**
     * Microphone related methods
     */
    startMicrophone: function (localAudioInput) {
      var model = this

      model.set({'micArmed': true})
      dbg('Starting microphone')

      model.microphone = localAudioInput

      model.microphone.connect(model.compressor)
      model.compressor.connect(model.monitorGain)
      model.compressor.connect(model.input)
      model.input.connect(model.analyserNode)
      model.input.connect(app.outgoingVoipStreamDestination)

      if (this.get('isRecording')) {
        // this should only happen if the users mic gets unplugged
        // during a recording in progress and we have to fallback
        // to the next available microphone
        //
        // TODO: notify the host when this happens

        console.error('The user\'s microphone became disconnected while recording.  Falling back to next available mic: ' + this.call.audioInput.get('label'))
      }

      if (this.get('monitor')) {
        model.monitorGain.connect(app.localAudioOut)
      }

      // if the mic should be muted when we get the stream
      if (app.user.get('muted')) {
        this.muteMicrophone()
      }
    },

    stopMicrophone: function () {
      this.microphone.disconnect(this.compressor)
      this.compressor.disconnect()
      this.input.disconnect()
      this.monitorGain.disconnect()
      this.stopAudioTracks()
      this.set({'micArmed': false})
      dbg('Stopped microphone')
    },

    muteMicrophone: function () {
      this.microphone.gain.value = 0
      // always get the latest audio track
      var audioTrack = this.call.localAudio.mediaStream.getAudioTracks()[0]
      if (audioTrack) {
        audioTrack.enabled = false
      }
      dbg('Muted microphone')
    },

    unmuteMicrophone: function () {
      this.microphone.gain.value = this.get('micGain')
      // always get the latest audio track
      var audioTrack = this.call.localAudio.mediaStream.getAudioTracks()[0]
      if (audioTrack) {
        audioTrack.enabled = true
      }
      dbg('Unmuted microphone')
    },

    /**
     * Audio stream related methods
     */
    ensureAccessToLocalAudioStream: function () {
      var self = this

      dbg('ensureAccessToLocalAudioStream')

      return new Promise(function (resolve, reject) {
        if (self.get('micArmed')) return resolve(self.call.localAudio)

        // if the mic isnt' already armed then wait for it
        self.once('change:micArmed', function () {
          resolve(self.call.localAudio)
        })
      })
    },

    gotLocalStream: function (localInput, localAudio) {
      var self = this

      // fetch media devices again now that mic access is granted
      // so we can get labels that may have been withheld before
      var bustPromiseCache = true
      this.mediaDevices.fetch(bustPromiseCache)

      // use the recorder input so as to catch whatever is sent there
      // namely soundboard audio for the host
      var merger = this.actx.createChannelMerger(2)

      this.input.connect(merger, 0, 0)
      this.project.soundboardOut.connect(merger, 0, 1)

      this.trigger('localRecorderAudioAdded', merger)

      this.startMicrophone(localInput)

      // if we are using the media recorder
      // we need to restart it either way
      if (this.mediaRecorder) {
        // dump this instance and create a new one
        this.mediaRecorder = null
        // if we are not recording, then recreate the media recorder
        this.initMediaRecorder(localAudio, 'microphone')
        if (this.get('isRecording')) {
          this.mediaRecorder.start(this._microphoneMediarecorderChunkSize)
        }
      }

      if (this.recording.get('isRecording')) {
        dbg('Starting to record automatically')
        self.set({'isRecording': true})
      }
    },

    setLocalStreamSink: function (audioOutputId) {
      app.localAudioOutEl.setSinkId(audioOutputId)
    },

    stopAudioTracks: function () {
      var tracks = this.call.localAudio.mediaStream.getAudioTracks()
      _.each(tracks, function (track) {
        track.stop()
      })
    },

    /**
     * Track related methods
     */
    createUserTracks: function () {
      dbg('createUserTracks')

      var wavRecording = app.user.settings.get('wavRecording')
      var hasSoundboard = app.user.isHost() && app.user.settings.get('soundboard')
      var promises = []

      // if the user has soundboard on
      // make the soundboard the fist track so it's more proeminent
      if (hasSoundboard && !app.user.getTrack('mp3', 'soundboard')) {
        promises.push(this.createSoundboardTrack())
      }

      // if enabled the wav goes first in the pipeline since it doesn't do any encoding
      if (wavRecording && !app.user.getTrack('wav')) {
        promises.push(this.createWavTrack())
      }

      // if tracks don't already exist in user.tracks
      // create them
      if (!app.user.getTrack('mp3')) {
        promises.push(this.createMp3Track())
      }

      return Promise.all(promises).then(function () {
        var tracks = []
        var wavTrack = app.user.getTrack('wav')
        var mp3Track = app.user.getTrack('mp3')
        var soundboardTrack = app.user.getTrack('mp3', 'soundboard')
        wavTrack && tracks.push(wavTrack) // wav first if it exists
        mp3Track && tracks.push(mp3Track)
        soundboardTrack && tracks.push(soundboardTrack)
        return tracks
      }).catch(utils.logRethrow)
    },

    initializeTracksForRecording: function (tracks) {
      var self = this
      dbg('initializeTracksForRecording')

      // does this need to be run with promiseSerial?
      return Promise.all(tracks.map(function (track) {
        return track.initForRecording(self.audioPipeline)
      })).then(function (tracks) {
        dbg('Initialized tracks: ', tracks.map(function (track) { return track.get('format') + ':' + track.get('type') + ':' + track.id }).join(' '))
        return tracks
      }).catch(utils.logRethrow)
    },

    createMp3Track: function () {
      return app.user.createTrack({format: 'mp3'})
    },

    createWavTrack: function () {
      return app.user.createTrack({format: 'wav'})
    },

    createSoundboardTrack: function () {
      return app.user.createTrack({format: 'mp3', type: 'soundboard'})
    },

    /**
     * used to search for and upload old tracks
     * they might appear if the user refreshes the page during a recording
     */
    finishOldTracks: function (skip) {
      var self = this
      skip = skip || 0

      dbg('Started finalizing old tracks')
      // get all the tracks from this recording, for this user, that are not finalized and are not uploading
      var userTracks = this.recording.tracks.filter(function (track) {
        return track.get('userId') === app.user.get('_id') && !track.get('finalized') && !track.get('uploading')
      })

      var nextTrack = userTracks[skip]

      if (nextTrack) {
        nextTrack.uploadFull().catch(function (err) {
          console.error('Error uploading ', nextTrack.get('format') + ':' + nextTrack.id, ' skipping for now: ', err.toString())

          // remove listener - will add it back again when we retry
          nextTrack.off('uploadCompleted')

          self.finishOldTracks(1)
        })
        // nextTrack.getAudioBlob().then(function (blob) {
        //   console.log('Uploading unfinalized old track:', userTracks[0])
        //   nextTrack.set({size: blob.size})
        //   nextTrack.saveDuration(nextTrack.getDuration())
        //   nextTrack.upload(blob)
        // })
        nextTrack.on('uploadCompleted', function () {
          console.log('Old track completed')
          self.finishOldTracks()
        })
      } else {
        console.log('Finished uploading all the old tracks for this user.')
        // check if the user has more than tracks in the recording than in user.tracks
        // if so, than he might have refreshed the page
        var tracks = this.recording.tracks.countBy(function (track) {
          return track.get('userId') === app.user.get('_id')
        })

        // we need to force the 'All done' popup
        if (tracks['true'] > app.user.tracks.length) {
          this.trigger('userAudioUploaded')
        }
      }
    },

    /**
     * called after the mp3 has finished uploading
     * it gets the wav file and uploads that
     * @param  {Object} track The mo3 Track model
     */
    uploadCompleted: function (track) {
      var wavTrack = app.user.getTrack('wav')
      // wait until the mic mp3 has finalized
      if (track.get('format') === 'mp3' && wavTrack) {
        // ignore anything but the mic
        if (track.get('type') !== 'microphone') return

        dbg('Started uploading wav file')
        wavTrack.set({processing: true, duration: this.timer.duration()})

        var requirements = []

        if (!wavTrack.get('lastChunkProcessed')) {
          var lastChunkPromise = new Promise(function (resolve, reject) {
            wavTrack.once('change:lastChunkProcessed', resolve)
          })
          requirements.push(lastChunkPromise)
        }

        Promise.all(requirements).then(function () {
          return wavTrack.uploadFull()
        }).catch(function (err) { throw new Error(err) })
      } else {
        // when all the current tracks have finished,
        // search for old track that might have appeared if the user refreshed the page
        this.finishOldTracks()
      }
    },

    /**
     * Recording model methods
     */
    createRecording: function (cb) {
      var model = this
      var recording = this.recording.toJSON()
      recording.cloudDrive = this.project.get('cloudDrive')
      recording.name = recording.name || 'Recording ' + model.project.recordings.length
      this.socket.emit('recordings:create', recording, function (err, recording) {
        model.recording.set(recording)
        cb(err, recording)
      })
    },

    updateRecording: function () {
      var duration = this.timer.duration()
      this.recording.set({duration: duration})
      if (app.user.isHost()) {
        var data = {
          _id: this.recording.id,
          duration: duration
        }
        this.socket.emit('recordings:update', data, function (err, data) {
          if (err) console.error(err)
        })
      }
    },

    showUserTrack: function (track) {
      // adds the track to be shown in the ui as it uploads
      this.recording.tracks.add(track)
    },

    /**
     * Other methods
     */
    /**
     * called when the user wants to start a new recording the close tab protection is active
     * if the user confirms the popup, remove the protection and call the mode's startOver again
     */
    showStartOverModal: function () {
      var self = this
      var startOverModal = new zc.views.ModalView({
        addClass: 'start-over-modal',
        model: new Backbone.Model({
          title: 'Refresh page?',
          text: 'If you start a new recording you might lose data that is currently being processed. Are you sure?'
        }),
        ChildView: zc.views.ConfirmView,
        force: true,
        callback: function (confirmed) {
          if (confirmed) {
            self.project.trigger('stopCloseTabProtection')
            self.startOver(true)
          }
          startOverModal.exit()
        }
      })
      startOverModal.render()
    },

    startOver: function (newRecording) {
      var model = this
      // if the close tab protection is on then the page is doing something
      if (this.project.get('closeTabProtection')) {
        dbg('Tried to start over but close tab protection was on')
        this.showStartOverModal()
        return
      }

      this.socket.emit('startOver', {newRecording: newRecording}, function (err) {
        // the error might appear if the host is on the page and the project is archived
        if (err) {
          utils.notify('error', 'There was an error trying to perform this action, please refresh the page and try again.')
          return
        }
        model.trigger('startOver')
      })
    },

    hasStartedRecording: function () {
      return this.get('isRecording') || this.recording.get('duration') || this.hasFinishedRecording()
    },

    hasFinishedRecording: function () {
      return (this.recording.get('duration') || this.recording.tracks.length) && !this.get('isRecording')
    },

    /**
     * Used to get the sample rate of the current stream
     * @return {Promise}
     */
    getSampleRate: function () {
      var self = this

      if (this._sampleRatePromise) return this._sampleRatePromise

      var mediaStream = this.call.localAudio.mediaStream

      this._sampleRatePromise = new Promise(function (resolve, reject) {
        // if we have webm pcm support, use media recorder
        if (self._webmPcmSupport) {
          if (!self.audioPipeline) return Promise.reject(new Error('Audio pipeline not initialized'))

          var callback = function (e) {
            switch (e.data.command) {
              case 'webmInfo':
                var samplerate = e.data.data.sample_rate // asd
                app.user.attributes.platform.sampleRate = samplerate

                console.log('Microphone sample rate (webm):', samplerate)
                resolve(samplerate)

                self.audioPipeline.removeEventListener('message', callback)
                break
            }
          }
          self.audioPipeline.addEventListener('message', callback)

          var mediaRecorder = new MediaRecorder(mediaStream, {mimeType: self._pcmMimeType})
          mediaRecorder.ondataavailable = function (e) {
            self.audioPipeline.postMessage({
              command: 'getWebmInfo',
              data: e.data
            })
          }
          mediaRecorder.start()
          setTimeout(function () {
            mediaRecorder.stop()
          }, 300)
        } else {
          // if no pcm support
          var destination = self.actx.createMediaStreamDestination()
          var processor = self.actx.createScriptProcessor(512, 1, 1)
          processor.onaudioprocess = function (e) {
            try {
              self.input.disconnect(processor)
              processor.disconnect(destination)
            } catch (e) {}

            app.user.attributes.platform.sampleRate = e.inputBuffer.sampleRate

            console.log('Microphone sample rate (ff):', e.inputBuffer.sampleRate)
            resolve(e.inputBuffer.sampleRate)
          }
          self.input.connect(processor)
          processor.connect(destination)
        }
      })

      return this._sampleRatePromise
    },

    pause: function () {
      this.mediaRecorder.pause()
      this.timer.pause()
    },

    resume: function () {
      this.mediaRecorder.resume()
      this.timer.resume()
    }
  })
})()
