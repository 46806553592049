/* globals zc _ */

(function () {
  'use strict'

  zc.views.NotFoundView = zc.views.PageView.extend({

    template: _.template($('.not-found-template').html()),

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      return this
    }
  })
})()
