/* globals zc _ Backbone app utils debug analytics */

(function () {
  'use strict'

  var dbg = debug('zc:lobbyUserView')

  zc.views.LobbyUserView = Backbone.View.extend({
    initialize: function (options) {
      this.recorder = options.recorder
      this.actx = this.recorder.actx
      this.buffer = options.buffer

      this.listenTo(this.model, 'change:micArmed', this.micArmedChange)
      this.listenTo(this.model, 'change:isRecording', this.isRecordingChange)
      this.listenTo(this.model, 'change:paused', this.pausedChange)
      // this.listenTo(this.model, 'change:path', this.uploadComplete);
      // this.listenTo(this.model, 'change:uploading', this.uploadingChange);
      // this.listenTo(this.model, 'change:downloading', this.downloadingChange);
      // this.listenTo(this.model, 'change:percentUploaded', this.percentUploadedChange);
      this.listenTo(this.model, 'change:deferUploads', this.deferUploadsChange)
      this.listenTo(this.model, 'streamAdded', this.streamAdded)
      this.listenTo(this.model, 'streamRemoved', this.streamRemoved)
      this.listenTo(this.model, 'change:streamVolume', this.streamVolumeChange)
      this.listenTo(this.model, 'change:muted', this.mutedChange)
      this.listenTo(this.model, 'change:handRaised', this.handRaisedChange)
      this.listenTo(this.model.audioInput, 'change', this.renderMicLabel)
      this.listenTo(this.model.criticalHealthChecks, 'reset', this.healthChecksReset)
      this.listenTo(this.model.warningHealthChecks, 'reset', this.healthChecksReset)
      this.listenTo(this.model, 'change:localStorageQuota', this.renderLocalStorage)

      // status update listeners
      this.listenTo(this.model, 'change:micArmed', this.renderStatus)
      this.listenTo(this.model.audioInput, 'change', this.renderStatus)
      this.listenTo(this.model, 'change:isRecording', this.renderStatus)
      this.listenTo(this.model, 'change:paused', this.renderStatus)
      this.listenTo(this.model.tracks, 'change:uploading', this.renderStatus)
      this.listenTo(this.model.tracks, 'change:path', this.renderStatus)
      this.listenTo(this.model.tracks, 'add', this.renderStatus)
      this.listenTo(this.model.tracks, 'change:bytesRecorded change:bytesSaved change:percentSaved', this.renderTrackSaveProgress)
      this.listenTo(this.model.tracks, 'change:bytesRecorded change:bytesUploaded change:percentUploaded', this.renderTrackUploadProgress)

      this.listenTo(this.model.criticalHealthChecks, 'reset', this.renderStatus)
      this.listenTo(this.model.warningHealthChecks, 'reset', this.renderStatus)
      // this.listenTo(app.localStorageManager, 'change:alertLevel', this.renderStatus);

      this.listenTo(this.model, 'voipConnected', this.voipConnected)
      this.listenTo(this.model, 'voipDisconnected', this.voipDisconnected)

      this.listenTo(this.model, 'remove', this.tearDown)

      this.analyser = this.actx.createAnalyser() // analyser node to share with buffer view and peak meter view
      this.analyser.smoothingTimeConstant = 0.85
      // this.analyser.minDecibels = -90
      // this.analyser.maxDecibels = -10

      // compressor to make the visualizations look fatter
      // this.compressor = this.actx.createDynamicsCompressor()
      // this.compressor.threshold.setValueAtTime(0, this.actx.currentTime)
      // this.compressor.knee.setValueAtTime(24, this.actx.currentTime)
      // this.compressor.ratio.setValueAtTime(12, this.actx.currentTime)
      // this.compressor.attack.setValueAtTime(0, this.actx.currentTime)
      // this.compressor.release.setValueAtTime(0, this.actx.currentTime)

      // this.compressor.connect(this.analyser)
    },

    template: _.template($('.lobby-user-template').html()),

    className: 'lobby-user',

    events: {
      'click .download': 'downloadFile',
      'click .kick': 'kickClick',
      'click .mic-status': 'toggleMuted',
      'click .raised-hand': 'toggleHand',
      'click .user-footer-handle': 'toggleUserFooter',
      'click .health-check-status-item': 'toggleHealthCheckLog'
    },

    toggleUserFooter: function () {
      if (this.$footer.hasClass('hidden')) {
        this.showUserFooter()
      } else {
        this.hideUserFooter()
      }
    },

    showUserFooter: function () {
      this.$footer.removeClass('hidden')
    },

    hideUserFooter: function () {
      this.$footer.addClass('hidden')
      this.hideHealthCheckLog()
    },

    toggleHealthCheckLog: function () {
      if (this.$healthCheckLog.hasClass('hidden')) {
        this.showHealthCheckLog()
      } else {
        this.hideHealthCheckLog()
      }
    },

    showHealthCheckLog: function () {
      this.$healthCheckStatusItem.addClass('expanded')
      this.$healthCheckLog.removeClass('hidden')
    },

    hideHealthCheckLog: function () {
      this.$healthCheckStatusItem.removeClass('expanded')
      this.$healthCheckLog.addClass('hidden')
    },

    voipConnected: function () {
      this.$el.removeClass('disconnected')
    },

    voipDisconnected: function () {
      this.$el.addClass('disconnected')
    },

    handRaisedChange: function (user, handRaised) {
      if (handRaised) {
        this.$el.addClass('hand-raised')
        app.player.play('userRaisedHand')
      } else {
        this.$el.removeClass('hand-raised')
        app.player.play('userLoweredHand')
      }
    },

    toggleHand: function () {
      var handRaised = this.model.get('handRaised')
      this.model.set({handRaised: !handRaised})
      analytics.track('Raise Hand', {
        projectId: app.location.id,
        recordingId: app.location.recorder.recording.id,
        userId: app.user.id,
        username: app.user.get('username')
      })
    },

    toggleMuted: function () {
      var muted = !this.model.get('muted')

      this.model.set({muted: muted})

      var user = this.model

      if (user.isLocal()) {
        this.model.trigger('localUserMutedChange', user, muted)
      } else {
        this.model.trigger('remoteUserMutedChangedLocally', user, muted)
      }

      analytics.track('Mute')
    },

    streamAdded: function (audioGain) {
      // hook up the audio to our visualizations
      audioGain.connect(this.analyser)

      // start the drawing
      // this.startPeakMeter()
      this.startWaveform()
    },

    streamRemoved: function (audioGain) {
      // There is no way to detect ahead of time if the nodes are already connected
      // so catch the error caused by disconnecting a node that isn't connected if it happens
      try {
        audioGain.disconnect(this.analyser)
      } catch (err) {
        console.log('Stream input already disconnected from analyser for peer: ', this.model.id, ' Resulting error: ', err)
      }
      // this.stopPeakMeter()

      // this.$el.removeClass('has-video')
      // this.$video.find('video').remove()
    },

    // startPeakMeter: function (streamSource) {
    //   if (this.peakMeterView) this.peakMeterView.stop() && this.peakMeterView.remove()
    //
    //   this.peakMeterView = new zc.views.PeakMeterView({
    //     actx: this.recorder.actx,
    //     animationFrameManager: app.animationFrameManager,
    //     analyser: this.analyser,
    //     el: this.$volume
    //   })
    //   this.peakMeterView.render()
    //   this.peakMeterView.start()
    // },
    //
    // stopPeakMeter: function () {
    //   if (this.peakMeterView) {
    //     this.peakMeterView.stop()
    //   }
    // },

    kickClick: function (e) {
      e.preventDefault()
      this.renderConfirmKickModal()
    },

    kickUser: function () {
      if (app.user.isHost()) {
        this.model.collection.remove(this.model)
        app.socket.emit('kickUser', {userId: this.model.id})
      }
    },

    renderConfirmKickModal: function () {
      var view = this
      var modalView = new zc.views.ModalView({
        addClass: 'confirm-kick-modal',
        model: new Backbone.Model({
          title: 'Kick user',
          text: 'Are you sure you want to kick this user?',
          confirmText: 'Confirm',
          cancelText: 'Cancel'
        }),
        ChildView: zc.views.ConfirmView,
        callback: function (result) {
          if (result) {
            view.kickUser()
          }
          modalView.exit()
        }
      })

      modalView.render()
    },

    // showTrack: function () {
    //   var track = this.model.getTrack('mp3');
    //   track.set({
    //     uploading: true,
    //     progress: 0,
    //   });
    //
    //   if (this.model == app.user) {
    //     track.set({
    //       duration: this.recorder.recording.get('duration'),
    //       token: this.model.get('uploadToken')
    //     });
    //   }
    //
    //   // only add and show tracks that belong to the user unless you are the host
    //   if (app.user.isHost() || this.model == app.user) {
    //     this.recorder.recording.tracks.add(track);
    //   }
    // },

    uploadingChange: function (model, uploading) {
      if (uploading) {
        this.showUploading()
        // this.showTrack();
      } else {
        this.hideUploading()
        // var finalized = !!this.model.track.get('path');
        // this.model.track.set({uploading: false, finalized: finalized});
      }
    },

    renderTrackSaveProgress: function (track) {
      if (track.get('format') !== 'mp3') return // we only want to render mp3 tracks here
      var bytesRecorded = track.get('bytesRecorded')
      var bytesSaved = track.get('bytesSaved')
      // var percentSaved = track.get('percentSaved')
      var percentSaved = Math.min(Math.round(bytesSaved / bytesRecorded * 100), 100)
      dbg('renderPercentSaved - ', percentSaved, '% - bytesRecorded: ', bytesRecorded, ' - bytesSaved: ', bytesSaved)
      var percentString = percentSaved + '%'
      var fraction = utils.formattedDiskSpace(bytesSaved) + ' / ' + utils.formattedDiskSpace(bytesRecorded)
      dbg('saveProgress - percentString: ', percentString, ' - fraction: ', fraction)
      this.$localBackupProgressText.text(fraction)
      this.$localBackupProgressBar.css('width', percentString)
    },

    renderTrackUploadProgress: function (track) {
      if (track.get('format') !== 'mp3') return // we only want to render mp3 tracks here
      var bytesRecorded = track.get('bytesRecorded')
      var bytesUploaded = track.get('bytesUploaded')
      var percentUploaded = track.get('percentUploaded')
      dbg('renderPercentUploaded - ', percentUploaded, '% - bytesRecorded: ', bytesRecorded, ' - bytesUploaded: ', bytesUploaded)
      var percentString = percentUploaded + '%'
      var fraction = utils.formattedDiskSpace(bytesUploaded) + ' / ' + utils.formattedDiskSpace(bytesRecorded)
      dbg('uploadProgress - percentString: ', percentString, ' - fraction: ', fraction)
      this.$cloudBackupProgressText.text(fraction)
      this.$cloudBackupProgressBar.css('width', percentString)
    },

    // percentUploadedChange: function (model, ratio) {
    //   var percent = Math.round(ratio * 100);
    //   this.$percentUploaded.text(percent + '%');
    //
    //   if (this.model.track) {
    //     this.model.track.set({progress: percent});
    //   }
    // },

    // showUploading: function () {
    //   this.$uploading.css({display: 'block', opacity: 1});
    // },
    //
    // hideUploading: function () {
    //   this.$uploading.css({display: 'none'});
    // },

    // downloadingChange: function (model, downloading) {
    //   if (downloading) {
    //     this.showDownloading();
    //   } else {
    //     this.hideDownloading();
    //   }
    // },
    //
    // showDownloading: function () {
    //   this.$download.addClass('downloading').text('Downloading');
    // },
    //
    // hideDownloading: function () {
    //   this.$download.removeClass('downloading').text('Download');
    // },

    micArmedChange: function (model, micArmed) {
      this.renderMicArmedState()
      this.setMuteAccess()
    },

    mutedChange: function (user, muted) {
      // if (muted) {
      //   this.stopPeakMeter();
      // } else {
      //   this.startPeakMeter();
      // }

      this.renderMuted()
    },

    showMicArmed: function () {
      this.$el.addClass('armed')
      this.$micStatus.find('span').text('Mic On')
    },

    hideMicArmed: function () {
      this.$el.removeClass('armed')
      this.$micStatus.find('span').text('Mic Off')
    },

    healthChecksReset: function () {
      var status = this.model.getHealthCheckStatus()

      // if there are warnings or failures
      // then we want to show the footer automatically
      // and show log in case of failure
      // Only on the first render though
      var firstTimeRendering = !!this.$criticalHealthChecks.is(':empty')
      if (firstTimeRendering) {
        if (status === 'warning') {
          this.showUserFooter()
        } else if (status === 'failed') {
          this.showUserFooter()
          this.showHealthCheckLog()
        }
      }

      this.renderHealthChecks()
    },

    renderMicArmedState: function () {
      var micArmed = this.model.get('micArmed')
      if (micArmed) {
        this.showMicArmed()
      } else {
        this.hideMicArmed()
      }
    },

    renderHealthChecks: function () {
      if (app.user.isHost() || this.model.isLocal()) {
        this.renderCriticalHealthChecks()
        this.renderWarningHealthChecks()
        this.renderHealthCheckStatus()
      }
    },

    renderCriticalHealthChecks: function () {
      var self = this
      this.$criticalHealthChecks.empty()
      this.model.criticalHealthChecks.forEach(function (check) {
        var healthCheckView = new zc.views.HealthCheckItemView({model: check})
        self.$criticalHealthChecks.append(healthCheckView.render().el)
      })
    },

    renderWarningHealthChecks: function () {
      var self = this
      this.$warningHealthChecks.empty()
      this.model.warningHealthChecks.forEach(function (check) {
        var healthCheckView = new zc.views.HealthCheckItemView({model: check})
        self.$warningHealthChecks.append(healthCheckView.render().el)
      })
    },

    renderHealthCheckStatus: function () {
      var status = this.model.getHealthCheckStatus()
      this.$healthCheckStatusItem
        .removeClass('passed failed warning unknown')
        .addClass(status)
      this.$healthCheckStatus.text(status)
    },

    renderLocalStorage: function () {
      // var icon = '/media/images/folder-check-small.svg'
      // var storageQuota = this.model.get('localStorageQuota')
      // var hasError = false
      // var text
      //
      // if (storageQuota.alertMessage) {
      //   text = storageQuota.alertMessage
      //   icon = '/media/images/warning-red.svg'
      //   hasError = true
      // } else {
      //   text = 'Only ' + utils.formattedDiskSpace(storageQuota.free) + ' Free'
      //
      //   var alertLevel = app.localStorageManager.calcAlertLevel(storageQuota)
      //
      //   if (alertLevel > 0) {
      //     hasError = true
      //   }
      //
      //   if (alertLevel === 1) {
      //     icon = '/media/images/folder-warning-small.svg'
      //   } else if (alertLevel === 2) {
      //     icon = '/media/images/folder-error-small.svg'
      //   }
      // }
      //
      // this.$localStorageIcon.attr('src', icon)
      // this.$localStorageStatus.text(text)
      //
      // if (hasError) {
      //   this.$localStorageQuota.show()
      // } else {
      //   this.$localStorageQuota.hide()
      // }

      var storageQuota = this.model.get('localStorageQuota')
      var alertLevel = app.localStorageManager.calcAlertLevel(storageQuota)
      var alertLevelName = app.localStorageManager.alertLevelNames[alertLevel]
      var formattedFree = utils.formattedDiskSpace(storageQuota.free)
      this.$localStorageStatusItem.removeClass(app.localStorageManager.alertLevelNames.join(' '))
      this.$localStorageStatusItem.addClass(alertLevelName)
      this.$localStorageStatus.text(formattedFree)
    },

    renderMicLabel: function () {
      var micLabel = this.model.attrs().micLabel
      this.$micLabel.text(micLabel)
    },

    renderMuted: function () {
      var muted = this.model.get('muted')

      if (muted) {
        this.showMuted()
      } else {
        this.showUnmuted()
      }
    },

    showMuted: function () {
      this.$el.addClass('muted')
      this.$micStatus.find('span').text('Muted')
    },

    showUnmuted: function () {
      this.$el.removeClass('muted')
      this.$micStatus.find('span').text('Mic on')
    },

    isRecordingChange: function (model, isRecording) {
      this.buffer.set({isRecording: isRecording})
    },

    // showRecording: function () {
    //   this.$recordingStatus.addClass('is-recording')
    // },
    //
    // hideRecording: function () {
    //   if (this.recorder.recording.get('duration') && !this.model.get('paused')) {
    //     this.$recordingStatusText.text('Done Recording');
    //   }
    //
    //   this.$recordingStatus.removeClass('is-recording');
    //   this.$paused.hide();
    // },
    //
    // renderRecordingState: function () {
    //   var isRecording = this.model.get('isRecording');
    //   if (isRecording) {
    //     this.showRecording();
    //   } else {
    //     this.hideRecording();
    //   }
    // },

    // pausedChange: function (model, paused) {
    //   if (paused) {
    //     this.hideRecording();
    //     this.$paused.show();
    //   } else {
    //     this.$paused.hide();
    //     this.showRecording();
    //   }
    // },

    deferUploadsChange: function (model, deferUploads) {
      if (deferUploads) {
        this.showDeferredUploads()
      } else {
        this.hideDeferredUploads()
      }
    },

    showDeferredUploads: function () {
      this.$deferredUploads.show()
    },

    hideDeferredUploads: function () {
      this.$deferredUploads.hide()
    },

    setMuteAccess: function () {
      var micArmed = this.model.get('micArmed')
      var hasPermission = this.model.id === app.user.id || app.user.isHost()

      if (micArmed && hasPermission) {
        this.$micStatus.removeClass('disabled')
      } else {
        this.$micStatus.addClass('disabled')
      }
    },

    setRaisedHandAccess: function () {
      var user = this.model
      if (user.isLocal() || app.user.isHost()) {
        this.$raisedHand.removeClass('disabled')
      } else {
        this.$raisedHand.addClass('disabled')
      }
    },

    tearDown: function (model) {
      // this.buffer.trigger('remove')
      this.remove()
    },

    renderStatus: function () {
      var status = this.model.getStatus()
      this.$userStatus.text(status)
    },

    renderRecordAnalyser: function () {
      var recordAnalyserView = new zc.views.RecordAnalyserView({model: this.recorder.recAnalyser, el: this.$track})
      recordAnalyserView.render()
    },

    renderFrequencyAnalyser: function () {
      var frequencyAnalyserView = new zc.views.FrequencyAnalyserView({model: this.recorder, el: this.$frequencyAnalyser})
      frequencyAnalyserView.render()
    },

    startWaveform: function () {
      this.bufferView.startDrawingWaveformTail()
    },

    renderBuffer: function () {
      this.bufferView = new zc.views.BufferView({
        model: this.buffer,
        el: this.$buffer,
        analyser: this.analyser
      })
      this.bufferView.render()

      // in the case that the incoming user is the host and is already recording
      // we need to make sure that state gets applied
      this.buffer.set({isRecording: this.model.get('isRecording')})
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.$userStatus = this.$('.user-status')
      this.$track = this.$('.track')
      this.$frequencyAnalyser = this.$('.frequency-analyser')
      this.$buffer = this.$('.buffer')
      this.$micStatus = this.$('.mic-status')
      this.$micLabel = this.$('.selected-mic .item-content')
      this.$recordingStatus = this.$('.recording-status')
      this.$recordingStatusText = this.$recordingStatus.find('.status-text')
      this.$paused = this.$('.paused')
      this.$uploading = this.$('.uploading')
      this.$percentUploaded = this.$('.percent-uploaded')
      this.$deferredUploads = this.$('.deferred-uploads')
      this.$download = this.$('.download')
      this.$kick = this.$('.kick')
      this.$video = this.$('.video')
      this.$volume = this.$('.volume')
      this.$raisedHand = this.$('.raised-hand')

      // footer status items
      this.$footer = this.$('.user-footer')
      this.$healthCheckStatusItem = this.$('.health-check-status-item')
      this.$healthCheckStatus = this.$('.health-check-status-item .status')
      this.$localStorageStatusItem = this.$('.local-storage.status-item')
      this.$localStorageStatus = this.$('.local-storage.status-item .quota-free')
      this.$localBackupStatusItem = this.$('.local-backup.status-item')
      this.$localBackupProgressBar = this.$('.local-backup.status-item .progress-bar')
      this.$localBackupProgressText = this.$('.local-backup.status-item .progress-text')
      this.$cloudBackupStatusItem = this.$('.cloud-backup.status-item')
      this.$cloudBackupProgressBar = this.$('.cloud-backup.status-item .progress-bar')
      this.$cloudBackupProgressText = this.$('.cloud-backup.status-item .progress-text')

      // health check log
      this.$healthCheckLog = this.$('.health-check-log')
      this.$criticalHealthChecks = this.$('.health-check-items.critical')
      this.$warningHealthChecks = this.$('.health-check-items.warnings')

      // this.$localStorageStatus = this.$('.local-storage-status')
      // this.$localStorageIcon = this.$('.local-storage-icon')

      this.renderMicArmedState()
      this.setMuteAccess()
      this.renderMuted()
      this.setRaisedHandAccess()
      // this.renderRecordingState();
      this.renderStatus()
      this.renderMicLabel()
      this.renderLocalStorage()
      this.renderHealthChecks()

      // if (this.model.id == app.user.id) {
        // this.renderRecordAnalyser();
        // this.renderFrequencyAnalyser();
      this.renderBuffer()
      // }

      return this
    }
  })
})()
