/* globals zc _ Backbone utils app */

(function () {
  'use strict'

  zc.views.FootnoteFormView = Backbone.View.extend({
    initialize: function (options) {
      this.callback = options.callback
    },

    template: _.template($('.footnote-form-template').html()),

    className: 'footnote-form',

    events: {
      'click .footnote-submit': 'submit',
      'keypress .footnote-input': 'handleInputKeypress'
      // 'blur .footnote-input': 'submit'
    },

    handleInputKeypress: function (e) {
      // so we don't trigger global hotkeys while typing in the field
      e.stopPropagation()

      if (e.keyCode === 13) { // enter
        this.submit(e)
      }
    },

    submit: function (e) {
      e.preventDefault()
      this.attrs.text = this.$input.val() || 'No Text'
      this.attrs.ownerId = app.user.get('_id')
      this.model.recording.addFootnote(this.attrs)
      this.callback()
    },

    render: function () {
      var view = this
      var time = this.model.timer.duration()
      this.attrs = {
        time: time,
        hmsTime: utils.msToHms(time)
      }

      this.$el.html(this.template(this.attrs))
      this.$input = this.$('.footnote-input')
      this.$submit = this.$('.footnote-submit')

      _.defer(function () {
        view.$input.focus()
      })

      return this
    }
  })
})()
