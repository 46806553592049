/* globals zc _ $ app utils Backbone */

(function () {
  'use strict'

  zc.views.ProjectArchivedView = zc.views.PageView.extend({
    template: _.template($('.project-archived-template').html()),

    events: {
      'click .unarchive': 'unarchiveProject'
    },

    unarchiveProject: function () {
      // var self = this
      app.socket.emit('projects:unarchive', {_id: this.model.id}, function (err, res) {
        if (err) return utils.notify('error', err)

        // self.model.set({deletedAt: null})
        // app.router.navigate([self.model.get('owner'), self.model.get('slug')].join('/'), {trigger: true})
        // window.location.reload()

        // another way to refresh the view?
        Backbone.history.loadUrl(Backbone.history.fragment)
      })
    }
  })
})()
