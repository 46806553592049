/* globals zc Backbone */

(function () {
  'use strict'

  zc.models.Subscription = Backbone.Model.extend({
    initialize: function (attrs, options) {
      this.user = options.user
      this.setPlan(this, attrs.plan)
      this.on('change:plan', this.setPlan)
    },

    defaults: {
      plan: null,
      planBillingId: null,
      status: null,
      quantity: null,
      start: null,
      endedAt: null,
      trialStart: null,
      trialEnd: null,
      paidUntil: null,
      activeUntil: null,
      canceledAt: null
    },

    setPlan: function (model, plan) {
      if (this.plan) return this.plan.set(plan || {}, {user: this.user})
      this.plan = new zc.models.Plan(plan || {}, {user: this.user})
    },

    isActive: function () {
      // check if subscription is active
      var isActive = this.get('status') === 'active' || this.get('status') === 'past_due' // remove allowance for past due once I get trial ending transitions sorted out
      return isActive
    },

    canTrial: function () {
      return !this.get('start')
    },

    trialing: function () {
      // var trialEndTime = new Date(this.get('trialEnd')).getTime();
      // var paidUntilTime = new Date(this.get('paidUntil')).getTime();
      // var now = (new Date()).getTime();
      // return !paidUntilTime && trialEndTime > now;
      var isTrialing = this.get('status') === 'trialing'

      return isTrialing
    },

    isPaid: function () {
      return this.get('status') === 'active'
    }

  })
})()
