/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.MonitorSettingView = Backbone.View.extend({
    initialize: function () {
      this.listenTo(this.model, 'change:monitor', this.updateMonitorSelection)
      this.listenTo(this.model, 'change:monitorGain', this.updateMonitorGain)
    },

    template: _.template($('.monitor-setting-template').html()),

    className: 'monitor-setting',

    events: {
      'change .monitor-setting-select': 'monitorSelectChange'
    },

    updateMonitorSelection: function () {
      var monitor = this.model.get('monitor')
      this.$('.monitor-setting-select option[value="' + monitor + '"]').attr('selected', true)
    },

    monitorSelectChange: function (e) {
      this.model.set({monitor: JSON.parse(e.target.value)})
      console.log('Changed monitor setting: ', e.target.value ? 'on' : 'off')
    },

    render: function () {
      this.$el.html(this.template(this.model.attrs()))
      this.updateMonitorSelection()

      return this
    }
  })
})()
