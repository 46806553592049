/* globals zc _ app utils */

(function () {
  'use strict'

  zc.views.TranscriptionView = zc.views.TrackView.extend({
    initialize: function (options) {
      zc.views.TrackView.prototype.initialize.call(this, options)
      this.listenTo(this.model, 'change:completedAt', this.completedAtChange)
      this.listenTo(this.model, 'change:processing', this.processingChange)
      this.listenTo(this.model, 'change:errorMessage', this.errorChange)
      this.listenTo(this.model, 'change', this.render)

      _.bindAll(this, 'refreshStatus')
    },

    template: _.template($('.transcription-template').html()),

    className: 'transcription track',

    download: function (e) {
      var view = this
      e.preventDefault()

      if (this.model.get('downloading')) {
        // don't allow double downloads
        return
      }

      var projectId = this.model.get('projectId') || app.location.id
      var path = ['/' + app.user.id, this.model.getFinalMixFolder(), this.model.get('filename')].join('/')

      this.model.cloudStore.createDownloadUrl(projectId, path).then(function (downloadUrl) {
        var element = document.createElement('a')
        element.setAttribute('href', downloadUrl)

        var name = view.model.get('filename')
        element.setAttribute('download', name)

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
      })
    },

    completedAtChange: function (model, completedAt) {
      var view = this
      if (completedAt) {
        this.model.set({progress: 100})
        this.$name.text('Completed')

        setTimeout(function () {
          view.model.set({processing: false})
          view.$el.addClass('finalized')
          view.model.set({'progress': 0})
        }, 1500)
      }
    },

    processingChange: function (model, processing) {
      if (processing) {
        this.$el.addClass('processing')
      } else {
        this.$el.removeClass('processing')
      }
    },

    refreshStatus: function () {
      var view = this
      // if this view is no longer present in the dom (eg the user moved to a different recording)
      // stop polling for data
      if (!document.body.contains(this.el)) {
        clearInterval(view.pollId)
        return
      }

      this.readTranscription().then(function (transcription) {
        if (!transcription) {
          return view.$processingStatus.text('Waiting in Queue...')
        }

        // set processing to true always here so we can delay the ui change
        transcription.processing = true
        view.model.set(transcription)

        if (transcription.deliveredAt) {
          clearInterval(view.pollId)
        }

        if (transcription.errorMessage) {
          view.model.set({processing: false})
          clearInterval(view.pollId)
        }
      }).catch(function (err) {
        utils.notify('error', err)
      })
    },

    readTranscription: function () {
      var view = this
      return new Promise(function (resolve, reject) {
        app.socket.emit('transcription:read', {_id: view.model.id}, function (err, transcription) {
          err ? reject(err) : resolve(transcription)
        })
      })
    },

    pollStatus: function () {
      this.pollId = setInterval(this.refreshStatus, 5 * 1000)
    },

    errorChange: function (model, error) {
      if (error) {
        this.showError(error)
      } else {
        this.hideError()
      }
    },

    showError: function (error) {
      this.$el.removeClass('fixing-error')
      this.$el.addClass('has-error')
      this.$errorMessage.text(error)
    },

    hideError: function () {
      this.$el.removeClass('has-error')
    },

    checkError: function () {
      var attrs = this.model.attrs()
      if (attrs.error) {
        this.model.set({'error': attrs.errorMessage})
      }
    },

    render: function () {
      var view = this
      var attrs = this.model.attrs()
      this.$el.html(this.template(attrs))

      this.$errorMessage = this.$('.error-message')
      this.$processingStatus = this.$('.processing-status')
      this.$progressBar = this.$('.progress-bar')
      this.$progressPercent = this.$('.progress-percent')
      this.$name = this.$('.name')

      if (attrs.processing) {
        this.$el.addClass('processing')
        this.pollStatus()
      }

      if (attrs.deliveredAt) {
        this.$el.addClass('finalized')
      }

      this.checkError()

      if (this.model.get('error')) {
        this.showError()
      }

      // force progress to render
      _.defer(function () {
        view.model.trigger('change:progress', view.model, view.model.get('progress'))
      })

      return this
    }

  })
})()
