/* globals zc _ Backbone app utils moment debug analytics */

(function () {
  'use strict'

  var dbg = debug('zc:recording')

  zc.models.Recording = Backbone.Model.extend({
    initialize: function (attrs) {
      attrs = attrs || {}
      this.tentativeTracks = new zc.collections.Tracks()
      attrs.uploads = this.filterTentativeTracks(attrs.uploads)
      this.tracks = new zc.collections.Tracks(attrs.uploads)
      this.postproductions = new zc.collections.Tracks(attrs.postproductions)
      this.transcriptions = new zc.collections.Tracks(attrs.transcriptions || [{finalized: true}])
      this.footnotes = new zc.collections.Footnotes(attrs.footnotes)
      this.participants = new zc.collections.Users(attrs.participants)

      this.listenTo(this.tracks, 'change:finalized', this.checkAllUploaded)

      this.checkAllUploaded()
    },

    defaults: {
      duration: 0,
      hostVoip: true,
      isRecording: false,

      // postproduction algorithms
      loudnessTarget: -19,
      leveler: true,
      gate: true,
      crossGate: false,
      transcript: false,
      separateTracks: false
    },

    attrs: function () {
      var attrs = this.toJSON()
      attrs.hmsDuration = utils.msToHms(attrs.duration)
      attrs.trackCount = this.tracks.length
      attrs.tentativeTrackCount = this.tentativeTracks.length
      attrs.participantCount = this.participants.length
      attrs.postproductionCount = this.postproductions.length
      attrs.transcriptionCount = this.transcriptions.length
      attrs.footnoteCount = this.footnotes.length
      attrs.createdAtReadable = attrs.createdAt ? moment(attrs.createdAt).format('dddd MMM Do YYYY - h:mmA') : null
      attrs.updatedAtReadable = attrs.updatedAt ? moment(attrs.updatedAt).format('dddd MMM Do YYYY - h:mmA') : null
      attrs.name = this.getName()
      attrs.slug = this.getSlug()
      attrs.idPath = this.getIdPath()

      return attrs
    },

    getIdPath: function () {
      var project = this.collection.project
      return ['', project.get('owner'), project.get('slug'), this.id].join('/')
    },

    getName: function () {
      return this.get('name') || 'Recording ' + (this.collection.indexOf(this) + 1)
    },

    getSlug: function () {
      return utils.slugify(this.getName())
    },

    getCloudFolder: function () {
      var project = app.location
      return project.get('slug') + '/' + this.attrs().slug
    },

    // We create tentative tracks when we think
    // a recording is about to take place so that so that
    // we can instantly start recording when 'record' is pressed.
    //
    // Check for {tentative: true} tracks.
    // If they exist, remove them from tracks array
    // before adding tracks to recording.tracks
    filterTentativeTracks: function (tracks) {
      var self = this
      return _.filter(tracks, function (track) {
        if (track.tentative) { self.handleTentativeTrack(track); return false }
        return true
      })
    },

    handleTentativeTrack: function (trackAttrs) {
      var track = new zc.models.Track(trackAttrs)

      this.tentativeTracks.add(track) // store all tentatives here

      // If the Recording is unstarted and the tracks belong to
      // app.user, we can place them in user.tracks to be used
      // if the recording is followed through on at some point
      var userOwnsTrack = app.user.id === track.get('userId')
      if (userOwnsTrack && this.hasNotStarted()) {
        dbg('User owned tentative track: ', track.id, track.get('format'))
        app.user.tentativeTracks.add(track)
        // make sure the recording settings allow the format
        if (this.isFormatAllowed(track.get('format'))) {
          dbg('Adding tentative track to user tracks: ', track.id, track.get('format'))
          app.user.tracks.add(track)
        }
      }
    },

    isFormatAllowed: function (format) {
      var wavRecordingEnabled = !!app.user.settings.get('wavRecording')
      return format === 'mp3' || (wavRecordingEnabled && format === 'wav')
    },

    calcLocalStorageUsed: function () {
      var self = this
      return new Promise(function (resolve, reject) {
        var promises = self.tracks.map(function (track) {
          return track.calcLocalStorageUsed()
        })

        Promise.all(promises).then(function (sizes) {
          var size = _.reduce(sizes, function (a, b) { return a + b }, 0)
          resolve(size)
        }, function (err) {
          reject(err)
        })
      })
    },

    deleteLocalStorage: function () {
      var self = this

      var promises = self.tracks.map(function (track) {
        return track.deleteLocalStorage()
      })

      return Promise.all(promises)
    },

    addFootnote: function (attrs) {
      var model = this

      attrs.recordingId = this.id

      app.socket.emit('recording:addFootnote', attrs, function (err, footnote) {
        if (err) {
          utils.nofity('error', err)
        } else {
          model.footnotes.add(footnote)

          analytics.track('Footnote Created')
        }
      })
    },

    canAllowPostproduction: function () {
      return this.hasFinished()
    },

    getRawTracks: function () {
      return this.tracks ? this.tracks.toJSON() : this.get('uploads')
    },

    hasFinished: function () {
      var rawTracks = this.getRawTracks()
      return !!_.findWhere(rawTracks, {finalized: true})
    },

    hasOnlyTentativeTracks: function () {
      var rawTracks = this.getRawTracks()
      return _.pluck(rawTracks, 'tentative').indexOf(false) < 0
    },

    hasNotStarted: function () {
      return !this.get('duration') && !this.hasFinished() && this.hasOnlyTentativeTracks()
    },

    checkAllUploaded: function () {
      var allUploaded = this.allUploadsComplete()
      if (allUploaded) {
        this.set('allUploaded', allUploaded)
      }
    },

    allUploadsComplete: function () {
      var allUploaded = true
      if (!this.tracks.length) {
        allUploaded = false
      }

      this.tracks.each(function (track) {
        if (!track.get('finalized')) {
          allUploaded = false
        }
      })

      return allUploaded
    },

    // /**
    //  * called by forceFinalizeUpload to signal the server that it should upload the file
    //  * @param  {Object} track The Track model
    //  */
    // finalizeUploadFromServer: function (track) {
    //   var data = track.toJSON()
    //   data.user = {_id: data.userId, username: data.username}
    //   data.cloudToken = data.cloudToken || app.user.getCloudToken()
    //   data.recordingId = this.id
    //   data.path = data.path || track.getUploadPath()
    //   data._id = data._id
    //   data.forced = true
    //   var format = track.get('format')
    //   var url = window.location.href + '/' + this.id
    //
    //   if (format === 'wav') {
    //     utils.notify('error', 'You cannot finalize another users WAV file.  Please have them return to this recording from their computer using this url: ' + url + '.  They can click on their own track to retry the upload.')
    //     return
    //   }
    //
    //   app.socket.emit('forceFinalizeUpload', data, function (err, upload) {
    //     if (err) {
    //       utils.notify('error', err)
    //       track.set({error: 'Finalization Failed :/'})
    //     } else {
    //       track.set(upload)
    //     }
    //   })
    // },

    createPostproduction: function (args, cb) {
      var self = this
      var tracks = args.selectedTracks
      var track = tracks[0]
      var multiTrackOutput = this.get('separateTracks')
      var recordingId = self.id
      var multiTrackFormat = 'wav'
      var inputTracks = _.map(tracks, function (track) {
        return {
          id: track.id,
          format: track.get('format'),
          path: track.get('path'),
          filename: track.get('filename'),
          url: track.get('url'),
          cloudDrive: track.get('cloudDrive'),
          username: track.get('username'),
          userId: app.user.id
        }
      })

      var finalMixFolder = track.getFinalMixFolder()
      var finalMixFilename = track.getFinalMixFilename('mp3')

      var options = {
        metadata: {title: app.location.get('name') + ' ' + 'Final Mix'},
        format: 'mp3',
        algorithms: {
          leveler: this.get('leveler'),
          normloudness: true,
          loudnesstarget: this.get('loudnessTarget'),
          hipfilter: true,
          denoise: true,
          denoiseamount: 0,
          gate: this.get('gate'),
          crossgate: this.get('crossGate')
        },
        output_basename: finalMixFilename.replace(/\.[^.]*$/, '')
      }

      options.outputFiles = [
        {format: 'mp3', mono_mixdown: true}
      ]

      if (multiTrackOutput) {
        options.outputFiles.push({format: 'tracks', ending: multiTrackFormat + '.zip'})
        // also update the format
        options.format = 'wav'
      }

      console.log('createPostproduction options: ', options)
      dbg('Creating postproduction:', options.metadata.title, options.outputFiles)

      var data = {
        recordingId: recordingId,
        userId: app.user.id,
        destFolder: finalMixFolder,
        inputTracks: inputTracks,
        credits: args.credits,
        transcript: this.get('transcript'),
        transcriptLanguage: this.get('transcriptLanguage'),
        projectName: app.location.get('name'),
        recordingName: this.getName(),
        options: options
      }
      app.socket.emit('postproduction:create', data, function (err, response) {
        if (err) {
          utils.notify('error', 'There was a problem generating your final mix track. ' + err)
        } else {
          var postproduction = response.postproduction
          var transcription = response.transcription

          postproduction.progress = 33
          self.postproductions.add(postproduction)

          if (transcription) {
            self.transcriptions.set(transcription)
          }

          app.user.incPostproductionCredits(-args.credits)
          utils.notify('success', 'Your postproduction mix is processing and will be available for download shortly.<br><br>  We will send an email to ' + app.user.get('email') + ' when it is ready.', {ttl: 10000})
          self.set('transcript', null)
          self.set('transcriptLanguage', null)
        }

        cb(err, postproduction)
      })
    }
  })
})()
