/* globals zc _ Backbone app servars utils debug ua SimplePeer */

(function () {
  'use strict'

  var Callstats = window.callstats

  var dbgCall = debug('zc:call')
  var dbgPeer = debug('zc:call:peer')

  zc.models.Call = Backbone.Model.extend({
    initialize: function (attrs, options) {
      this.actx = options.actx
      this.socket = options.socket
      this.audioInput = app.user.audioInput
      this.audioOutput = app.user.audioOutput

      this.mediaDevices = new zc.collections.MediaDevices()

      this.peers = {}
      this.peerErrors = {}
      this.localAudio = {}

      dbgCall('Starting call module')

      // silent so we don't trigger a bunch of unnecessary events during the bootstapping phase
      this.setPreferredDevicesPromise = this.setPreferredDevices({ silent: true })

      this.listenTo(this.mediaDevices, 'devicesRemoved', this.mediaDevicesRemoved)
      this.listenTo(this.audioOutput, 'change', this.audioOutputChange)
      this.listenTo(app.user.settings, 'change:hostVoip', this.callDependencyChange)
    },

    defaults: {
      started: false
    },

    initCallDependencyListeners: function () {
      // use promise cache so this only runs once no matter how many times the call is restarted
      if (this.initCallDependencyListenersPromise) return this.initCallDependencyListenersPromise

      dbgCall('init call dependency listeners')
      this.initCallDependencyListenersPromise = Promise.resolve()
      this.listenTo(this.audioInput, 'change', this.inputStreamChanged)
      this.listenTo(app.user.settings, 'change:echoCancellation', this.echoCancellationChange)
      return this.initCallDependencyListenersPromise
    },

    getIceServers: function () {
      var self = this
      dbgCall('Getting Ice servers')

      if (typeof Callstats !== 'undefined') {
        // initialize callstats
        var stats = this.stats = new Callstats()
        stats.initialize(
          servars.callStats.appId,
          servars.callStats.secretKey, // not so secret ¯\_(ツ)_/¯
          {
            userName: app.user.id,
            aliasName: app.user.get('displayName')
          },
          this.callStatsInitCallback.bind(self),
          this.statsCallback.bind(self),
          {
            applicationVersion: servars.version,
            disableBeforeUnloadHandler: false, // disables callstats.js's window.onbeforeunload parameter.
            disablePrecalltest: false // disables the pre-call test, it is enabled by default
          }
        )
      }

      return new Promise(function (resolve, reject) {
        self.socket.emit('getIceToken', function (err, iceToken) {
          if (err && !err.match('Test Account Credentials')) {
            utils.notify('alert', 'Unable to connect to backup voip servers.  Depending on your network the voip connection might fail.', { ttl: 8000 })
          }

          var iceServers = []
          if (iceToken) {
            iceServers = iceToken.iceServers.map(s => ({
              ...s,
              url: s.url && s.url.startsWith('stun')
                ? s.url.replace(/\?transport=(udp|tcp)/, '')
                : s.url,
              urls: s.urls && typeof s.urls === 'string' && s.urls.startsWith('stun')
                ? s.urls.replace(/\?transport=(udp|tcp)/, '')
                : Array.isArray(s.urls)
                  ? s.urls.map(url => url.startsWith('stun') ? url.replace(/\?transport=(udp|tcp)/, '') : url)
                  : s.urls
            }))
          } else {
            iceServers = [{ 'url': 'stun:global.stun.twilio.com:3478' }]
            dbgCall('Did not receive ice server list from server')
          }

          self.iceServers = iceServers
          resolve(iceServers)
        })
      })
    },

    /**
     * the main method of the model
     * 2. it starts listening for new users, or wertc signal messages
     * 3. it fires voip:ready which signals the other users that this app is ready for the call
     */
    start: function (localAudio) {
      var self = this
      dbgCall('Starting call setup')

      var promises = []

      if (!localAudio) {
        promises.push(this.getLocalAudio.bind(self))
      } else {
        promises.push(function () { return Promise.resolve(localAudio) })
      }

      promises.push(self.getIceServers.bind(self))
      promises.push(self.initVoipEventListeners.bind(self))
      promises.push(self.initCallDependencyListeners.bind(self))

      this.startCallPromise = utils.promiseSerial(promises)
        .then(function (results) {
          var localAudio = results[0]
          self.set('started', true)
          return localAudio
        }).catch(utils.logRethrow)
      return this.startCallPromise
    },

    hasStarted: function () {
      var callIsStarting = !!this.startCallPromise
      return this.get('started') || callIsStarting
    },

    restart: function () {
      dbgCall('Restarting call')
      console.warn('Restarting call')

      // destroy all the peer connections
      this.stop()

      // recreate the call with the new stream
      return this.start()
    },

    initVoipEventListeners: function (iceServers) {
      var self = this
      dbgCall('init voip event listeners')

      this.socket.on('voip:users', function (payload) {
        dbgCall('VOIP USERS: ', _.pluck(payload.users, '_id'), ' INITIATOR: ', payload.initiator)
        // dbgCall(payload.users)
        self.peersJoined(payload, app.outgoingVoipStreamDestination.stream, iceServers)
      })

      this.socket.on('voip:signal', function (data) {
        var peer = self.peers[data.peerId]
        dbgCall('Received socket signal', data.signal.type, ' from Peer: ', data.peerId)
        if (!peer) {
          var errMsg = 'no peer found with id: ' + data.peerId
          dbgPeer(errMsg)
          console.error(errMsg)
          return
        }

        dbgPeer('Local signaling state: ', peer._pc && peer._pc.signalingState)

        // it the peer connection is receiving an offer
        // but thinks that is should be the initiator
        // then there is a concurrent initiator conflict
        // AKA signaling glare
        if (data.signal.type === 'offer' && peer.initiator) {
          dbgPeer('Signaling glare encountered.  Lets get ready to Rummmmble!')

          if (data.glareToken === peer.glareToken) {
            peer.destroy(new Error('Cannot perform face-off with identical glare tokens. What are the odds?'))
          }

          // compare glare tokens
          var concede = data.glareToken > peer.glareToken

          if (concede) {
            // if we lose the glareToken battle, then recreate the peer connection as a non-initiator
            // so we have a peer in the right state for the incoming offer
            dbgPeer('Lost the glareToken face-off to peer: ', peer.id)
            var isInitiator = false

            dbgPeer('Recreating peer connection to accept remote offer')
            peer = self.createPeer(peer.id, app.outgoingVoipStreamDestination.stream, isInitiator, iceServers)
          } else {
            // if we win the glareToken face-off then we simply ignore the incoming signal
            dbgPeer('Won the glareToken face-off. Ignoring offer from: ', peer.id)
            return
          }

          // this happens when both users join the room at the same time and have peer.initiator = false
          // workaround for a bug tallked here:
          // https://github.com/feross/simple-peer/issues/363
          // TODO: take a look at this later on
        } else if (data.signal.renegotiate && peer.initiator === false) {
          dbgPeer('Conflict between non initiator peers')
          self.destroyPeer(data.peerId)
          self.voipReady()
          return
        }

        dbgPeer('Applying remote signal to local peer connection: ', data.signal.type, ' from: ', peer.id)
        peer.signal(data.signal)
      })

      // when the other user wants to restart the voip
      this.socket.on('voip:restart', function (data) {
        var peer = self.peers[data.userId]

        // sometimes the peer can get destroyed before this event fires
        // fall back to using the event's data.initiator to determine signalling sequence
        var isInitiator = (peer && peer.initiator) || data.initiator === app.user.id

        console.warn('Received voip:restart event. Peer: ', data.userId, 'isInitiator: ', isInitiator)

        if (isInitiator) {
          // start reconnect process.  this will only run if the user in question is in the room
          // and is the initiator.  This is useful for getting the initiator to try reconnecting
          // sooner than it normally would just by waiting for the connection to timeout
          self.startPeerReconnect(data.userId, isInitiator)
        } else {
          self.destroyPeer(data.userId)
        }
      })

      this.voipReady()
    },

    voipReady: function () {
      // Signals to the room that this participant is ready and
      // listening for voip events.  This needs to be idempotent since it
      // can be called multiple times in a session if the network
      // connection is having trouble
      dbgCall('VOIP:READY')
      app.user.set('voipReady', true)
      console.log('User ready to create voip connection')
      this.socket.emit('voip:ready')
    },

    stopVoipEventListeners: function () {
      console.log('STOP VOIP EVENT LISTENERS')
      this.socket.off('voip:users')
      this.socket.off('voip:signal')
      this.socket.off('voip:restart')
    },

    buildAudioObject: function (mediaStream, peer) {
      var isPeerStream = !!peer

      // create a web audio source node so we can pipe the stream
      // into the web audio api audio graph
      var streamSource = this.actx.createMediaStreamSource(mediaStream)

      streamSource.channelCountMode = 'explicit'
      streamSource.channelInterpretation = 'speakers'
      streamSource.channelCount = 1

      // gain node for master control of the stream volume
      // throughout the app
      // all consumers of the stream should attach to this
      var gain = this.actx.createGain()
      streamSource.connect(gain)

      var audio = {
        mediaStream: mediaStream, // raw media stream
        streamSource: streamSource,
        gain: gain
      }

      if (isPeerStream) {
        // attach stream to audio/video element to get around issue in chrome
        // where remote streams can't be processed by web audio
        // this some how drives the audio into a stream that is consumable by web audio
        var mediaEl = document.createElement('audio')
        mediaEl.srcObject = mediaStream

        audio.mediaEl = mediaEl
      }

      return audio
    },

    getLocalAudio: function () {
      var self = this
      this.trigger('showAccessing')
      var mediaOptions = this.getMediaOptions()
      console.log('Local user stream requested with options: ', mediaOptions)
      return this.getLocalStream(mediaOptions)
        .then(self.addStreamLogging.bind(self))
        .then(self.buildLocalAudio.bind(self))
        .then(function (localAudio) {
          console.log('Received local audio stream')
          self.trigger('localStreamAdded', localAudio.gain, localAudio)
          return localAudio
        }).catch(utils.logRethrowCustom(new Error('There was an error getting the user\'s local stream')))
        .finally(function () {
          self.trigger('hideAccessing')
        })
    },

    buildLocalAudio: function (mediaStream) {
      this.localAudio = this.buildAudioObject(mediaStream)
      return Promise.resolve(this.localAudio)
    },

    addStreamLogging: function (stream) {
      var self = this
      var audioTracks = stream.getAudioTracks()

      audioTracks.forEach(function (track) {
        track.addEventListener('ended', function () {
          console.warn('User track ended')

          self.trigger('trackEnded')
        })
        track.addEventListener('overconstrained', function () {
          console.error('overconstrained error on track')
        })
      })

      return Promise.resolve(stream)
    },

    disconnectLocalStream: function () {
      dbgCall('Disconnecting local audio stream')
      this.disconnectAudioStream(this.localAudio)
    },

    disconnectPeerStream: function (peer) {
      dbgCall('Disconnecting peer streams')
      this.trigger('peerStreamRemoved', peer.audio.gain, peer.audio, peer)
      this.disconnectAudioStream(peer.audio)
    },

    disconnectAudioStream: function (audio) {
      audio.streamSource && audio.streamSource.disconnect()
      audio.gain && audio.gain.disconnect()
      audio.mediaStream && audio.mediaStream.getTracks().forEach(function (t) { return t.stop() })
    },

    gotPeerStream: function (peer, mediaStream) {
      dbgPeer('Got stream for peer:', peer.id)
      peer.audio = this.buildAudioObject(mediaStream, peer)

      // connect to local ouput so we can hear them
      peer.audio.gain.connect(app.localAudioOut)

      this.setPeerStreamSink(peer)
      this.setPeerEchoCancellation(peer)

      // start the media element so we can hear them
      // peer.audio.mediaEl.play()

      this.trigger('peerStreamAdded', peer.audio.gain, peer.audio, peer)
    },

    getMediaOptions: function () {
      var audioInputId = this.audioInput.get('deviceId')
      var echoCancellation = app.user.settings.get('echoCancellation')
      var mediaOptions = {
        // autoAdjustMic: false,
        // audio: true,
        audio: {
          deviceId: audioInputId ? { exact: audioInputId } : undefined,
          echoCancellation: echoCancellation,
          autoGainControl: false,
          googAutoGainControl: false,
          mozAutoGainControl: false,
          noiseSuppression: true,
          channelCount: 1
        },
        video: false
      }

      dbgCall('MediaConstraints: ', mediaOptions)

      return mediaOptions
    },

    callStatsInitCallback: function (status, msg) {
      var self = this
      console.log('Callstats init status: ' + status + ' msg: ' + msg)

      // Usage
      this.stats.on('preCallTestResults', function (status, results) {
        // Check the status
        if (status === self.stats.callStatsAPIReturnStatus.success) {
          // log results
          console.table({
            status: status,
            connectivity: results.mediaConnectivity,
            rtt: results.rtt,
            loss: results.fractionalLoss,
            throughput: results.throughput
          })
        } else {
          console.warn('Pre-call test could not be run')
        }
      })
    },

    statsCallback: function (stats) {
      var self = this
      console.log('processed stats ', stats)
      console.log('connectionState / fabricState')
      console.log(stats.connectionState, '/', stats.fabricState)

      var bitrateForSsrc = 0
      var quality = []
      _.keys(self.peers).forEach(function (peerId) {
        var peer = self.peers[peerId]
        bitrateForSsrc = 0
        console.log('peer:', peer.id, stats.connectionState, '/', stats.fabricState)

        quality = []
        _.keys(stats.streams).forEach(function (ssrc) {
          if (stats.streams[ssrc].bitrate) {
            bitrateForSsrc = bitrateForSsrc + stats.streams[ssrc].bitrate
          }
          if (stats.streams[ssrc].quality) {
            quality.push(stats.streams[ssrc].quality)
          }
        })

        if (bitrateForSsrc > 0) {
          bitrateForSsrc = bitrateForSsrc.toFixed(2)
          console.log('peer:', peer.id, bitrateForSsrc, 'Kbps')
          console.log('peer:', peer.id, 'quality is ', quality)
          console.log('peer:', peer.id, 'processed quality is ', quality)
        }
        console.log('peer.id and ssrcs ', peer.id, stats.streams)
      })
    },

    getMinQuality: function (quality) {
      var i
      var retQuality = 0
      var retQualityString
      for (i = 0; i < quality.length; i++) {
        var tempQuality
        if (quality[i] === 'excellent') {
          tempQuality = 3
        } else if (quality[i] === 'fair') {
          tempQuality = 2
        } else if (quality[i] === 'bad') {
          tempQuality = 1
        }
        if (retQuality === 0 || tempQuality < retQuality) {
          retQuality = tempQuality
        }
      }

      if (retQuality === 1) {
        retQualityString = 'Red'
      } else if (retQuality === 2) {
        retQualityString = 'Yellow'
      } else if (retQuality === 3) {
        retQualityString = 'Green'
      }
      return retQualityString
    },

    getLocalStream: function (mediaOptions) {
      var self = this
      dbgCall('Getting local stream')

      return new Promise(function (resolve, reject) {
        navigator.mediaDevices.getUserMedia(mediaOptions).then(resolve).catch(function (err) {
          console.error('Error getting local stream:', err.name)
          if (err.name === 'PermissionDeniedError' || err.name === 'NotAllowedError') {
            var browser = ua.browser.name.toLowerCase()
            var isChrome = browser === 'chrome'
            var isFirefox = browser === 'firefox'

            var message = 'by updating the microphone permission setting in your browser.'
            if (isChrome) {
              message = 'by clicking the icon on the lock icon to the left side of the URL bar and changing the Microphone permission setting to "Always allow on this site".  Then refresh the page.'
              message += '<img src="/media/images/gifs/chrome-allow-mic.gif" class="modal-image" />'
            } else if (isFirefox) {
              message = 'by clicking the microphone icon to the left side of the URL bar and removing any permissions that are present. Then refreshrefresh the page and allow acces to the microphone when prompted.'
              message += '<img src="/media/images/gifs/firefox-allow-mic.gif" class="modal-image" />'
            }

            var micPermissionModal = new zc.views.ModalView({
              addClass: 'mic-permission-modal',
              model: new Backbone.Model({
                title: 'Zencastr was denied access to your microphone',
                text: 'In order join the recording, we need permission access to your microphone. You can allow access ' + message,
                confirmText: 'Close',
                cancelText: null
              }),
              ChildView: zc.views.ConfirmView,
              callback: function () {
                micPermissionModal.exit()
              }
            })
            micPermissionModal.render()
          } else if (err.name === 'TrackStartError') {
            // https://stackoverflow.com/a/43945050
            utils.notify('error', 'There was a problem accessing your microphone.')
          } else {
            utils.notify('error', 'There was a problem accessing your microphone. ' + err.name + ': ' + err.message)
          }

          if (self.stats) self.stats.reportError(null, app.user.id, self.stats.webRTCFunctions.getUserMedia, err)
          reject(err)
        })
      })
    },

    createPeer: function (userId, stream, initiator, iceServers) {
      var self = this
      var user = app.location.lobby.users.get(userId) // the user backbone model

      var oldPeer = this.peers[userId]
      if (oldPeer) {
        dbgCall('Cleaning up old connection... ', oldPeer)
        self.destroyPeer(oldPeer.id)
      }

      console.log('Creating peer for user: ', userId, '. Initiator: ', initiator)
      dbgCall('Before creating peerIds: ', _.keys(this.peers))

      var peer = this.peers[userId] = new SimplePeer({
        initiator: app.user.id === initiator,
        stream: stream,
        config: { iceServers: iceServers },
        iceTransportPolicy: self.peerErrors[userId] > 1 ? 'relay' : 'all',
        trickle: true
      })

      peer.id = userId

      peer.glareToken = Math.random() // used to decide whose offer should win in the case of concurrent offers

      dbgCall('CREATED PEER for User: ', userId, ' peer: ', peer)
      dbgCall('After creating peerIds: ', _.keys(this.peers), ' peers: ', this.peers)

      peer.on('error', function (err) {
        var peer = this

        console.error('Peer:', userId, 'error:', err.message, err.stack)
        if (userId in self.peerErrors) {
          self.peerErrors[peer.id]++
          dbgPeer('Incrementing subsequent peer error', peer.id, self.peerErrors)
        } else {
          self.peerErrors[peer.id] = 1
          dbgPeer('First peer error', peer.id, self.peerErrors)
        }

        dbgCall('peer error count: ', self.peerErrors)

        // if we had multiple errors with this user, show a warning
        if (self.peerErrors[peer.id] > 3) {
          utils.notify('error', 'Your VoIP connection to <strong>' + user.get('username') + '</strong> has failed. \nRetrying...', { ttl: 3000 })
        }

        // self.destroyPeer(peer.id)

        self.startPeerReconnect(peer.id, peer.initiator)

        if (self.stats) self.stats.reportError(peer._pc, peer.id, self.stats.webRTCFunctions.iceConnectionFailure, err)
      })

      peer.on('signal', function (signal) {
        dbgPeer('Creating signal ' + signal.type + ' for peer: ', peer.id)
        if (!peer._pc) console.warn('Missing peer connection')
        var signalingState = peer._pc && peer._pc.signalingState
        if (!signalingState) console.warn('Unable to determine signaling state')
        dbgPeer('Local signaling state: ', signalingState)

        dbgPeer('Sending signal via socket to: ', peer.id, signal.type)

        // do not send empty signal with empty candidates
        // seems to happen in firefox 68
        if (signal.candidate && signal.candidate.candidate === '') {
          return
        }

        self.socket.emit('voip:signal', {
          signal: signal,
          peerId: peer.id,
          glareToken: this.glareToken
        })
      })

      peer.once('connect', function () {
        dbgPeer('Peer connected')
        var user = app.location.lobby.users.get(peer.id)
        user.trigger('voipConnected')
        self.stopPeerReconnect(peer.id)
      })

      peer.on('data', function (data) {
        var parsed = JSON.parse(String.fromCharCode.apply(null, data))
        dbgPeer('Peer data: ', parsed)
        // if (parsed.event === 'change:echoCancellation') {
        //   self.set('echoCancellation', parsed.payload)
        // }
      })

      peer.on('stream', function (stream) {
        dbgPeer('Received stream for peer: ', peer.id)
        self.gotPeerStream(peer, stream)
      })

      peer.once('close', function () {
        dbgPeer('Peer on close: ', peer)
        if (self.disconnectedAt) {
          dbgPeer('Disconnected x ms ago: ' + (new Date().getTime() - self.disconnectedAt))
        }

        dbgPeer('Once CLOSE for peer: ', peer.id, peer)

        peer.audio && self.disconnectPeerStream(peer)

        // triggering voipDisconnected will show the disconnected state in the ui
        user.trigger('voipDisconnected')

        // make sure some other peer with different id didn't take place between
        // calling `destroy()` and `close` event
        dbgPeer('Checking for user: ', peer.id, ' in peers: ', _.keys(self.peers))
        if (self.peers[peer.id]) {
          dbgPeer('Found sneaky peer: ', peer.id, self.peers[peer.id])
          self.peers = _.omit(self.peers, peer.id)
        }
        dbgPeer('Peer left: ', peer, ' Remaining Peers: ', _.keys(self.peers))

        // sometimes only the close event gets fired when there is an error on the remote peer
        // If the error callback did get called and a reconnection attempt process has already been started, then
        // calling startPeerReconnect again will not have any effect
        // self.startPeerReconnect(peer.id, peer.initiator)
        self.destroyPeer(peer.id)
      })

      peer.on('iceStateChange', function (iceConnectionState, iceGatheringState) {
        dbgPeer('IceStateChange chagen event. iceGatheringState: ', iceGatheringState, ' iceConnectionState: ', iceConnectionState)
        if (iceConnectionState === 'disconnected') {
          dbgPeer('ICE State DISCONNECTED for peer', peer.id)

          user.trigger('voipDisconnected')
          self.disconnectedAt = new Date().getTime()
        } else if (iceConnectionState === 'connected') {
          dbgPeer('ICE State CONNECTED for peer', peer.id)
          user.trigger('voipConnected')
        }
      })

      if (this.stats) {
        // remoteUserID is the recipient's userID
        // conferenceID is generated or provided by the origin server (webrtc service)
        var usage = this.stats.fabricUsage.multiplex
        this.stats.addNewFabric(peer._pc, peer.id, usage, app.location.recorder.recording.id, function (status, msg) {
          console.log('Callstats monitoring status: ' + status + ' msg: ' + msg)
        })
      }

      return peer
    },

    reconnectPeer: function (peerId, isInitiator) {
      var self = this
      dbgPeer('Reconnect attempt for peer: ', peerId)
      // var peer = this.peers[peerId]
      // var iceServers = peer.config.iceServers
      this.destroyPeer(peerId)

      // notify the remote peer to restart
      this.socket.emit('voip:restart', {
        peerId: peerId,
        userId: app.user.id,
        initiator: app.user.id // this user is initiator
      }, function (err) {
        if (err) {
          console.warn(err)
          // this generally means that the remote peer has left or is disconnected from the socket server
          // cancel the reconnect attempts
          self.stopPeerReconnect(peerId)
        }

        self.voipReady()
      })

      // this.voipReady()
      // this.createPeer(peerId, app.outgoingVoipStreamDestination.stream, isInitiator, iceServers)
    },

    reconnectIntervals: {},

    startPeerReconnect: function (peerId, isInitiator) {
      var self = this

      // don't keep trying to reconnect if the user isn't in the room anymore
      // var user = app.location.lobby.users.get(peerId) // the user backbone model
      // var isUserInRoom = user && !!user.collection

      // only the initiator should try to reconnect
      if (isInitiator) {
        // if we have a
        if (!this.reconnectIntervals[peerId]) {
          dbgPeer('Peer is initiator. Starting reconnect attempts for peer: ', peerId, ' isInitiator: ', isInitiator)

          var maxBackoff = 1000 * 30 // max of 30 seconds between attempts
          var backoffFactor = 1
          var attempts = 1 // start with 1 since we attempt to reconnect right away
          var lastAttempt = new Date().getTime()

          // try to reconnect right away
          self.reconnectPeer(peerId, isInitiator)

          // then space out the attempts
          this.reconnectIntervals[peerId] = setInterval(function () {
            var peer = self.peers[peerId]

            // don't keep trying to reconnect if the user isn't in the room anymore
            var user = app.location.lobby.users.get(peerId) // the user backbone model
            var isUserInRoom = user && !!user.collection
            if (!isUserInRoom) return self.stopPeerReconnect(peerId)

            // don't try to reconnect while a prior negotiation is still taking place
            if (peer && peer._isNegotiating) {
              dbgPeer('Peer still negotiating.  Delay reconnect attempt. peer: ', peerId, ' isInitiator: ', isInitiator)
              return
            }

            var randomMs = Math.random() * 1000
            var backoff = Math.min(backoffFactor * randomMs, maxBackoff)
            var now = new Date().getTime()
            var timeSinceLastAttempt = now - lastAttempt

            if (timeSinceLastAttempt >= backoff) {
              attempts++
              backoffFactor = backoffFactor * 2 // double the backoff factor after each attempt
              lastAttempt = now
              console.log('Backoff expired. attempting another reconnection now')
              self.reconnectPeer(peerId, isInitiator)
            } else {
              console.log('Waiting for backoff to expire before attempting another reconnection')
            }

            console.log('Attempts: ', attempts, ' Since last: ', timeSinceLastAttempt, ' Backoff: ', backoff)
          }, 3000)
        }
      } else {
        // if (!isUserInRoom) return self.stopPeerReconnect(peerId)

        dbgPeer('Peer is not initiator.  Not reconnecting. ', peerId, ' isInitiator: ', isInitiator)
        // since we are not the initiator, sent the restart event to the initiator so they can
        // quickly begin the reconnection without waiting for a timeout error
        this.socket.emit('voip:restart', {
          peerId: peerId,
          userId: app.user.id,
          initiator: peerId // The remote user is the initiator
        }, function (err) {
          console.error('Problem sending voip:restart event to peer: ', peerId, err)
        })
      }
    },

    stopPeerReconnect: function (peerId) {
      if (this.reconnectIntervals[peerId]) {
        dbgPeer('Stopping reconnect interval for peer: ', peerId)
        clearInterval(this.reconnectIntervals[peerId])
        this.reconnectIntervals[peerId] = undefined
      }
    },

    peersJoined: function (payload, stream, iceServers) {
      var self = this
      var users = payload.users // all users currently in the room
      dbgCall('Peers joined:', _.pluck(payload.users, '_id'), 'Initiator: ', payload.initiator)
      dbgCall('Existing peers', _.keys(self.peers))
      // dbgCall(payload.users)

      // filter out local user as well as any users who are already in the peers array
      users.filter(function (user) {
        return !self.peers[user._id] && user._id !== app.user.id
      }).forEach(function (user) {
        dbgCall('Creating Peer for user:', user._id)
        self.createPeer(user._id, stream, payload.initiator, iceServers)
      })
    },

    setPeerEchoCancellation: function (peer) {
      var echoCancellation = app.user.settings.get('echoCancellation')

      // HAX
      // echo cancellation doesn't work unless the peer media stream is output
      // through a html media element (<audio> / <video>) with non-zero volume volume
      // ie. mediaEl.srcObject = peerStream
      // see: https://bugs.chromium.org/p/chromium/issues/detail?id=687574
      //
      // Use this to turn it off and on since the media constraints don't seem to be reliable
      if (echoCancellation) {
        // mute web audio output
        // play peer audio out of video/audio element with echo cancellation
        peer.audio.gain.disconnect(app.localAudioOut)
        peer.audio.mediaEl.play()
      } else {
        // mute media element audio
        // play peer audio through web audio nodes without echo cancellation
        peer.audio.mediaEl.pause()
        peer.audio.gain.connect(app.localAudioOut)
      }
    },

    clearPeers: function () {
      var self = this
      var peerIds = _.keys(self.peers)
      dbgCall('Clearing PeerIds: ', peerIds, ' peers: ', _.keys(self.peers))
      peerIds.forEach(function (peerId) {
        self.destroyPeer(peerId)
      })
      dbgCall('Peers should be empty after clearing: ', _.keys(self.peers))
    },

    destroyPeer: function (peerId) {
      var self = this
      var peer = self.peers[peerId]
      if (peer) {
        peer.destroy()
        self.peers = _.omit(self.peers, peerId)
        console.warn('Destroyed peer: ' + peerId + ': ' + !self.peers[peerId])
        dbgCall('Destroyed peerId: ' + peerId, ' peer: ', peer)
        if (self.peers[peerId]) console.error('Peer (' + peerId + ') not properly destroyed. Peers: ' + self.peers)
      } else {
        dbgCall('Couldn\'t destroy missing peer: ' + peerId)
      }
    },

    stop: function () {
      var self = this
      dbgCall('Stopping call')

      // disconnect existing local stream
      this.disconnectLocalStream()

      this.stopVoipEventListeners()

      self.clearPeers()

      self.set('started', false)
    },

    mediaDevicesRemoved: function (removedDevices) {
      if (_.findWhere(removedDevices, this.audioInput.toJSON())) {
        this.setPreferredDevices().catch(utils.notifyError)
      }
    },

    /**
     * called when the user changes the input mic from inside the app
     */
    inputStreamChanged: function () {
      dbgCall('Input stream changed')
      this.disconnectLocalStream()
      this.getLocalAudio().catch(utils.notifyError)
    },

    /**
     * Called when echo cancelation setting changes. On this event we should get new local audio track
     * and update it into the peer connection
     */
    echoCancellationChange: function () {
      var self = this
      this.getLocalAudio().then(function (localAudio) {
        var localAudioTrack = localAudio.mediaStream.getAudioTracks()[0]
        Object.values(self.peers).forEach(function (peer) {
          var stream = peer.streams[0]
          var oldTrack = stream.getAudioTracks()[0]
          peer.replaceTrack(oldTrack, localAudioTrack, stream)
          self.setPeerEchoCancellation(peer)
        })
      })
    },

    /**
     * This will get called when a setting or configuration changes that
     * requires the call to be restarted
     *
     * We should probably try and use applyConstraints as shown here
     * when it matures a bit:
     *
     * https://jsfiddle.net/jib1/n7bmkjnf/?utm_source=website&utm_medium=embed&utm_campaign=n7bmkjnf
     *
     */
    callDependencyChange: function () {
      dbgCall('Call dependency change')
      var self = this
      var voipEnabled = app.user.settings.get('hostVoip')

      var callHasStarted = this.hasStarted()

      // when the dependencies change do one of three things:
      // 1. start the call if it hasn't already started and voip is now enabled
      // 2. restart the call if it has already started and voip is enabled
      // 3. stop the call if it has already started but voip is now disabled

      var requirements = []

      // wait for any pending startCall to complete first otherwise we can end up
      // disrupting the webrtc handshakes and spewing errors everywhere
      if (this.startCallPromise) requirements.push(this.startCallPromise)

      Promise.all(requirements).then(function () {
        if (!callHasStarted && voipEnabled) {
          return self.restart()
        } else if (callHasStarted && voipEnabled) {
          return self.restart()
        } else if (callHasStarted && !voipEnabled) {
          self.stop()
          return self.getLocalAudio()
        }
      }).catch(utils.notifyError)
    },

    setPeerStreamSink: function (peer) {
      var audioOutputId = this.audioOutput.get('deviceId')
      if (audioOutputId && peer.audio.mediaEl.setSinkId) {
        peer.audio.mediaEl.setSinkId(audioOutputId)
      } else {
        var errMsg = 'This browser does not support output device selection'
        console.error(errMsg)
        dbgCall(errMsg)
      }
    },

    setPreferredDevices: function (options) {
      var self = this
      return this.mediaDevices.getPreferredDevices().then(function (preferred) {
        preferred.audioInput && self.audioInput.set(preferred.audioInput.toJSON(), options)
        preferred.audioOutput && self.audioOutput.set(preferred.audioOutput.toJSON(), options)
        return preferred
      })
    },

    audioOutputChange: function (audioOutput) {
      var self = this
      var audioOutputId = audioOutput.get('deviceId')
      dbgCall('Setting sinkId to ' + audioOutputId)

      _.keys(self.peers).forEach(function (peerId) {
        var peer = self.peers[peerId]
        self.setPeerStreamSink(peer)
      })
    }
  })
})()
