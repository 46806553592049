/* globals zc Backbone app utils */

(function () {
  'use strict'

  zc.models.Settings = Backbone.Model.extend({
    initialize: function (attrs, options) {
      this.user = options.user
      this.on('change', this.settingsChanged)
    },

    attrs: function () {
      return this.toJSON()
    },

    settingsChanged: function (model) {
      if (this.user.isHost() || window.location.pathname === '/dashboard') {
        this.save()
      }
    },

    save: function () {
      var data = {userId: this.user.id, settings: this.toJSON()}
      app.socket.emit('user:update:settings', data, function (err) {
        if (err) {
          utils.notify('error', err)
        } else {
          utils.notify('success', 'Settings saved successfully', {ttl: 1500})
        }
      })
    }
  })
})()
