/* globals zc Backbone _ */

(function () {
  'use strict'

  zc.views.FormView = Backbone.View.extend({
    initialize: function (options) {
      this.callback = options.callback || this.callback

      if (this.model) {
        this.listenTo(this.model, 'loading', this.showLoading)
        this.listenTo(this.model, 'doneLoading', this.hideLoading)
      }
    },

    className: 'form',

    events: {
      'click .submit': 'submit',
      'submit form': 'submit',
      'keypress input': 'inputKeypress',
      'input input.form-error': 'errorInputChange'
    },

    url: function () {
      // should be implemented by decendent
      console.warn('This method should be implemented by the decendent')
      return '/some/api/url/'
    },

    values: function () {
      // should be implemented by decendent
      console.warn('This method should be implemented by the decendent')
      return {}
    },

    validateForm: function (attrs) {
      // should be implemented by decendent
      // Should return an error or false if there are no errors
      console.warn('This method should be implemented by the decendent')
      return false
    },

    showLoading: function () {
      this.$('a,button').addClass('disabled')
    },

    hideLoading: function () {
      this.$('a,button').removeClass('disabled')
    },

    showError: function (err) {
      this.hideError()
      this.$error.text(err)
      this.$error.fadeIn()
    },

    hideError: function () {
      this.$error.hide()
    },

    callback: function (err, res) {
      // Should be implemented by decendent
      console.log(err, res)
    },

    inputKeypress: function (e) {
      if (e.keyCode === 13) {
        e.preventDefault()
        this.submit()
      }
    },

    errorInputChange: function (e) {
      var $target = $(e.target)
      $target.removeClass('form-error')
    },

    submit: function (e) {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      var view = this
      var values = this.values()

      var validationError = this.validateForm(values)

      if (validationError) {
        return this.showError(validationError)
      } else {
        this.hideError()
      }

      this.showLoading()

      return $.ajax({
        url: view.url(),
        type: 'POST',
        data: values
      }).success(function (res) {
        view.callback(null, res)
      }).error(function (res) {
        view.showError(res.responseText)
        view.callback(res.responseText)
      }).always(function () {
        view.hideLoading()
      })
    },

    render: function () {
      var attrs = this.model
        ? _.isFunction(this.model.attrs)
          ? this.model.attrs() : this.model.toJSON()
        : {}

      this.$el.html(this.template(attrs))
      this.$error = this.$('.error')
      return this
    }
  })
})()
