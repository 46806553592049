/* globals zc _ */

(function () {
  'use strict'

  zc.views.PageView = zc.views.BaseView.extend({
    initialize: function () {
      this.listenTo(this.model, 'enter', this.enter)
      this.listenTo(this.model, 'exit', this.exit)
    },

    enter: function () {
      var self = this
      _.defer(function () {
        $('.app').removeClass('page-loading')
        self.$el.css({opacity: 1})
      })
    },

    exit: function () {
      this.remove()
    },

    render: function () {
      this._render()
      return this
    }
  })
})()
